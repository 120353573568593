import moment from "moment";

export const range = (start: number, end: number) => {
  const range: number[] = [];
  for (let i = start; i <= end; i++) {
    range.push(i);
  }
  return range;
};

export const getYearRange = (step = 20) =>
  range(new Date().getFullYear() - step, new Date().getFullYear() + step);

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getDateClassName = (d: Date, tempDate?: Date | null, minDate?: Date | null) => {
  return moment(d).format("YY,MM,DD") === moment(tempDate).format("YY,MM,DD")
    ? "date chosen"
    : `date ${
        moment(d).format("YY,MM,DD") < moment(new Date()).format("YY,MM,DD") && minDate
          ? "past"
          : ""
      }`;
};
