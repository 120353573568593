import { CaseReducer, createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionDetails } from "../../classes/Wallet";
import { DEFAULT_CHAIN_ID } from "../../config/network";
import { STORAGE_KEYS } from "../../utils/storage";

type TWalletState = {
  isConnected: boolean;
  hasAdminRole: boolean;
  account: string;
  chainId: number;

  nativeTokenBalance: string;

  blockNumber: {
    [chainId: number]: number;
  };
  transactions: {
    [chainId: number]: {
      [txHash: string]: TransactionDetails;
    };
  };
};

const initialState: TWalletState = {
  isConnected: false,
  hasAdminRole: false,
  account: "",
  chainId: DEFAULT_CHAIN_ID,

  nativeTokenBalance: "0",

  blockNumber: {},
  transactions: {},
};

const _handleSetConnected: CaseReducer<TWalletState, PayloadAction<boolean>> = (state, action) => {
  if (action.payload) {
    localStorage.setItem(STORAGE_KEYS.IS_CONNECTED, "true");
  }
  state.isConnected = action.payload;
};

const _handleSetHasRole: CaseReducer<TWalletState, PayloadAction<boolean>> = (state, action) => {
  state.hasAdminRole = action.payload;
};

const _handleSetBalance: CaseReducer<TWalletState, PayloadAction<string>> = (state, action) => {
  state.nativeTokenBalance = action.payload;
};

const _setAccount: CaseReducer<TWalletState, PayloadAction<string>> = (state, action) => {
  state.account = action.payload;
};

const _setChainId: CaseReducer<TWalletState, PayloadAction<number>> = (state, action) => {
  state.chainId = action.payload;
};

const _clearState: CaseReducer<TWalletState> = (state) => {
  state.account = "";
  state.hasAdminRole = false;
  state.isConnected = false;
  state.chainId = DEFAULT_CHAIN_ID;
  state.nativeTokenBalance = "0";
};

export const addTransaction = createAction<{
  chainId: number;
  hash: string;
  action: string;
}>("contract/addTransaction");

export const updateBlockNumber = createAction<{
  chainId: number;
  blockNumber: number;
}>("contract/updateBlockNumber");

export const removeTransaction = createAction<{
  chainId: number;
  hash: string;
}>("contract/removeTransaction");

const walletSlice = createSlice({
  name: "storeWallet",
  initialState,
  reducers: {
    handleSetConnected: _handleSetConnected,
    handleSetHasRole: _handleSetHasRole,
    handleSetBalance: _handleSetBalance,
    setAccount: _setAccount,
    setChainId: _setChainId,
    clearState: _clearState,
  },
  extraReducers: (builder) => {
    builder.addCase(addTransaction, ({ transactions }, { payload: { chainId, hash, action } }) => {
      if (transactions[chainId]?.[hash]) {
        throw Error("Attempted to add existing transaction.");
      }
      const txs = transactions[chainId] ?? {};
      txs[hash] = {
        hash,
        addedTime: Date.now(),
        chainId,
        action,
      };
      transactions[chainId] = txs;
    });
    builder.addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload;
      if (typeof state.blockNumber[chainId] !== "number") {
        state.blockNumber[chainId] = blockNumber;
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId]);
      }
    });
    builder.addCase(removeTransaction, (state, { payload: { chainId, hash } }) => {
      delete state.transactions[chainId][hash];
    });
  },
});

export const walletActions = walletSlice.actions;
export default walletSlice.reducer;
