export const STORAGE_KEYS = {
  ACCOUNT: "account",
  TOKEN_ACCESS: "tokenAccess",
  REFRESH_TOKEN: "refreshToken",
  SECRET: "secret",
  SIGNATURE: "signature",
  USER: "user",
  IS_CONNECTED: "isConnected",
  WALLET_LAST_CONNECTED: "walletLastConnected",
  TRANSACTIONS: "transactions",
};

export default class StorageUtils {
  static setItem(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  static getItem(key: string, defaultValue?: string) {
    const result = window.localStorage.getItem(key);
    if (result === null || result === undefined) return defaultValue;
    return result;
  }

  static removeItem(key: string) {
    window.localStorage.removeItem(key);
  }

  // section storage
  static setSectionStorageItem(key: string, value: string) {
    window.sessionStorage.setItem(key, value);
  }

  static getSectionStorageItem(key: string) {
    return window.sessionStorage.getItem(key);
  }

  static removeSectionStorageItem(key: string) {
    window.sessionStorage.removeItem(key);
  }

  static removeUser() {
    this.removeSectionStorageItem(STORAGE_KEYS.USER);
  }

  // Token
  static setToken(value = "") {
    StorageUtils.setSectionStorageItem(STORAGE_KEYS.TOKEN_ACCESS, value);
  }

  static getToken() {
    return StorageUtils.getSectionStorageItem(STORAGE_KEYS.TOKEN_ACCESS);
  }

  static removeToken() {
    StorageUtils.removeSectionStorageItem(STORAGE_KEYS.TOKEN_ACCESS);
  }

  static setUser(value: unknown) {
    this.setSectionStorageItem(STORAGE_KEYS.USER, JSON.stringify(value));
  }

  static getUser() {
    const user = this.getSectionStorageItem(STORAGE_KEYS.USER) as string;
    return JSON.parse(user);
  }

  static setSignature(value = "") {
    StorageUtils.setSectionStorageItem(STORAGE_KEYS.SIGNATURE, value);
  }

  static getSignature() {
    return StorageUtils.getSectionStorageItem(STORAGE_KEYS.SIGNATURE);
  }

  static removeSignature() {
    StorageUtils.removeSectionStorageItem(STORAGE_KEYS.SIGNATURE);
  }

  static setAccount(value = "") {
    StorageUtils.setSectionStorageItem(STORAGE_KEYS.ACCOUNT, value);
  }

  static getAccount() {
    return StorageUtils.getSectionStorageItem(STORAGE_KEYS.ACCOUNT);
  }

  static removeAccount() {
    return StorageUtils.removeSectionStorageItem(STORAGE_KEYS.ACCOUNT);
  }
}
