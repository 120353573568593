import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { Contract, ContractInterface, ethers } from "ethers";
import { useMemo } from "react";
import { IWeb3ReactContext } from "../classes/Wallet";
import { DEFAULT_CHAIN_ID } from "../config/network";
import RPC from "../config/rpc";
import ERC20_ABI from "../constants/abis/erc20";
import ERC20_PERMIT_ABI from "../constants/abis/erc20-permit";
import TOKEN_TRANSFER_ABI from "../constants/abis/token-transfer";
import { ChainId } from "../constants/chains";
import { TOKEN_TRANSFER_ADDRESS } from "../constants/contractAddress";

import { getContract } from "../functions/contract";

export const PROVIDER: { [chain: number]: ethers.providers.BaseProvider } = {
  [ChainId.MAINNET]: ethers.getDefaultProvider(
    process.env.NEXT_PUBLIC_FULLNODE_MAINNET || RPC[ChainId.MAINNET]
  ),
  [ChainId.GÖRLI]: ethers.getDefaultProvider(
    process.env.NEXT_PUBLIC_FULLNODE_GOERLI || RPC[ChainId.GÖRLI]
  ),
  [ChainId.BSC]: ethers.getDefaultProvider(
    process.env.NEXT_PUBLIC_FULLNODE_BSC || RPC[ChainId.BSC]
  ),
  [ChainId.BSC_TESTNET]: ethers.getDefaultProvider(
    process.env.NEXT_PUBLIC_FULLNODE_BSC_TESTNET || RPC[ChainId.BSC_TESTNET]
  ),
  [ChainId.MATIC]: ethers.getDefaultProvider(
    process.env.NEXT_PUBLIC_FULLNODE_MATIC || RPC[ChainId.MATIC]
  ),
  [ChainId.MATIC_TESTNET]: ethers.getDefaultProvider(
    process.env.NEXT_PUBLIC_FULLNODE_MUMBAI_TEST || RPC[ChainId.MATIC_TESTNET]
  ),
};

export const getProvider = (library: Web3Provider, fullNode?: string) => {
  const provider = ethers.getDefaultProvider(fullNode);

  return { ...library, provider };
};

export const useChainId = () => {
  const { chainId } = useWeb3React();
  return chainId || DEFAULT_CHAIN_ID;
};

export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: ContractInterface,
  context: IWeb3ReactContext,
  fullNode?: string,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = context;
  const { provider } = getProvider(library, fullNode);
  return useMemo(() => {
    if (!provider && !library) return null;
    if (!addressOrAddressMap || !ABI || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        provider && fullNode ? (provider as Web3Provider) : library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      return null;
    }
  }, [
    provider,
    library,
    addressOrAddressMap,
    ABI,
    chainId,
    fullNode,
    withSignerIfPossible,
    account,
  ]) as T;
}

export const useTokenContract = (
  context: IWeb3ReactContext,
  tokenAddress?: string,
  fullNode?: string,
  withSignerIfPossible?: boolean
) => {
  return useContract(tokenAddress, ERC20_ABI, context, fullNode, withSignerIfPossible);
};

export const useTokenERC20PermitContract = (
  context: IWeb3ReactContext,
  tokenAddress?: string,
  fullNode?: string,
  withSignerIfPossible?: boolean
) => {
  return useContract(tokenAddress, ERC20_PERMIT_ABI, context, fullNode, withSignerIfPossible);
};

export const useTokenTransferContract = (
  context: IWeb3ReactContext,
  fullNode?: string,
  withSignerIfPossible?: boolean
): Contract | null => {
  const address: string | undefined = TOKEN_TRANSFER_ADDRESS[context.chainId];
  return useContract(address, TOKEN_TRANSFER_ABI, context, fullNode, withSignerIfPossible);
};
