import classNames from "classnames";
import React from "react";
import ReactDOM from "react-dom";
import { useAppSelector } from "../../hooks/common";
import ToastMessageItem from "./ToastMessageItem";

interface Props {
  container: HTMLElement;
}

const ToastMessage = ({ container }: Props) => {
  const { toastList } = useAppSelector((state) => state.toastMessage);

  return ReactDOM.createPortal(
    <div
      className={classNames(
        "fixed flex flex-col-reverse justify-end",
        "transition-transform duration-500 ease-in-out",
        "box-border top-header right-0 z-[51]"
      )}
    >
      {toastList.map((toast) => {
        return (
          <div key={toast.id}>
            <ToastMessageItem {...toast} />
          </div>
        );
      })}
    </div>,
    container
  );
};
export default ToastMessage;
