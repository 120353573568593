import React from "react";
import { IconProps } from "../../classes/Icon";

export const DangerToastIcon: React.FC<IconProps> = ({
  size = "24",
  color = "#F2645A",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02943 21 3 16.9706 3 12C3 7.02943 7.02943 3 12 3C16.9706 3 21 7.02943 21 12Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M14.8285 9.17157L9.17163 14.8284"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.17163 9.17157L14.8285 14.8284"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
