import cn from "classnames";
import React, { useCallback, useState } from "react";

interface Props {
  onEnter?: () => void;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  classNameContainer?: string;
  inputSize?: "sm" | "md";
}

export const Input = React.forwardRef<
  HTMLInputElement,
  Props & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      className,
      classNameContainer,
      onEnter,
      autoFocus = false,
      startAdornment,
      endAdornment,
      inputSize = "md",
      ...props
    },
    ref
  ) => {
    const handleOnEnter: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        onEnter && event.key === "Enter" && onEnter();
      },
      [onEnter]
    );
    const [isFocus, setIsFocus] = useState<boolean>(autoFocus);

    const sizeClassName = cn({
      "text-sm px-4 py-2": inputSize === "sm",
      "text-md p-[11px]": inputSize === "md",
    });

    const renderWithAdornment = useCallback(() => {
      return (
        <div
          ref={ref}
          className={cn(
            "input-normal rounded-lg",
            "flex flex-row items-center justify-between gap-2",
            "transition-all ease-in-out duration-250",
            classNameContainer,
            sizeClassName,
            {
              "border-primary-80": isFocus,
            }
          )}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        >
          {startAdornment ?? startAdornment}
          <input
            onKeyDown={handleOnEnter}
            className={cn(className, "font-input outline-none", "bg-transparent w-full")}
            {...props}
          />
          {endAdornment ?? endAdornment}
        </div>
      );
    }, [
      className,
      classNameContainer,
      endAdornment,
      handleOnEnter,
      isFocus,
      props,
      ref,
      sizeClassName,
      startAdornment,
    ]);

    const renderContent = useCallback(() => {
      if (endAdornment || startAdornment) return renderWithAdornment();

      return (
        <input
          ref={ref}
          onKeyDown={handleOnEnter}
          className={cn(
            className,
            "font-input outline-none appearance-none bg-transparent",
            "transition-all ease-in-out duration-250",
            "input-normal rounded-lg",
            sizeClassName
          )}
          {...props}
        />
      );
    }, [
      className,
      endAdornment,
      handleOnEnter,
      props,
      ref,
      renderWithAdornment,
      sizeClassName,
      startAdornment,
    ]);

    return renderContent();
  }
);

Input.displayName = "Input";
