import { TAddAdmin, TAddAdminReturn } from "../../classes/Api";
import cosplayApi from "../config";

const userApi = cosplayApi.injectEndpoints({
  endpoints: (builder) => ({
    addAdmin: builder.mutation<TAddAdminReturn, TAddAdmin>({
      query: (args) => ({
        url: "/v1/api/user",
        method: "POST",
        body: args,
      }),
    }),
  }),
});

export default userApi;
