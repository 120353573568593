import cn from "classnames";
import React, { useCallback } from "react";
import { NETWORK_DETAILS } from "../../config/network";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import { useWeb3Activity } from "../../hooks/useWeb3Activity";
import { modalSliceActions } from "../../stores/modalSlice";
import { Button } from "../bases/Button";
import DashBoard from "./Dashboard";
import UserMenu from "./UserMenu";

type Props = {
  className?: string;
  title: string;
  customAction?: React.ReactNode;
  children: React.ReactNode;
};

const DefaultLayout = ({ className, title, customAction, children }: Props) => {
  const walletState = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();

  const context = useWeb3Activity();
  const { account: connectedAccount, isSupportedNetwork } = context;

  const handleSwitchNetworkClicked = useCallback(() => {
    dispatch(modalSliceActions.addToQueue({ type: "popup/switch-network" }));
  }, [dispatch]);

  const renderActionButton = useCallback((): React.ReactNode => {
    if (customAction) {
      return customAction;
    }
    if (!isSupportedNetwork) {
      return <Button className="gap-2 btn-primary">Wrong Network...</Button>;
    }
    if (context.isConnected && connectedAccount) {
      return <UserMenu />;
    }
    return <Button className="gap-2 btn-primary">Checking...</Button>;
  }, [connectedAccount, context.isConnected, customAction, isSupportedNetwork]);

  return (
    <DashBoard>
      <div className={cn("h-full p-10 !pt-0", className)}>
        <div
          className={cn(
            "flex justify-between items-center font-extrabold text-3xl",
            "bg-[#FBFAFA] sticky top-0 py-10 z-10"
          )}
        >
          {title}
          <div className="flex gap-2">
            <Button
              className="btn-secondary gap-2 !py-2 !px-3 min-w-[140px]"
              onClick={handleSwitchNetworkClicked}
            >
              <div className="w-6 h-6">{NETWORK_DETAILS[walletState.chainId].roundedIcon}</div>
              {NETWORK_DETAILS[walletState.chainId].label}
            </Button>
            {renderActionButton()}
          </div>
        </div>
        {children}
      </div>
    </DashBoard>
  );
};

export default DefaultLayout;
