import { TAddressExportedReturn, TBatchListReturn, TGenerateAddressArgs, TGenerateAddressReturn, TListQuery } from "../../classes/Api";
import cosplayApi from "../config";

const addressApi = cosplayApi.injectEndpoints({
  endpoints: (builder) => ({
    generate: builder.mutation<TGenerateAddressReturn, TGenerateAddressArgs>({
      query: (args) => ({
        url: "/v1/api/address/generate",
        method: "POST",
        body: args,
      }),
    }),

    list: builder.query<TGenerateAddressReturn, TListQuery>({
      query: (params) => ({
        url: "/v1/api/address",
        params,
      }),
    }),

    listExport: builder.query<TAddressExportedReturn, Partial<TListQuery>>({
      query: (params) => ({
        url: "/v1/api/address/export_csv",
        params,
      }),
    }),

    
    getBatch: builder.query<TBatchListReturn, undefined>({
      query: () => ({
        url: "/v1/api/address/batches",
      }),
    }),
  }),
});

export default addressApi;
