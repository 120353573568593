import * as RadixPopover from "@radix-ui/react-popover";
import cn from "classnames";
import React, { useCallback, useState } from "react";
import ReactTimePicker, { TimePickerProps } from "react-time-picker";
import { Value } from "react-time-picker/dist/cjs/shared/types";
import "react-time-picker/dist/TimePicker.css";
import ClockIcon from "../icons/ClockIcon";

export interface IReactTimePicker extends Omit<TimePickerProps, "onChange"> {
  value?: Value | null | undefined;
  onChange: (value: Value) => void;
  children?: React.ReactNode;
  className?: string | undefined;
  size?: "sm" | "md";
}

export const TimePicker = ({
  value,
  onChange,
  children,
  className,
  size = "md",
  ...props
}: IReactTimePicker) => {
  const [toggleCalendar, setToggleCalendar] = useState<boolean>(false);

  const sizeClassName = cn({
    "text-sm px-4 py-1.5": size === "sm",
    "text-base p-[11px]": size === "md",
  });

  const handleOnChange = useCallback(
    (value: Value) => {
      if (!value) return;
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className={className}>
      <RadixPopover.Root open={toggleCalendar} onOpenChange={setToggleCalendar}>
        <RadixPopover.Trigger className="w-full my-1">
          <div
            className={cn(
              "flex gap-3 justify-between box-border items-center",
              "border rounded-lg cursor-pointer select-none",
              sizeClassName,
              {
                "border-primary-80": toggleCalendar,
              }
            )}
          >
            {children || value}
            <ClockIcon />
          </div>
        </RadixPopover.Trigger>
        <RadixPopover.Content
          side="bottom"
          align="start"
          sideOffset={10}
          className="transition-all duration-250 ease-in z-10"
        >
          <ReactTimePicker
            value={value}
            onChange={handleOnChange}
            disableClock={true}
            format="HH:mm"
            clearIcon={null}
            className={cn("time-picker")}
            {...props}
          />
        </RadixPopover.Content>
      </RadixPopover.Root>
    </div>
  );
};

export default TimePicker;
