import React from "react";
import { IconProps } from "../../classes/Icon";

const TransferIcon: React.FC<IconProps> = ({ size = "24", color = "#707070", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path d="M5 4L2 7L5 10" fill={color} />
      <path
        d="M5 4L2 7L5 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19 14L22 17L19 20" fill={color} />
      <path
        d="M19 14L22 17L19 20"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 7H22Z" fill={color} />
      <path
        d="M2 7H22"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 17H22Z" fill={color} />
      <path
        d="M2 17H22"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TransferIcon;
