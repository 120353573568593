import React from "react";
import { IconProps } from "../../classes/Icon";

export const WarningIcon: React.FC<IconProps> = ({
  size = "24",
  color = "#FBBF24",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M10.702 3.74857L2.45423 17.9978C2.32225 18.2259 2.25266 18.4846 2.25244 18.748C2.25222 19.0115 2.32139 19.2703 2.45299 19.4986C2.58458 19.7268 2.77397 19.9163 3.00208 20.0481C3.2302 20.1799 3.489 20.2493 3.75244 20.2493H20.2479C20.5114 20.2493 20.7702 20.1799 20.9983 20.0481C21.2264 19.9163 21.4158 19.7268 21.5474 19.4986C21.679 19.2703 21.7482 19.0115 21.748 18.748C21.7477 18.4846 21.6781 18.2259 21.5462 17.9978L13.2984 3.74857C13.1666 3.52093 12.9773 3.33193 12.7495 3.20055C12.5216 3.06916 12.2632 3 12.0002 3C11.7371 3 11.4787 3.06916 11.2509 3.20055C11.023 3.33193 10.8337 3.52093 10.702 3.74857Z"
        fill={color}
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9V13.5"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z"
        fill="white"
      />
    </svg>
  );
};
