/* eslint-disable no-console */
import { ExternalProvider } from "@ethersproject/providers";
import { SUPPORTED_NETWORKS } from "../config/network";

interface SwitchNetworkArguments {
    provider: ExternalProvider;
    chainId: number;
}

type TErrorMobile = {
    data: {
        originalError: {
            code: number;
        };
    };
};

export const switchToNetwork = async ({ provider, chainId }: SwitchNetworkArguments) => {
    if (!provider.request) {
        return;
    }
    const params = SUPPORTED_NETWORKS[chainId];
    try {
        await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: params.chainId }],
        });
    } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (
            (error as { code: number }).code === 4902 ||
            (error as TErrorMobile)?.data?.originalError?.code === 4902
        ) {
            await provider.request({
                method: "wallet_addEthereumChain",
                params: [params],
            });
            // metamask (only known implementer) automatically switches after a network is added
            // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
            // metamask's behavior when switching to the current network is just to return null (a no-op)
            try {
                await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: params.chainId }],
                });
            } catch (error) {
                console.error("Added network but could not switch chains", error);
            }
        } else {
            console.error("Switch chain error", error);
            throw error;
        }
    }
};
