import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/bases/Button";
import CosplayIcon from "../../components/icons/CosplayIcon";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import { authActions } from "../../stores/authSlice";
import { modalSliceActions } from "../../stores/modalSlice";

const Login = () => {
  const { isAuthentication } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleConnectWalletButtonClicked = useCallback(() => {
    dispatch(
      modalSliceActions.addToQueue({
        type: "popup/switch-network",
        propsState: { connectNow: true },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (isAuthentication) {
      history.replace("/");
    } else {
      dispatch(authActions.logout());
    }
  }, [dispatch, history, isAuthentication]);

  return (
    <div className="w-screen h-screen bg-[#D9D9D9] flex">
      <div className="w-1/2 bg-[#FBFAFA] flex justify-center items-center">
        <div className="w-[440px]">
          <div className="flex gap-5 items-center">
            <CosplayIcon size={48} />
            <div className="text-grey-100 text-3xl font-extrabold">Cosplay Token Transfer</div>
          </div>
          <div className="mt-8 text-primary-80 text-2xl font-extrabold">Connect Your Wallet</div>
          <div className="mt-1 text-grey-70 text-lg font-semibold">
            Connect to continue signing in!
          </div>
          <Button className="mt-6 btn-primary w-full" onClick={handleConnectWalletButtonClicked}>
            Connect Wallet
          </Button>
        </div>
      </div>
      <img
        src="/background-login.png"
        alt="login-background"
        className="w-1/2 object-cover object-left"
      />
    </div>
  );
};

export default Login;
