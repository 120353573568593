import { CHAINS_ID } from "../constants/chains";

const RPC = {
    [CHAINS_ID.MAINNET]: "https://api.sushirelay.com/v1",
    [CHAINS_ID.ROPSTEN]: "https://eth-ropsten.alchemyapi.io/v2/cidKix2Xr-snU3f6f6Zjq_rYdalKKHmW",
    [CHAINS_ID.RINKEBY]: "https://eth-rinkeby.gateway.pokt.network/v1/lb/632309233f78900039e16090",
    [CHAINS_ID.GÖRLI]: "https://eth-goerli.g.alchemy.com/v2/302OMbWfz_PYZlNYPvSKiPEJw2kYu-1Z",
    [CHAINS_ID.KOVAN]: "https://eth-kovan.alchemyapi.io/v2/wnW2uNdwqMPes-BCf9lTWb9UHL9QP2dp",
    [CHAINS_ID.FANTOM]: "https://rpcapi.fantom.network",
    [CHAINS_ID.FANTOM_TESTNET]: "https://rpc.testnet.fantom.network",
    [CHAINS_ID.MATIC]: "https://polygon-rpc.com/",
    [CHAINS_ID.MATIC_TESTNET]: "https://polygon-mumbai.gateway.pokt.network/v1/lb/632309233f78900039e16090",
    [CHAINS_ID.XDAI]: "https://rpc.gnosischain.com",
    [CHAINS_ID.BSC]: "https://bsc-dataseed.binance.org/",
    [CHAINS_ID.BSC_TESTNET]: "https://data-seed-prebsc-1-s3.binance.org:8545",
    [CHAINS_ID.MOONBEAM_TESTNET]: "https://rpc.testnet.moonbeam.network",
    [CHAINS_ID.AVALANCHE]: "https://api.avax.network/ext/bc/C/rpc",
    [CHAINS_ID.AVALANCHE_TESTNET]: "https://api.avax-test.network/ext/bc/C/rpc",
    [CHAINS_ID.HECO]: "https://http-mainnet.hecochain.com",
    [CHAINS_ID.HECO_TESTNET]: "https://http-testnet.hecochain.com",
    [CHAINS_ID.HARMONY]: "https://api.harmony.one",
    [CHAINS_ID.HARMONY_TESTNET]: "https://api.s0.b.hmny.io",
    [CHAINS_ID.OKEX]: "https://exchainrpc.okex.org",
    [CHAINS_ID.OKEX_TESTNET]: "https://exchaintestrpc.okex.org",
    [CHAINS_ID.ARBITRUM]: "https://arb1.arbitrum.io/rpc",
    [CHAINS_ID.PALM]: "https://palm-mainnet.infura.io/v3/da5fbfafcca14b109e2665290681e267",
    [CHAINS_ID.FUSE]: "https://rpc.fuse.io",
    [CHAINS_ID.CELO]: "https://forno.celo.org",
    [CHAINS_ID.MOONRIVER]: "https://rpc.moonriver.moonbeam.network",
};

export default RPC;
