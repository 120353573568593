import React from "react";
import { IconProps } from "../../classes/Icon";

const CloseIcon: React.FC<IconProps> = ({ size = "16", color = "#B3B3B3", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <g clipPath="url(#clip0_97_5902)">
        <path
          d="M16 1.6L14.4 0L8 6.4L1.6 0L0 1.6L6.4 8L0 14.4L1.6 16L8 9.6L14.4 16L16 14.4L9.6 8L16 1.6Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_97_5902">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
