import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import DownOneIcon from "../icons/DownOneIcon";

export type TOption = {
  label: string;
  value: string | number;
  icon?: React.ReactNode;
};

type Props = {
  options: TOption[];
  children?: React.ReactNode;
  disabled?: boolean;
  optionsClass?: string;
  optionClass?: string;
  onChange?: (value: string | number, label?: string) => void;
  defaultValue?: string | number;
  customClassName?: string;
  placeHolder?: string;
  textClassName?: string;
  iconClassName?: string;
  size?: "sm" | "md";
};

function SelectBox({
  options,
  placeHolder,
  disabled = false,
  optionsClass = "",
  optionClass = "",
  onChange,
  defaultValue,
  customClassName,
  textClassName,
  iconClassName,
  size = "md",
}: Props) {
  const [selected, setSelected] = useState<TOption>(
    options.find((option) => option.value === defaultValue) || {
      label: "",
      value: "",
      icon: undefined,
    }
  );
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectionPosition, setSelectionPosition] = useState("");
  const [generatedId, setGeneratedId] = useState("");
  const handleExpanded = useCallback(() => {
    if (disabled) return;
    setExpanded((state) => !state);
  }, [disabled]);

  const sizeClassName = cn({
    "text-sm px-4 py-1.5": size === "sm",
    "text-base px-4 py-[11px] ": size === "md",
  });

  const sizeClassNameOption = cn({
    "px-4 py-2 text-sm": size === "sm",
    "px-4 py-3 text-base": size === "md",
  });

  const handleItemOnClick = useCallback(
    (option: TOption) => {
      if (option.value === selected.value) return;
      setSelected(option);
      onChange && onChange(option.value, option?.label);
    },
    [onChange, selected]
  );

  const handleCollapse = useCallback(() => {
    setExpanded(false);
  }, []);

  useEffect(() => {
    const position = document
      .getElementById(`selection-list-${generatedId}`)
      ?.getBoundingClientRect();
    const clientHeight = window.innerHeight;
    const positionSelectBox = document
      .getElementById(`selection-box-${generatedId}`)
      ?.getBoundingClientRect();

    if (!position || !positionSelectBox) return;
    if (position?.height + positionSelectBox?.bottom + 50 > clientHeight) {
      setSelectionPosition(`-${position.height + 8}px`);
    } else {
      setSelectionPosition("");
    }
  }, [expanded, generatedId]);

  useEffect(() => {
    const random = `${Math.floor(Math.random() * 1000)}-${Math.floor(Math.random() * 10000)}`;
    setGeneratedId(random);
  }, []);

  useEffect(() => {
    setSelected(
      options.find((option) => option.value === defaultValue) || {
        label: "",
        value: "",
        icon: undefined,
      }
    );
  }, [defaultValue, options]);

  return (
    <div
      onClick={handleExpanded}
      className={cn(
        "relative flex items-center cursor-pointer",
        "border border-grey-40 rounded-lg !outline-none select-none",
        "transition-all ease-in-out duration-250",
        sizeClassName,
        {
          "cursor-not-allowed text-grey-40 bg-grey-40 border-grey-40": disabled,
          "border-primary-80": expanded,
        },
        customClassName
      )}
      tabIndex={0}
      onBlur={handleCollapse}
      id={`selection-box-${generatedId}`}
    >
      <div className={cn("text-black text-base mr-5 flex flex-row items-center", textClassName)}>
        {selected.icon && <div className="w-5 h-5 mr-3">{selected.icon}</div>}
        {selected?.label ? selected.label : placeHolder || "Select"}
      </div>

      <div
        className={cn(
          "absolute top-1/2 right-[12px] -translate-y-1/2",
          "transition-transform duration-250",
          {
            "rotate-180 ": expanded,
            "opacity-50": disabled,
          }
        )}
      >
        <DownOneIcon className={iconClassName} size={14} />
      </div>
      {expanded && (
        <div
          className={cn(
            "absolute z-10 left-0 right-0 top-full py-[8px]",
            "bg-white rounded-md drop-shadow-[0px_5px_20px_rgba(0,0,0,0.1)]",
            "overflow-y-auto scrollbar-thin transition-all duration-250 ease-in",
            expanded ? "max-h-[210px]" : "max-h-0 h-0",
            optionsClass
          )}
          id={`selection-list-${generatedId}`}
          style={{
            top: selectionPosition,
            marginTop: selectionPosition ? "0" : "8px",
          }}
        >
          {options?.map((option, index) => (
            <div
              onClick={() => {
                handleItemOnClick(option);
              }}
              key={index}
              className={cn(
                "hover:bg-grey-10 hover:text-primary-80",
                "flex flex-row items-center",
                sizeClassNameOption,
                optionClass
              )}
            >
              {option.icon && <div className="h-5 w-5 mr-3">{option.icon}</div>}
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectBox;
