import cn from "classnames";
import { BigNumber, Contract } from "ethers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import dataTransferApi from "../apis/endpoints/dataTransfer";
import { TWalletImported } from "../classes/Api";
import {
  BLOCK_GAS_LIMIT,
  ERROR_TRANSACTION,
  MAX_LIMIT,
  NATIVE_TOKEN_SYMBOL,
  SYSTEM_TYPE,
  ZERO_ADDRESS,
} from "../constants/constant";
import { useAppDispatch, useAppSelector } from "../hooks/common";
import { ApprovalState, useApproveCallback } from "../hooks/useApproveToken";
import { useBlockNumber } from "../hooks/useBlockNumber";
import { useTokenERC20PermitContract, useTokenTransferContract } from "../hooks/useContract";
import useDebounce from "../hooks/useDebounce";
import { useWeb3Activity } from "../hooks/useWeb3Activity";
import { toastMessageActions } from "../stores/toastMessageSlice";
import { transferTokenActions } from "../stores/transferTokenSlice";
import { addTransaction } from "../stores/walletSlice";
import { formatBalance, formatIntBalance, parseBalance } from "../utils/format";
import { Button } from "./bases/Button";
import { Input } from "./bases/Input";
import RadioButton from "./bases/RadioButton";
import SelectBox from "./bases/SelectBox";
import Spinner from "./commons/Spinner";
import { TOption } from "../components/bases/SelectBox";
import { modalSliceActions } from "../stores/modalSlice";
import ERC20_ABI from "../constants/abis/erc20";
import { Provider, TransactionResponse } from "@ethersproject/providers";

type Props = {
  className?: string;
  randomChecked?: boolean;
  handleSetRandom: () => void;
};

const transferTypeOption: TOption[] = [
  {
    label: "Multi Send",
    value: "multi",
  },
  {
    label: "Separate Send",
    value: "separate",
  },
];

const TransferTokenController = ({ className, randomChecked, handleSetRandom }: Props) => {
  const {
    selectedItems,
    transferFilters,
    batchList,
    transferType,
    walletAddressList,
    isFetching,
    metaRes: { itemCount },
  } = useAppSelector((state) => state.transferToken);
  const { transactions } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();

  const [numberOfRandom, setNumberOfRandom] = useState<string>("");
  const [balanceOfToken, setBalanceOfToken] = useState<string>("-");
  const [balanceOfERC20, setBalanceOfERC20] = useState<string>("-");
  const [ERC20TokenSymbol, setERC20TokenSymbol] = useState<string>("");
  const [totalAmount, setTotalAmount] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [randomList, setRandomList] = useState<TWalletImported[]>([]);

  const randomNumberDebounce = useDebounce(numberOfRandom, 100);

  const [getRandomList, getRandomResponse] = dataTransferApi.useLazyGetRandomListQuery();
  const [isFetchingRandomData, setisFetchingRandomData] = useState<boolean>(false);

  const context = useWeb3Activity();
  const latestBlock = useBlockNumber(context.chainId);

  const tokenTransferContract = useTokenTransferContract(context);

  const errorMsg = useMemo(() => {
    return {
      required: "This field is required",
      greater: `Please do not enter greater than ${itemCount} ${
        itemCount === 1 ? "address" : "addresses"
      }`,
      isZero: "Number random must be greater than 0",
      exceedLimit: "The maximum random number is 800",
      invalidNative: "Invalid native token transfer",
    };
  }, [itemCount]);

  const isTransactionPending = useMemo(() => {
    if (!transactions[context.chainId]) return false;
    return !!Object.values(transactions[context.chainId]).find(
      (tx) => tx.action === "transfer-token"
    );
  }, [context.chainId, transactions]);

  const { listAddressTransfer, tokenAddress } = useMemo(() => {
    const listAddressTransfer = randomChecked ? randomList : selectedItems;

    return {
      listAddressTransfer,
      tokenAddress: listAddressTransfer[0] ? listAddressTransfer[0].token.address : undefined,
    };
  }, [randomChecked, randomList, selectedItems]);

  const tokenContract = useTokenERC20PermitContract(context, walletAddressList[0]?.token?.address);

  const [tokenDecimals, approvalState, handleApprove] = useApproveCallback(
    context,
    tokenAddress,
    BigNumber.from(totalAmount || 0),
    tokenTransferContract?.address,
    latestBlock
  );

  const handleMultiSend = async (
    listAddressTransfer: TWalletImported[],
    addressOfToken: string | undefined,
    decimals: number
  ) => {
    try {
      if (!tokenTransferContract) return;
      if (!addressOfToken) return;

      const getAddressList = listAddressTransfer.map((element) => element.address.trim());
      const getAmountList = listAddressTransfer.map((element) =>
        parseBalance(element.amount, decimals)
      );
      let totalAmount = BigNumber.from(0);

      if (addressOfToken === ZERO_ADDRESS) {
        for (const amount of getAmountList) {
          totalAmount = BigNumber.from(totalAmount).add(BigNumber.from(amount));
        }
      }

      const args = [addressOfToken, getAddressList, getAmountList, SYSTEM_TYPE];

      const gasLimit =
        addressOfToken === ZERO_ADDRESS
          ? await tokenTransferContract.estimateGas.multiSend(...args, { value: totalAmount })
          : await tokenTransferContract.estimateGas.multiSend(...args);
      if (BigNumber.from(gasLimit).gt(BLOCK_GAS_LIMIT[context.chainId])) {
        dispatch(
          toastMessageActions.addToastMessage({
            title: "Exceeds block gas limit",
            type: "danger",
          })
        );
        return;
      }
      const tx =
        addressOfToken === ZERO_ADDRESS
          ? await tokenTransferContract.multiSend(...args, {
              gasLimit: gasLimit,
              value: totalAmount,
            })
          : await tokenTransferContract.multiSend(...args, {
              gasLimit,
            });

      dispatch(
        addTransaction({
          action: "transfer-token",
          chainId: context.chainId,
          hash: tx.hash,
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch ({ code, reason, data }: any) {
      setNumberOfRandom("");
      const errMsg = Object(data).message;

      if (
        code === ERROR_TRANSACTION.reject.codeNumber ||
        code === ERROR_TRANSACTION.reject.codeString
      ) {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Signature rejected",
          })
        );
        return;
      }

      if (reason === ERROR_TRANSACTION.balanceERC20.codeString) {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Insufficient ERC20 tokens",
          })
        );
      } else if (errMsg && errMsg.includes(ERROR_TRANSACTION.balanceNative.codeString1)) {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Insufficient native tokens",
          })
        );
      } else if (
        reason &&
        (String(reason).includes(ERROR_TRANSACTION.balanceNative.codeString2) ||
          String(reason).includes(ERROR_TRANSACTION.balanceNative.codeString3))
      ) {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Insufficient native tokens",
          })
        );
      } else {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Something went wrong!",
          })
        );
      }
    }
  };

  const handleSendButtonClicked = useCallback(
    async (): Promise<void> => {
      if (!tokenTransferContract) return;
      if (randomChecked && !numberOfRandom) {
        return setError(errorMsg.required);
      }
      if (error) return;

      if (!listAddressTransfer.length) {
        dispatch(
          toastMessageActions.addToastMessage({
            title: "Please select receive address",
            type: "danger",
          })
        );
        return;
      }
      const decimals = await tokenDecimals;

      if (transferType === "multi") {
        await handleMultiSend(listAddressTransfer, tokenAddress, decimals);
      } else {
        dispatch(
          modalSliceActions.addToQueue({
            type: "popup/confirm-transfer",
            propsState: {
              listAddressTransfer: listAddressTransfer,
              addressOfToken: tokenAddress,
              decimals: parseInt(String(decimals)),
            },
          })
        );
      }
    },
    // eslint-disable-next-line
    [
      dispatch,
      error,
      errorMsg.required,
      listAddressTransfer,
      numberOfRandom,
      randomChecked,
      tokenTransferContract,
      tokenDecimals,
      tokenAddress,
      transferType,
    ]
  );

  const handleOnNumberChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const regex = /^[0-9]+$/;
      if (value && !value.match(regex)) return;
      setNumberOfRandom(value);
      if (transferFilters.batch !== "all" && !walletAddressList[0].token) {
        return setError(errorMsg.invalidNative);
      }
      if (!value) return setError(errorMsg.required);
      if (!Number(value)) return setError(errorMsg.isZero);
      if (Number(value) > Number(MAX_LIMIT)) return setError(errorMsg.exceedLimit);
      if (Number(value) > Number(itemCount)) return setError(errorMsg.greater);
      setisFetchingRandomData(true);
      return setError("");
    },
    [
      errorMsg.exceedLimit,
      errorMsg.greater,
      errorMsg.isZero,
      errorMsg.required,
      itemCount,
      errorMsg.invalidNative,
      walletAddressList,
      transferFilters.batch,
    ]
  );

  const handleBatchSelected = useCallback(
    (value: string | number) => {
      dispatch(transferTokenActions.handleChangeFilters({ batch: value, page: 1 }));
      dispatch(transferTokenActions.handleSetFetchingData(true));
      dispatch(transferTokenActions.handleSetSelectedItems([]));
      dispatch(transferTokenActions.handleChangeTransferType("multi"));
      setError("");
    },
    [dispatch]
  );

  const handleTransferTypeSelected = useCallback(
    (value: string | number) => {
      dispatch(transferTokenActions.handleChangeTransferType(value));
    },
    [dispatch]
  );

  const handleSelectRadioButton = useCallback(() => {
    setError("");
    dispatch(transferTokenActions.handleSetSelectedItems([]));
    setNumberOfRandom("");
    dispatch(transferTokenActions.handleChangeTransferType("multi"));
    handleSetRandom();
  }, [dispatch, handleSetRandom]);

  useEffect(() => {
    if (listAddressTransfer.length > 0) {
      const tokenContract = new Contract(
        listAddressTransfer[0].token.address,
        ERC20_ABI,
        context.library.getSigner(context.account).connectUnchecked() as Provider
      );
      tokenContract.decimals().then((decimals: TransactionResponse) => {
        const totalAmountBN = listAddressTransfer.reduce(
          (pre, cur) =>
            BigNumber.from(pre)
              .add(parseBalance(cur.amount, Number(decimals)))
              .toString(),
          "0"
        );
        setTotalAmount(totalAmountBN);
      });
    } else {
      setTotalAmount("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAddressTransfer]);

  useEffect(
    () => {
      if (!randomNumberDebounce || Number(randomNumberDebounce) > MAX_LIMIT) return;
      if (error) return;

      const { address, batch } = transferFilters;
      getRandomList({
        randomNumber: randomNumberDebounce,
        address,
        batch,
      }).then(({ data }) => {
        if (!data) return;
        const list = data?.result;
        if (list.length < Number(numberOfRandom)) {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "warning",
              title: "Warning",
              description: `Valid data to get random is not enough: Only get ${list.length} addresses for list random`,
            })
          );
        }
        setRandomList(list);
      });
    },
    // eslint-disable-next-line
    [getRandomList, randomNumberDebounce, transferFilters]
  );

  useEffect(
    () => {
      (async () => {
        if (!context.account) return;

        const balance = await context.library.getBalance(context.account);
        setBalanceOfToken(formatIntBalance(formatBalance(balance)));
      })();
    },
    // eslint-disable-next-line
    [context.account, latestBlock]
  );

  useEffect(() => {
    if (isFetching) setNumberOfRandom("");
  }, [isFetching]);

  useEffect(
    () => {
      (async () => {
        if (!context.account) return;
        if (!tokenContract) {
          if (balanceOfERC20 !== "-") setBalanceOfERC20("-");
          if (ERC20TokenSymbol !== "") setERC20TokenSymbol("");
          return;
        }
        if (walletAddressList.length === 0) {
          if (ERC20TokenSymbol !== "") setERC20TokenSymbol("");
        }

        const decimals = await tokenContract.decimals();

        if (transferFilters.batch === "all") {
          if (balanceOfERC20 !== "-") setBalanceOfERC20("-");
          if (ERC20TokenSymbol !== "") setERC20TokenSymbol("");
          return;
        }

        const balance = await tokenContract.balanceOf(context.account);
        setBalanceOfERC20(formatIntBalance(formatBalance(balance, decimals)));
        setERC20TokenSymbol(walletAddressList[0].symbol.toUpperCase());
      })();
    },
    // eslint-disable-next-line
    [context.account, latestBlock, tokenContract]
  );

  useEffect(() => {
    if (getRandomResponse.isFetching || getRandomResponse.isLoading) {
      setisFetchingRandomData(true);
    } else {
      setisFetchingRandomData(false);
    }
  }, [getRandomResponse.isFetching, getRandomResponse.isLoading]);

  const renderButton = useCallback(() => {
    if (approvalState === ApprovalState.NOT_APPROVED) {
      return (
        <Button
          className="mt-1 btn-primary"
          size="sm"
          onClick={handleApprove}
          disabled={isFetchingRandomData || error.length > 0}
        >
          Approve
        </Button>
      );
    }

    if (approvalState === ApprovalState.PENDING) {
      return (
        <Button className="mt-1 btn-primary gap-2" size="sm" disabled={true}>
          <Spinner size="medium" /> Approving
        </Button>
      );
    }

    if (isTransactionPending) {
      return (
        <Button className="mt-1 btn-primary gap-2" size="sm" disabled={true}>
          <Spinner size="medium" /> Sending
        </Button>
      );
    }

    return (
      <Button
        className="mt-1 btn-primary"
        size="sm"
        onClick={handleSendButtonClicked}
        disabled={isFetchingRandomData || error.length > 0}
      >
        Send
      </Button>
    );
  }, [
    approvalState,
    isFetchingRandomData,
    handleApprove,
    handleSendButtonClicked,
    isTransactionPending,
    error.length,
  ]);

  return (
    <div
      className={cn("bg-white border border-grey-20 rounded-lg shadow-panel py-6 px-8", className)}
    >
      <div className="grid grid-cols-2 gap-8 mb-5">
        <div className="flex flex-col">
          <div className="flex flex-row gap-3 items-center">
            <div className="text-sm text-grey-80">Available native</div>
            <div className="text-base font-extrabold">
              {!NATIVE_TOKEN_SYMBOL[context.chainId]
                ? "-"
                : `${balanceOfToken} ${NATIVE_TOKEN_SYMBOL[context.chainId]}`}
            </div>
          </div>
          {transferFilters.batch !== "all" &&
            walletAddressList.length !== 0 &&
            tokenContract &&
            walletAddressList[0]?.token?.address !== ZERO_ADDRESS && (
              <div className="flex flex-col">
                <div className="flex flex-row gap-3 items-center">
                  <div className="text-sm text-grey-80">Available ERC20</div>
                  <div className="text-base font-extrabold">{`${balanceOfERC20} ${ERC20TokenSymbol}`}</div>
                </div>
              </div>
            )}
        </div>
        <div className="flex flex-row gap-36">
          <RadioButton
            checked={!randomChecked}
            onClick={() => {
              if (randomChecked) {
                handleSelectRadioButton();
              }
            }}
          >
            No random
          </RadioButton>
          <RadioButton
            checked={randomChecked}
            onClick={() => {
              if (!randomChecked) {
                handleSelectRadioButton();
              }
            }}
          >
            Random
          </RadioButton>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-8 w-full">
        <div className="flex flex-col">
          <div className="text-sm font-semibold text-grey-80 mb-1">Batch Address</div>
          <SelectBox
            customClassName="w-full"
            options={batchList}
            defaultValue={transferFilters.batch}
            onChange={handleBatchSelected}
          />
        </div>
        {randomChecked ? (
          <div className="grid grid-cols-2 gap-4 w-full">
            <div className="flex flex-col">
              <div className="text-sm font-semibold text-grey-80 mb-1">Transfer Type</div>
              <SelectBox
                customClassName="w-full"
                options={transferTypeOption}
                defaultValue={transferType}
                onChange={handleTransferTypeSelected}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-sm font-semibold text-grey-80 mb-1">Number random</div>
              <Input
                className={cn("w-full", { "!border-danger": error })}
                inputSize="md"
                placeholder="Number random"
                value={numberOfRandom}
                onChange={handleOnNumberChanged}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="text-sm font-semibold text-grey-80 mb-1">Transfer Type</div>
            <SelectBox
              customClassName="w-full"
              options={transferTypeOption}
              defaultValue={transferType}
              onChange={handleTransferTypeSelected}
              disabled={isTransactionPending ? true : false}
            />
          </div>
        )}
      </div>
      <div className="w-full grid grid-cols-2 gap-8 mt-1 h-4">
        <div />
        <div className="grid grid-cols-2 gap-4 w-full mt-1">
          <div />
          <div className="text-xs text-danger">{error && error}</div>
        </div>
      </div>
      {renderButton()}
    </div>
  );
};

export default TransferTokenController;
