import React from "react";
import { IconProps } from "../../classes/Icon";

const DownOneIcon: React.FC<IconProps> = ({
  size = "20",
  color = "#707070",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M18 6L10 14.0001L2 6H18Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownOneIcon;
