import { useCallback } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppSelector } from "../../hooks/common";

/* eslint-disable @typescript-eslint/no-explicit-any */
const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isAuthentication } = useAppSelector((state) => state.auth);

  const routeComponent = useCallback(
    (props: any) => {
      if (!isAuthentication) {
        return <Redirect to={{ pathname: "/login" }} />;
      }
      return <Component {...props} />;
    },
    [Component, isAuthentication]
  );
  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
