import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import authApi from "../../apis/endpoints/auth";
import StorageUtils, { STORAGE_KEYS } from "../../utils/storage";

type TAuthState = {
  isAuthentication: boolean;
  userAddress: string;
  decryptPrivateKey: string | null;
};

const initialState: TAuthState = {
  isAuthentication: !!StorageUtils.getSectionStorageItem(STORAGE_KEYS.TOKEN_ACCESS),
  userAddress: "",
  decryptPrivateKey: "",
};

const _logout: CaseReducer<TAuthState, PayloadAction> = (state) => {
  state.isAuthentication = false;
  state.userAddress = "";
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logout: _logout,
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      state.isAuthentication = true;
      const { accessToken, user } = action.payload.result;
      state.userAddress = user.walletAddress;
      state.decryptPrivateKey = user.decryptPrivateKey;
      StorageUtils.setSectionStorageItem(STORAGE_KEYS.TOKEN_ACCESS, accessToken);
    });
    builder.addMatcher(authApi.endpoints.login.matchRejected, (state) => {
      state.isAuthentication = false;
      state.userAddress = "";
    });
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
