import React from "react";
import { IconProps } from "../../classes/Icon";

const CopyIcon: React.FC<IconProps> = ({ size = "20", color = "#707070", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <g clipPath="url(#clip0_445_119)">
        <path
          d="M16.6667 7.5459H9.16667C8.24619 7.5459 7.5 8.29209 7.5 9.21257V16.7126C7.5 17.6331 8.24619 18.3793 9.16667 18.3793H16.6667C17.5871 18.3793 18.3333 17.6331 18.3333 16.7126V9.21257C18.3333 8.29209 17.5871 7.5459 16.6667 7.5459Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16699 12.5462H3.33365C2.89163 12.5462 2.4677 12.3706 2.15514 12.0581C1.84258 11.7455 1.66699 11.3216 1.66699 10.8796V3.37956C1.66699 2.93753 1.84258 2.51361 2.15514 2.20105C2.4677 1.88849 2.89163 1.71289 3.33365 1.71289H10.8336C11.2756 1.71289 11.6996 1.88849 12.0121 2.20105C12.3247 2.51361 12.5003 2.93753 12.5003 3.37956V4.21289"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_445_119">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
