import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import transactionApi from "../../apis/endpoints/transaction";
import { TTransactionListQuery, TTxnData } from "../../classes/Api";

type TTransactionHistoryState = {
  transactionList: TTxnData[];
  queryFilters: TTransactionListQuery;
  totalItems: number;
  updater: number; 
  oneTransactionData?: TTxnData;
};

const initialState: TTransactionHistoryState = {
  transactionList: [],
  queryFilters: {
    orderBy: "updatedAt",
    orderDir: "DESC",
    page: 1,
    searchEq: "",
    take: 10,
    fromDate: moment(new Date().setFullYear(new Date().getFullYear() - 1)).format("MM/DD/YYYY"),
    toDate: moment(new Date()).format("MM/DD/YYYY"),
    transferType: "DEFAULT",
  },
  totalItems: 0,
  updater: Date.now(),

  oneTransactionData: undefined,
};

const _handleChangeFilters: CaseReducer<
  TTransactionHistoryState,
  PayloadAction<Partial<TTransactionListQuery>>
> = (state, action) => {
  state.queryFilters = {
    ...state.queryFilters,
    ...action.payload,
  };
};

const _clearState: CaseReducer<TTransactionHistoryState> = (state) => {
  state.transactionList = [];
  state.queryFilters = { ...initialState.queryFilters };
};

const _clearOneTransactionData: CaseReducer<TTransactionHistoryState> = (state) => {
  state.oneTransactionData = undefined;
};

const transactionHistorySlice = createSlice({
  name: "transactionHistorySlice",
  initialState,
  reducers: {
    handleChangeFilters: _handleChangeFilters,
    clearState: _clearState,
    clearOneTransactionData: _clearOneTransactionData,
  },
  extraReducers: (builder) => {
    builder.addMatcher(transactionApi.endpoints.transactionList.matchFulfilled, (state, action) => {
      state.transactionList = action.payload.result.data;
      state.totalItems = action.payload.result.meta.itemCount;
    });

    builder.addMatcher(transactionApi.endpoints.getOne.matchFulfilled, (state, action) => {
      state.oneTransactionData = action.payload.result;
    });
  },
});

export const transactionHistoryActions = transactionHistorySlice.actions;
export default transactionHistorySlice.reducer;
