import { TLoginArgs, TLoginReturn } from "../../classes/Api";
import cosplayApi from "../config";

const authApi = cosplayApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<TLoginReturn, TLoginArgs>({
      query: (args) => ({
        url: "/v1/api/auth/login",
        method: "POST",
        body: args,
      }),
    }),
  }),
});

export default authApi;
