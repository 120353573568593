import cn from "classnames";
import React from "react";

type Props = React.HTMLAttributes<HTMLTableSectionElement>;

export const NoRecordTable = ({ className, ...props }: Props) => {
  return (
    <div
      className={cn("flex w-full items-center justify-center h-[200px]", className)}
      {...props}
    >
      No record
    </div>
  );
};
