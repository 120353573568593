import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Column, usePagination, useTable } from "react-table";
import dataTransferApi from "../../apis/endpoints/dataTransfer";
import { TErrorReturn, TListQuery, TWalletImported } from "../../classes/Api";
import CheckBox from "../../components/bases/CheckBox";
import Table, { TablePagination } from "../../components/bases/Table";
import Tooltip from "../../components/bases/Tooltip";
import Spinner from "../../components/commons/Spinner";
import DefaultLayout from "../../components/layout/DefaultLayout";
import TransferTokenController from "../../components/TransferTokenController";
import { DEFAULT_BATCH_OPTION, MAX_LIMIT } from "../../constants/constant";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import useDebounce from "../../hooks/useDebounce";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { transferTokenActions } from "../../stores/transferTokenSlice";
import { formatIntBalance } from "../../utils/format";
import { removeEmptyProperties } from "../../utils/utils";

const TransferToken = () => {
  const [getBatchList] = dataTransferApi.useLazyGetListBatchQuery();
  const [getWalletList, getWalletListResponse] = dataTransferApi.useLazyGetListQuery();
  const [getFullList, getFullListState] = dataTransferApi.useLazyGetFullListQuery();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [randomChecked, setRandomChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    transferFilters,
    metaRes: { itemCount: totalItems },
    walletAddressList,
    updater,
    selectedItems,
    saveList,
    isFetching,
  } = useAppSelector((state) => state.transferToken);

  const filtersDebounce = useDebounce(transferFilters, 400);

  const numPages = useMemo(() => {
    const controlledPageCount = Math.ceil(Number(totalItems) / Number(transferFilters.take));
    return controlledPageCount;
  }, [totalItems, transferFilters.take]);

  const handleRowSelected = useCallback(
    (item: TWalletImported, selected: boolean) => {
      if (!item.token) {
        dispatch(
          toastMessageActions.addToastMessage({
            title: "Error",
            description: "Invalid native token transfer",
            type: "danger",
          })
        );
        return;
      }
      if (selected) {
        dispatch(transferTokenActions.handleUnselectItem(item.id));
      } else {
        if (selectedItems.length === MAX_LIMIT) {
          dispatch(
            toastMessageActions.addToastMessage({
              title: "Error",
              description: "Maximum amount address is 800",
              type: "danger",
            })
          );
          return;
        }
        if (selectedItems.length > 0 && item.token.address !== selectedItems[0].token.address) {
          dispatch(
            toastMessageActions.addToastMessage({
              title: "Error",
              description: "Must select item with the same token",
              type: "danger",
            })
          );
          return;
        }
        dispatch(transferTokenActions.handleSelectItem(item));
      }
    },
    [dispatch, selectedItems]
  );

  const handleSelectAllItems = useCallback(() => {
    if (Number(totalItems) > 0 && !walletAddressList[0].token) {
      dispatch(
        toastMessageActions.addToastMessage({
          title: "Error",
          description: "Invalid native token transfer",
          type: "danger",
        })
      );
      return;
    }
    if (Number(totalItems) > MAX_LIMIT) {
      dispatch(
        toastMessageActions.addToastMessage({
          title: "Error",
          description: "Maximum amount address is 800",
          type: "danger",
        })
      );
      return;
    }
    setSelectAll((prev) => !prev);
  }, [dispatch, totalItems, walletAddressList]);

  const columns: Column<TWalletImported>[] = useMemo((): Column<TWalletImported>[] => {
    const result: Column<TWalletImported>[] = [
      {
        Header: "No.",
        accessor: "id",
        width: "10%",
        Cell: ({ index, row }) => {
          return (
            <Table.Cell className="pl-8" key={index}>
              {row.index + (Number(transferFilters.page) - 1) * Number(transferFilters.take) + 1}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Address",
        accessor: "address",
        width: "35%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8" key={index}>
              {value}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        width: "15%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8" key={index}>
              <Tooltip message={formatIntBalance(value, 18, 2)}>
                {formatIntBalance(value, 18, 2).split(".")[1].length > 2
                  ? `${formatIntBalance(value, 2, 2)}...`
                  : formatIntBalance(value, 2, 2)}
              </Tooltip>
            </Table.Cell>
          );
        },
      },
      {
        Header: "Token",
        accessor: "symbol",
        width: `${!randomChecked ? "15%" : "20%"}`,
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              {value.toUpperCase()}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Batch",
        accessor: "batch",
        width: `${!randomChecked ? "15%" : "20%"}`,
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              {value}
            </Table.Cell>
          );
        },
      },
    ];

    if (!randomChecked) {
      result.unshift({
        Header:
          getFullListState.isFetching || getFullListState.isLoading ? (
            <Spinner size="medium" variant="secondary" />
          ) : (
            <CheckBox
              checked={!!totalItems && selectedItems.length >= totalItems}
              onClick={handleSelectAllItems}
            />
          ),
        accessor: "updatedAt",
        width: "10%",
        Cell: ({ index, dataCell }) => {
          const selected = selectedItems.some((item) => item.id === dataCell.id);
          return (
            <Table.Cell className="pl-8" key={index}>
              {getFullListState.isFetching || getFullListState.isLoading ? (
                <Spinner size="medium" variant="secondary" />
              ) : (
                <CheckBox
                  checked={selected}
                  onClick={() => handleRowSelected(dataCell, selected)}
                />
              )}
            </Table.Cell>
          );
        },
      });
    }

    return result;
  }, [
    handleRowSelected,
    handleSelectAllItems,
    getFullListState.isFetching,
    getFullListState.isLoading,
    randomChecked,
    selectedItems,
    totalItems,
    transferFilters.page,
    transferFilters.take,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: walletAddressList,
      manualPagination: true,
      initialState: {
        pageSize: transferFilters.take,
        pageIndex: Number(transferFilters.page) - 1,
      },
      pageCount: numPages,
      autoResetPage: false,
    },
    usePagination
  );

  const handleNext = useCallback(() => {
    if (canNextPage) {
      dispatch(
        transferTokenActions.handleChangeFilters({ page: Number(transferFilters.page) + 1 })
      );
      nextPage();
    }
  }, [canNextPage, dispatch, nextPage, transferFilters.page]);

  const handlePrev = useCallback(() => {
    if (canPreviousPage) {
      dispatch(
        transferTokenActions.handleChangeFilters({ page: Number(transferFilters.page) - 1 })
      );
      previousPage();
    }
  }, [canPreviousPage, dispatch, previousPage, transferFilters.page]);

  const handleGotoPage = useCallback(
    (updater: number) => {
      dispatch(transferTokenActions.handleChangeFilters({ page: updater + 1 }));
      gotoPage(updater);
    },
    [dispatch, gotoPage]
  );

  const handleSetRandom = useCallback(() => {
    setRandomChecked((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isFetching || (totalItems && totalItems > MAX_LIMIT)) return;
    if (selectAll) {
      if (transferFilters.batch && !saveList[transferFilters.batch]) {
        const params: Partial<TListQuery> = removeEmptyProperties({
          ...transferFilters,
          batch:
            transferFilters.batch === DEFAULT_BATCH_OPTION[0].value ? "" : transferFilters.batch,
          take: totalItems,
        });
        getFullList(params);
      }
    }
  }, [getFullList, isFetching, saveList, selectAll, totalItems, transferFilters]);

  useEffect(() => {
    const error = getFullListState.error;
    if (error && selectAll) {
      if ((error as TErrorReturn).status === 400) {
        dispatch(
          toastMessageActions.addToastMessage({
            title: "Error",
            description: (error as TErrorReturn).data.message?.[0] || "",
            type: "danger",
          })
        );
      }
      setSelectAll(false);
    }
  }, [dispatch, getFullListState.error, selectAll]);

  useEffect(() => {
    if (!selectAll) {
      dispatch(transferTokenActions.handleSetSelectedItems([]));
    } else {
      if (transferFilters.batch && saveList[transferFilters.batch]) {
        dispatch(transferTokenActions.handleSetSelectedItems(saveList[transferFilters.batch]));
      }
    }
  }, [dispatch, saveList, selectAll, transferFilters.batch]);

  useEffect(
    () => {
      if (selectedItems.length === 0) {
        setSelectAll(false);
      } else if (totalItems && selectedItems.length >= totalItems) {
        if (!selectAll) setSelectAll(true);
      }
    },
    // eslint-disable-next-line
    [selectedItems.length, totalItems]
  );

  useEffect(() => {
    if (transferFilters.page === 1) {
      gotoPage(0);
    }
  }, [gotoPage, transferFilters]);

  useEffect(() => {
    const params: Partial<TListQuery> = removeEmptyProperties({
      ...filtersDebounce,
      batch: filtersDebounce.batch === DEFAULT_BATCH_OPTION[0].value ? "" : filtersDebounce.batch,
    });
    getWalletList(params as TListQuery);
  }, [filtersDebounce, getWalletList, gotoPage, updater]);

  useEffect(() => {
    return () => {
      dispatch(transferTokenActions.clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    getBatchList(undefined);
  }, [getBatchList, updater]);

  const renderTableBody = useCallback(() => {
    return (
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <Table.Row
              color={"inherit"}
              {...row.getRowProps()}
              key={index}
              className={cn(index % 2 === 0 ? "bg-white" : "bg-[#FDFDFD]")}
            >
              {row.cells.map((cell, index) => (
                <React.Fragment key={index}>
                  {cell.render("Cell", { index, dataCell: cell.row.original })}
                </React.Fragment>
              ))}
            </Table.Row>
          );
        })}
      </Table.Body>
    );
  }, [getTableBodyProps, rows, prepareRow]);

  return (
    <DefaultLayout title="Transfer Token">
      <TransferTokenController
        className="mt-6"
        randomChecked={randomChecked}
        handleSetRandom={handleSetRandom}
      />
      <div className="min-h-[300px] mt-4">
        <Table
          {...getTableProps()}
          isLoading={getWalletListResponse?.isLoading || getWalletListResponse?.isFetching}
          noRecord={!walletAddressList.length}
        >
          <Table.Head>
            {headerGroups?.map((headerGroup) => (
              <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((col) => {
                  return (
                    <Table.HeadCell
                      {...col.getHeaderProps()}
                      style={{ width: col.width }}
                      className={cn("pl-8", {
                        "text-center": col.id === "symbol" || col.id === "batch",
                      })}
                    >
                      {col.render("Header")}
                    </Table.HeadCell>
                  );
                })}
              </Table.HeadRow>
            ))}
          </Table.Head>
          {renderTableBody()}
        </Table>
        <TablePagination
          currentPage={Number(transferFilters.page)}
          pageCount={pageCount}
          nextPage={handleNext}
          prevPage={handlePrev}
          hasNext={canNextPage}
          hasPrev={canPreviousPage}
          className="mt-5"
          gotoPage={handleGotoPage}
        />
      </div>
    </DefaultLayout>
  );
};

export default TransferToken;
