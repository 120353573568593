import { BigNumber } from "ethers";
import { getAddress } from "@ethersproject/address";

/**
 * Returns true if the string value is zero in hex
 * @param hexNumberString
 */
export function isZero(hexNumberString: string): boolean {
    return /^0x0*$/.test(hexNumberString);
}

export const isEmptyValue = (text: string) =>
    BigNumber.isBigNumber(text)
        ? BigNumber.from(text).isZero()
        : text === "" || text.replace(/0/g, "").replace(/\./, "") === "";

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: string): string | false {
    try {
        return getAddress(value);
    } catch {
        return false;
    }
}

export const withValueLimitInputAmount = (value: string | number, allowIntegers = 10) => {
    value = String(value);
    return (
        value === "" ||
        value === null ||
        value === undefined ||
        value.split(".")[0].length <= allowIntegers
    );
};
