import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from "@ethersproject/providers";
import { createWeb3ReactRoot } from "@web3-react/core";
import { ReactNode } from "react";

export const NetworkContextName = "NETWORK";

const Web3ReactProviderDefault = createWeb3ReactRoot(NetworkContextName);

type Props = {
  children: ReactNode;
  getLibrary: (provider: ExternalProvider | JsonRpcFetchFunc) => Web3Provider;
};

const Web3ProviderNetwork = ({ children, getLibrary }: Props) => {
  return <Web3ReactProviderDefault getLibrary={getLibrary}>{children}</Web3ReactProviderDefault>;
};

export default Web3ProviderNetwork;
