import React from "react";
import { IconProps } from "../../../classes/Icon";

const EthereumIcon: React.FC<IconProps> = ({ ...attributes }) => {
  return (
    <svg
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M125.396 32L124.142 36.2615V159.91L125.396 161.161L182.791 127.235L125.396 32Z"
        fill="#343434"
      />
      <path d="M125.396 32L68 127.235L125.396 161.161L125.396 101.146L125.396 32Z" fill="#8C8C8C" />
      <path
        d="M125.396 172.028L124.689 172.89V216.936L125.396 219L182.826 138.119L125.396 172.028Z"
        fill="#3C3C3B"
      />
      <path d="M125.396 219V172.028L68 138.119L125.396 219Z" fill="#8C8C8C" />
      <path d="M125.396 161.161L182.791 127.235L125.396 101.146L125.396 161.161Z" fill="#141414" />
      <path d="M68 127.235L125.396 161.161L125.396 101.146L68 127.235Z" fill="#393939" />
    </svg>
  );
};

export default EthereumIcon;
