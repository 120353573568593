import React from "react";
import { IconProps } from "../../classes/Icon";

const CheckedIcon: React.FC<IconProps> = ({ size = "20", color = "white", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M16.7319 5.2958C16.639 5.20207 16.5284 5.12768 16.4065 5.07691C16.2846 5.02614 16.1539 5 16.0219 5C15.8899 5 15.7592 5.02614 15.6373 5.07691C15.5155 5.12768 15.4049 5.20207 15.3119 5.2958L7.86192 12.7558L4.73192 9.6158C4.6354 9.52256 4.52146 9.44925 4.3966 9.40004C4.27175 9.35084 4.13843 9.32671 4.00424 9.32903C3.87006 9.33135 3.73765 9.36008 3.61458 9.41357C3.4915 9.46706 3.38016 9.54428 3.28692 9.6408C3.19368 9.73732 3.12037 9.85126 3.07117 9.97612C3.02196 10.101 2.99783 10.2343 3.00015 10.3685C3.00247 10.5027 3.0312 10.6351 3.0847 10.7581C3.13819 10.8812 3.2154 10.9926 3.31192 11.0858L7.15192 14.9258C7.24489 15.0195 7.35549 15.0939 7.47735 15.1447C7.59921 15.1955 7.72991 15.2216 7.86192 15.2216C7.99393 15.2216 8.12464 15.1955 8.2465 15.1447C8.36836 15.0939 8.47896 15.0195 8.57192 14.9258L16.7319 6.7658C16.8334 6.67216 16.9144 6.5585 16.9698 6.432C17.0252 6.30551 17.0539 6.1689 17.0539 6.0308C17.0539 5.8927 17.0252 5.75609 16.9698 5.62959C16.9144 5.50309 16.8334 5.38944 16.7319 5.2958Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckedIcon;
