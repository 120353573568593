import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import addressApi from "../../apis/endpoints/address";
import { TListQuery, TWalletCreated } from "../../classes/Api";
import { TOption } from "../../components/bases/SelectBox";
import { DEFAULT_BATCH_OPTION, DEFAULT_PAGE_LIMIT } from "../../constants/constant";

type TGenerateWalletState = {
  queryFilters: TListQuery;

  walletAddressList: TWalletCreated[];
  totalItems: number;

  updater: number;

  batchList: TOption[];
};

const initialState: TGenerateWalletState = {
  queryFilters: {
    take: DEFAULT_PAGE_LIMIT,
    page: 1,
    orderBy: "batch",
    orderDir: "DESC",
    batch: DEFAULT_BATCH_OPTION[0].value,
    fromDate: moment(new Date().setFullYear(new Date().getFullYear() - 1)).format("MM/DD/YYYY"),
    toDate: moment(new Date()).format("MM/DD/YYYY"),
  },

  walletAddressList: [],
  totalItems: 0,

  updater: Date.now(),

  batchList: DEFAULT_BATCH_OPTION,
};

const _handleChangeFilters: CaseReducer<
  TGenerateWalletState,
  PayloadAction<Partial<TListQuery>>
> = (state, action) => {
  state.queryFilters = {
    ...state.queryFilters,
    ...action.payload,
  };
};

const _clearState: CaseReducer<TGenerateWalletState> = (state) => {
  state.walletAddressList = [];
  state.queryFilters = { ...initialState.queryFilters };
};

const generateWalletSlice = createSlice({
  name: "generateWalletSlice",
  initialState,
  reducers: {
    handleChangeFilters: _handleChangeFilters,
    clearState: _clearState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(addressApi.endpoints.list.matchFulfilled, (state, action) => {
      state.walletAddressList = action.payload.result.data;
      state.totalItems = action.payload.result.meta.itemCount;
    });

    builder.addMatcher(addressApi.endpoints.generate.matchFulfilled, (state) => {
      state.updater = Date.now();
    });

    builder.addMatcher(addressApi.endpoints.getBatch.matchFulfilled, (state, action) => {
      const batchList = [...action.payload.result].map((batch) => ({
        label: String(batch),
        value: batch,
      }));

      state.batchList = DEFAULT_BATCH_OPTION.concat(batchList);
    });
  },
});

export const generateWalletActions = generateWalletSlice.actions;
export default generateWalletSlice.reducer;
