import cn from "classnames";
import React, { BaseHTMLAttributes, useCallback, useEffect, useState } from "react";
import addressApi from "../../apis/endpoints/address";
import { useAppDispatch } from "../../hooks/common";
import { modalSliceActions } from "../../stores/modalSlice";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { Button } from "../bases/Button";
import { Input } from "../bases/Input";
import Modal from "../bases/Modal";

export interface IGenerateWalletModalProps extends BaseHTMLAttributes<HTMLDivElement> {
  container: HTMLElement;
  onCloseHandler?: () => void;
}

const ERROR_FIELD = {
  required: "Please enter amount address",
  greater: "Generate at least one address",
  maximum: "Maximum is 10000 addresses",
};

export default function GenerateWalletModal({
  container,
  onCloseHandler,
}: IGenerateWalletModalProps): React.ReactElement {
  const [numberOfWallets, setNumberOfWallets] = useState<string>("");
  const dispatch = useAppDispatch();
  const [handleGenerateWallet, generateResponse] = addressApi.useGenerateMutation();
  const [error, setError] = useState<string>("");

  const handleClickGenerateWalletBtn = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!numberOfWallets) return setError(ERROR_FIELD.required);
      if (error) return;
      handleGenerateWallet({ numberOfWallets: Number(numberOfWallets) });
    },
    [error, handleGenerateWallet, numberOfWallets]
  );

  const handleOnValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^[0-9]+$/;
    if (value && !value.match(regex)) return;
    setNumberOfWallets(value);
    if (!value) return setError(ERROR_FIELD.required);
    if (!Number(value)) return setError(ERROR_FIELD.greater);
    if (Number(value) > 10000) return setError(ERROR_FIELD.maximum);
    return setError("");
  }, []);

  useEffect(() => {
    if (generateResponse.isSuccess) {
      setNumberOfWallets("");
      dispatch(
        toastMessageActions.addToastMessage({
          type: "success",
          title: "Successfully",
          description: `Successfully created ${numberOfWallets} accounts`,
        })
      );
      dispatch(modalSliceActions.shiftFromQueue());
    }
  }, [dispatch, generateResponse.isSuccess, numberOfWallets]);

  return (
    <Modal onCloseHandler={onCloseHandler} container={container} className="w-[549px]">
      <div className="text-3xl font-bold text-center w-full">Amount address</div>
      <form className="mt-8 w-full" onSubmit={handleClickGenerateWalletBtn}>
        <Input
          className={cn("w-full", { "!border-danger": error })}
          placeholder="Number of wallets"
          value={numberOfWallets}
          onChange={handleOnValueChange}
        />
        <div className="text-xs text-danger mt-1 h-4">{error && error}</div>
        <Button className="btn-primary mt-4 w-full" type="submit">
          Generate
        </Button>
      </form>
    </Modal>
  );
}
