import React from "react";
import { IconProps } from "../../classes/Icon";

const ClockIcon: React.FC<IconProps> = ({ size = "24", color = "#A7A8A7", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M4 12a8 8 0 1116 0 8 8 0 01-16 0zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm1 4a1 1 0 10-2 0v6a1 1 0 00.293.707l3 3a1 1 0 001.414-1.414L13 11.586V6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ClockIcon;
