import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import dataTransferApi from "../../apis/endpoints/dataTransfer";
import { TListQuery, TWalletImported } from "../../classes/Api";
import { TOption } from "../../components/bases/SelectBox";
import { DEFAULT_BATCH_OPTION, DEFAULT_PAGE_LIMIT } from "../../constants/constant";

type TImportCsvState = {
  queryFilters: TListQuery;

  walletAddressList: TWalletImported[];
  totalItems: number;
  updater: number;

  batchList: TOption[];
};

const initialState: TImportCsvState = {
  queryFilters: {
    take: DEFAULT_PAGE_LIMIT,
    page: 1,
    orderBy: "batch",
    orderDir: "DESC",
    batch: DEFAULT_BATCH_OPTION[0].value,
    fromDate: moment(new Date().setFullYear(new Date().getFullYear() - 1)).format("MM/DD/YYYY"),
    toDate: moment(new Date()).format("MM/DD/YYYY"),
  },

  walletAddressList: [],
  totalItems: 0,
  updater: Date.now(),

  batchList: DEFAULT_BATCH_OPTION,
};

const _handleChangeFilters: CaseReducer<TImportCsvState, PayloadAction<Partial<TListQuery>>> = (
  state,
  action
) => {
  if (action.payload?.symbol) {
    action.payload.symbol = action.payload.symbol.trim().toLowerCase();
  }
  state.queryFilters = {
    ...state.queryFilters,
    ...action.payload,
  };
};

const _clearState: CaseReducer<TImportCsvState> = (state) => {
  state.walletAddressList = [];
  state.queryFilters = { ...initialState.queryFilters };
};

const _clearAddressList: CaseReducer<TImportCsvState> = (state) => {
  state.walletAddressList = [];
  state.totalItems = 0;
};

const importCsvSlice = createSlice({
  name: "importCsvSlice",
  initialState,
  reducers: {
    handleChangeFilters: _handleChangeFilters,
    clearState: _clearState,
    clearAddressList: _clearAddressList,
  },
  extraReducers: (builder) => {
    builder.addMatcher(dataTransferApi.endpoints.getList.matchFulfilled, (state, action) => {
      state.walletAddressList = action.payload.result.data;
      state.totalItems = action.payload.result.meta.itemCount;
    });
    builder.addMatcher(dataTransferApi.endpoints.upload.matchFulfilled, (state) => {
      state.updater = Date.now();
    });

    builder.addMatcher(dataTransferApi.endpoints.getListBatch.matchFulfilled, (state, action) => {
      const batchList = [...action.payload.result].map((batch) => ({
        label: String(batch),
        value: batch,
      }));

      state.batchList = DEFAULT_BATCH_OPTION.concat(batchList);
    });
  },
});

export const importCsvActions = importCsvSlice.actions;
export default importCsvSlice.reducer;
