import cn from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import useNativeTokenBalance from "../../hooks/useNativeTokenBalance";
import { routeActions, ROUTES, TRoute, TRouteSideBar } from "../../stores/routeSlice";
import { walletActions } from "../../stores/walletSlice";
import StorageUtils from "../../utils/storage";
import CosplayIcon from "../icons/CosplayIcon";
import DownloadIcon from "../icons/DownloadIcon";
import DownOneIcon from "../icons/DownOneIcon";
import TransferIcon from "../icons/TransferIcon";
import WalletIcon from "../icons/WalletIcon";
import KeyIcon from "../icons/KeyIcon";
import VestingIcon from "../icons/VestingIcon";

const DashBoard = ({ children }: { children: JSX.Element }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  useAppSelector((state) => state.route);
  useNativeTokenBalance();

  const sidebarItems: TRouteSideBar[] = useMemo(() => {
    return [
      {
        title: "Generate Wallet",
        route: "/",
        icon: (
          <WalletIcon
            color={location.pathname === ROUTES.GENERATE_WALLET ? "#C92B5B" : undefined}
          />
        ),
      },
      {
        title: "Import CSV",
        route: "/import-csv",
        icon: (
          <DownloadIcon color={location.pathname === ROUTES.IMPORT_CSV ? "#C92B5B" : undefined} />
        ),
      },
      {
        title: "Transfer",
        route: "/transfer-token",
        icon: (
          <TransferIcon
            color={location.pathname === ROUTES.TRANSFER_TOKEN ? "#C92B5B" : undefined}
          />
        ),
        child: [
          {
            title: "Transfer Token",
            route: "/transfer-token/transfer",
            icon: (
              <TransferIcon
                color={location.pathname === ROUTES.SUB_TRANSFER ? "#C92B5B" : undefined}
              />
            ),
          },
          {
            title: "Transfer History",
            route: "/transfer-token/history",
            icon: (
              <TransferIcon
                color={location.pathname === ROUTES.SUB_HISTORY ? "#C92B5B" : undefined}
              />
            ),
          },
        ],
      },
      {
        title: "Grant Role",
        route: "/grant-role",
        icon: <KeyIcon color={location.pathname === ROUTES.GRANT_ROLE ? "#C92B5B" : undefined} />,
      },
      {
        title: "Vesting",
        route: "/vesting",
        icon: (
          <VestingIcon
            color={location.pathname === ROUTES.TRANSFER_TOKEN ? "#C92B5B" : undefined}
          />
        ),
        child: [
          {
            title: "Add Vesting Wallet",
            route: "/vesting/add-vesting-wallet",
            icon: (
              <VestingIcon
                color={location.pathname === ROUTES.ADD_VESTING_WALLET ? "#C92B5B" : undefined}
              />
            ),
          },
          {
            title: "Remove Vesting",
            route: "/vesting/remove-vesting",
            icon: (
              <VestingIcon
                color={location.pathname === ROUTES.REMOVE_VESTING ? "#C92B5B" : undefined}
              />
            ),
          },
          // {
          //   title: "Vesting List",
          //   route: "/vesting/vesting-list",
          //   icon: (
          //     <VestingIcon
          //       color={location.pathname === ROUTES.VESTING_LIST ? "#C92B5B" : undefined}
          //     />
          //   ),
          // },
        ],
      },
    ];
  }, [location.pathname]);

  const handleOnTabClicked = useCallback(
    (e: React.MouseEvent<HTMLElement>, route: TRoute, firstChild?: TRoute) => {
      if (firstChild) {
        dispatch(
          routeActions.handleSetExpand({
            isExpand: true,
            route: route,
          })
        );
        dispatch(routeActions.setRoute(firstChild));
        history.push(firstChild);
      } else {
        dispatch(routeActions.handleSetExpand({ isExpand: false, route: "" }));
        dispatch(routeActions.setRoute(route));
        history.push(route);
      }
    },
    [dispatch, history]
  );

  const handleOnSubTabClicked = useCallback(
    (e: React.MouseEvent<HTMLElement>, route: TRoute, parent: TRoute) => {
      dispatch(routeActions.setRoute(route));
      dispatch(
        routeActions.handleSetExpand({
          isExpand: true,
          route: parent,
        })
      );
      history.push(route);
    },
    [dispatch, history]
  );

  useEffect(() => {
    const account = StorageUtils.getAccount();
    dispatch(walletActions.setAccount(account || ""));
  }, [dispatch]);

  useEffect(() => {
    const split = location.pathname.split("/");
    if (split.length > 2) {
      dispatch(
        routeActions.handleSetExpand({
          isExpand: true,
          route: `/${split[1]}`,
        })
      );
    }
  }, [dispatch, location.pathname]);

  return (
    <div className="min-h-screen">
      <div
        className={cn(
          "relative w-full font-primary",
          "flex justify-start items-stretch min-h-screen"
        )}
      >
        <div className="w-sidebar min-w-[292px] sticky h-screen py-10 top-0 left-0 select-none">
          <div className="flex justify-center mb-10">
            <CosplayIcon className="min-h-[48px] h-12" size={48} />
          </div>
          {sidebarItems.map((item) => (
            <React.Fragment key={item.route}>
              <div
                className={cn(
                  "py-4 pl-10 pr-5 text-grey-80 cursor-pointer font-bold",
                  "transition-colors duration-250 ease-linear",
                  "flex flex-row gap-3 items-center justify-between border-r-4",
                  item.route === location.pathname ? "border-primary-80" : "border-white",
                  {
                    "text-primary-80 bg-pink-5": item.route === location.pathname,
                  }
                )}
                onClick={(e) => handleOnTabClicked(e, item.route, item.child?.[0].route)}
              >
                <div className="flex flex-row gap-3 items-center">
                  {item.icon}
                  {item.title}
                </div>
                {item.child && <DownOneIcon size={18} />}
              </div>
              {item.child
                ? item.child.map((sub, index) => (
                    <div
                      className={cn(
                        "py-4 pl-10 text-grey-80 cursor-pointer font-bold",
                        "transition-colors duration-250 ease-linear",
                        "flex items-center gap-3",
                        {
                          "text-primary-80 bg-pink-5 border-r-4 border-primary-80":
                            sub.route === location.pathname,
                        }
                      )}
                      onClick={(e) => handleOnSubTabClicked(e, sub.route, item.route)}
                      key={index}
                    >
                      <div className="ml-9">{sub.title}</div>
                    </div>
                  ))
                : null}
            </React.Fragment>
          ))}
        </div>
        <main className="relative w-full bg-[#FBFAFA]">{children}</main>
      </div>
    </div>
  );
};

export default DashBoard;
