import { ContractInterface } from "ethers";

const TOKEN_TRANSFER_ABI: ContractInterface = [
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "uint8", name: "version", type: "uint8" }],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "token", type: "address" },
      { indexed: false, internalType: "uint256", name: "totalAmount", type: "uint256" },
      { indexed: false, internalType: "address[]", name: "sourceWallets", type: "address[]" },
      { indexed: false, internalType: "address[]", name: "receivers", type: "address[]" },
      { indexed: false, internalType: "uint256[]", name: "amount", type: "uint256[]" },
      { indexed: false, internalType: "string", name: "systemType", type: "string" },
    ],
    name: "MultiSourceTransferSucceeded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      { indexed: true, internalType: "bytes32", name: "previousAdminRole", type: "bytes32" },
      { indexed: true, internalType: "bytes32", name: "newAdminRole", type: "bytes32" },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      { indexed: true, internalType: "address", name: "account", type: "address" },
      { indexed: true, internalType: "address", name: "sender", type: "address" },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      { indexed: true, internalType: "address", name: "account", type: "address" },
      { indexed: true, internalType: "address", name: "sender", type: "address" },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "token", type: "address" },
      { indexed: false, internalType: "address", name: "sourceWallet", type: "address" },
      { indexed: false, internalType: "address", name: "receiver", type: "address" },
      { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      { indexed: false, internalType: "string", name: "systemType", type: "string" },
    ],
    name: "TransferSingleSucceeded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "token", type: "address" },
      { indexed: false, internalType: "uint256", name: "totalAmount", type: "uint256" },
      { indexed: false, internalType: "address[]", name: "receivers", type: "address[]" },
      { indexed: false, internalType: "uint256[]", name: "amount", type: "uint256[]" },
      { indexed: false, internalType: "string", name: "systemType", type: "string" },
    ],
    name: "TransferSucceeded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "token", type: "address" },
      { indexed: false, internalType: "uint256", name: "vestingId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "amountClaimed", type: "uint256" },
    ],
    name: "UserClaimedVesting",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "token", type: "address" },
      { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      { indexed: false, internalType: "address", name: "vestingWallet", type: "address" },
      { indexed: false, internalType: "uint256", name: "claimDate", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "vestingId", type: "uint256" },
    ],
    name: "VestingWalletAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "token", type: "address" },
      { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      { indexed: false, internalType: "address", name: "vestingWallet", type: "address" },
      { indexed: false, internalType: "uint64", name: "startDate", type: "uint64" },
      { indexed: false, internalType: "uint64", name: "endDate", type: "uint64" },
      { indexed: false, internalType: "uint256", name: "vestingId", type: "uint256" },
    ],
    name: "VestingWalletAddedV2",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: false, internalType: "uint256", name: "vestingId", type: "uint256" },
    ],
    name: "VestingWalletRemoved",
    type: "event",
  },
  { stateMutability: "payable", type: "fallback" },
  {
    inputs: [],
    name: "ADMIN_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MAX_RECEIVERS",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_vestingAddress", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint64", name: "_startDate", type: "uint64" },
      { internalType: "uint64", name: "_endDate", type: "uint64" },
      { internalType: "address", name: "_tokenAddress", type: "address" },
    ],
    name: "addVestingWallet",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
    name: "claimVesting",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_vestingWallet", type: "address" }],
    name: "getVestingIds",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  { inputs: [], name: "initialize", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "address[]", name: "receiverAddresses", type: "address[]" },
      { internalType: "uint256[]", name: "value", type: "uint256[]" },
      { internalType: "string", name: "systemType", type: "string" },
    ],
    name: "multiSend",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "address[]", name: "sourceAddresses", type: "address[]" },
      { internalType: "address[]", name: "receiverAddresses", type: "address[]" },
      { internalType: "uint256[]", name: "value", type: "uint256[]" },
      { internalType: "string", name: "systemType", type: "string" },
    ],
    name: "multiSendFromMultiSource",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
    name: "releasable",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_vestingId", type: "uint256" }],
    name: "removeVesting",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "address", name: "receiver", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "string", name: "systemType", type: "string" },
    ],
    name: "transferToken",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "vestingWallet",
    outputs: [
      { internalType: "address", name: "vestingCreator", type: "address" },
      { internalType: "address", name: "vestingAddress", type: "address" },
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "uint256", name: "vestingAmount", type: "uint256" },
      { internalType: "uint256", name: "claimDate", type: "uint256" },
      { internalType: "uint256", name: "amountClaimed", type: "uint256" },
      { internalType: "bool", name: "isVestingValid", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "vestingWalletV2",
    outputs: [
      { internalType: "address", name: "vestingCreator", type: "address" },
      { internalType: "address", name: "vestingAddress", type: "address" },
      { internalType: "address", name: "tokenAddress", type: "address" },
      { internalType: "uint256", name: "vestingAmount", type: "uint256" },
      { internalType: "uint64", name: "startDate", type: "uint64" },
      { internalType: "uint64", name: "endDate", type: "uint64" },
      { internalType: "uint256", name: "amountClaimed", type: "uint256" },
      { internalType: "bool", name: "isVestingValid", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

export default TOKEN_TRANSFER_ABI;
