import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import vestingApi from "../../apis/endpoints/vesting";
// import transactionApi from "../../apis/endpoints/transaction";
import { TVestingListQuery, TVestingData } from "../../classes/Api";

type TVestingListState = {
  vestingList: TVestingData[];
  queryFilters: TVestingListQuery;
  totalItems: number;
  updater: number;
};

const initialState: TVestingListState = {
  vestingList: [],
  queryFilters: {
    orderBy: "vestingId",
    orderDir: "DESC",
    page: 1,
    searchEq: "",
    vestingAddress: "",
    vestingId: undefined,
    tokenAddress: "",
    take: 10,
    fromDate: moment(new Date().setFullYear(new Date().getFullYear() - 1)).format("MM/DD/YYYY"),
    toDate: moment(new Date()).format("MM/DD/YYYY"),
  },
  totalItems: 0,
  updater: Date.now(),
};

const _handleChangeFilters: CaseReducer<
  TVestingListState,
  PayloadAction<Partial<TVestingListQuery>>
> = (state, action) => {
  state.queryFilters = {
    ...state.queryFilters,
    ...action.payload,
  };
};

const _clearState: CaseReducer<TVestingListState> = (state) => {
  state.vestingList = [];
  state.queryFilters = { ...initialState.queryFilters };
};

const vestingListSlice = createSlice({
  name: "vestingListSlice",
  initialState,
  reducers: {
    handleChangeFilters: _handleChangeFilters,
    clearState: _clearState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(vestingApi.endpoints.getVestingList.matchFulfilled, (state, action) => {
      const result = action.payload.result.data;
      const formartResult = result.map((item) => {
        return {
          ...item,
          vestingDuration:
            item.endDate ? item.startDate + "|" + item.endDate : undefined,
        };
      });
      state.vestingList = formartResult;
      state.totalItems = action.payload.result.meta.itemCount;
    });
  },
});

export const vestingListActions = vestingListSlice.actions;
export default vestingListSlice.reducer;
