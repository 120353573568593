import cn from "classnames";
import { isAddress } from "ethers/lib/utils";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Value } from "react-time-picker/dist/cjs/shared/types";
import tokenApi from "../../apis/endpoints/token";
import { Button } from "../../components/bases/Button";
import DatePicker from "../../components/bases/DatePicker";
import { Input } from "../../components/bases/Input";
import SelectBox, { TOption } from "../../components/bases/SelectBox";
import TimePicker from "../../components/bases/TimePicker";
import DefaultLayout from "../../components/layout/DefaultLayout";
import {
  BLOCK_GAS_LIMIT,
  ERROR_TRANSACTION,
  NATIVE_TOKEN_SYMBOL,
  ZERO_ADDRESS,
} from "../../constants/constant";
import { useBlockNumber } from "../../hooks/useBlockNumber";
import { useAppDispatch } from "../../hooks/common";
import { useWeb3Activity } from "../../hooks/useWeb3Activity";
import { formatBalance, formatIntBalance, parseBalance } from "../../utils/format";
import { useTokenERC20PermitContract, useTokenTransferContract } from "../../hooks/useContract";
import { ApprovalState, useApproveCallback } from "../../hooks/useApproveToken";
import { BigNumber } from "ethers";
import Spinner from "../../components/commons/Spinner";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { addTransaction } from "../../stores/walletSlice";
import RadioButton from "../../components/bases/RadioButton";

type TVestingInfo = {
  walletAddress: string;
  vestingAmount: string;
  startDate: string | undefined;
  startTime: Value;
  endDate: string | undefined;
  endTime: Value;
  token: {
    address: string;
    symbol: string | undefined;
  };
};

type TFormError = {
  addressError: string;
  amountError: string;
  timeError: string;
};

const ERROR_FIELD = {
  requiredAddress: "Please enter wallet address",
  zeroAddress: "Zero address is not allowed",
  validAddress: "Wallet address is not valid",
  requiredAmount: "Please enter vesting amount",
  minimumAmount: "Fractional component exceeds decimals",
  exceedBalance: "Vesting amount exceed available balance",
  greater: "Vesting amount must be greater than 0",
  validTime: "Start time must be smaller than end time",
};

const AddVestingWallet = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours().toString().padStart(2, "0");
  const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  const [nativeBalance, setNativeBalance] = useState<string>("-");
  const [balanceOfERC20, setBalanceOfERC20] = useState<string>("-");
  const [vestingInfo, setVestingInfo] = useState<TVestingInfo>({
    walletAddress: "",
    vestingAmount: "",
    startDate: moment(new Date()).format("MM/DD/YYYY"),
    startTime: `${hours}:${minutes}`,
    endDate: moment(new Date()).format("MM/DD/YYYY"),
    endTime: `${hours}:${minutes}`,
    token: {
      address: "",
      symbol: "",
    },
  });
  const [formErrors, setFormErrors] = useState<TFormError>({
    addressError: "",
    amountError: "",
    timeError: "",
  });
  const [tokenList, setTokenList] = useState<TOption[]>([]);
  const [resetSelectBox, setResetSelectBox] = useState<boolean>(false);
  const [approvalState, setApprovalState] = useState<ApprovalState>(ApprovalState.UNKNOWN);
  const [cliffCheck, setCliffCheck] = useState<boolean>(false);
  const [isTransactionPending, setIsTransctionPending] = useState<boolean>(false);
  const [getTokenList] = tokenApi.useLazyGetListTokenQuery();
  const dispatch = useAppDispatch();

  const context = useWeb3Activity();
  const latestBlock = useBlockNumber(context.chainId);
  const tokenContract = useTokenERC20PermitContract(context, vestingInfo.token.address);
  const tokenTransferContract = useTokenTransferContract(context);

  const [, , handleApprove, isApproving] = useApproveCallback(
    context,
    vestingInfo.token.address,
    parseBalance(vestingInfo.vestingAmount),
    tokenTransferContract?.address,
    latestBlock
  );

  const handleOnAddressChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setVestingInfo((prev) => ({
      ...prev,
      walletAddress: value,
    }));
  }, []);

  const handleOnAmountChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const floatRegex = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/;
      if (value && !value.match(floatRegex)) return;
      const decimalIndex = value.indexOf(".");
      const fractionalPart = value.substring(decimalIndex + 1);
      if (tokenContract) {
        if (decimalIndex !== -1) {
          const decimals = await tokenContract.decimals();
          if (fractionalPart.length > decimals) return;
        }
      } else {
        if (decimalIndex !== -1) {
          if (fractionalPart.length > 18) return;
        }
      }
      setVestingInfo((prev) => ({
        ...prev,
        vestingAmount: value,
      }));
    },
    [tokenContract]
  );

  const handleSelectStartDate = useCallback((date: Date) => {
    setVestingInfo((prev) => ({
      ...prev,
      startDate: moment(date).format("MM/DD/YYYY"),
    }));
  }, []);

  const handleSelectStartTime = useCallback((value: Value) => {
    setVestingInfo((prev) => ({
      ...prev,
      startTime: value,
    }));
  }, []);

  const handleSelectEndDate = useCallback((date: Date) => {
    setVestingInfo((prev) => ({
      ...prev,
      endDate: moment(date).format("MM/DD/YYYY"),
    }));
  }, []);

  const handleSelectEndTime = useCallback((value: Value) => {
    setVestingInfo((prev) => ({
      ...prev,
      endTime: value,
    }));
  }, []);

  const handleTokenSelect = useCallback(async (value: string | number, label?: string) => {
    setVestingInfo((prev) => ({
      ...prev,
      token: {
        address: String(value),
        symbol: label,
      },
    }));
  }, []);

  useEffect(
    () => {
      if (!context.isSupportedNetwork) return;
      if (vestingInfo.token.address === ZERO_ADDRESS) {
        return setApprovalState(ApprovalState.APPROVED);
      }
      if (!vestingInfo.vestingAmount) return setApprovalState(ApprovalState.UNKNOWN);
      if (!tokenContract) return setApprovalState(ApprovalState.UNKNOWN);

      tokenContract
        .allowance(context.account, tokenTransferContract?.address)
        .then((currentAllowance: BigNumber) => {
          if (!currentAllowance) return setApprovalState(ApprovalState.UNKNOWN);
          if (isApproving) return setApprovalState(ApprovalState.PENDING);
          tokenContract.decimals().then((tokenDecimals: BigNumber) => {
            const amountToApprove = parseBalance(vestingInfo.vestingAmount, Number(tokenDecimals));
            return BigNumber.from(currentAllowance).lt(amountToApprove)
              ? setApprovalState(ApprovalState.NOT_APPROVED)
              : setApprovalState(ApprovalState.APPROVED);
          });
        });
    },
    // eslint-disable-next-line
    [
      context.chainId,
      context.account,
      vestingInfo.token.address,
      vestingInfo.vestingAmount,
      isApproving,
    ]
  );

  useEffect(
    () => {
      getTokenList({ chainId: context.chainId }).then((response) => {
        const list = response.data?.result.map(({ address, symbol }) => ({
          label: symbol.toUpperCase(),
          value: address,
        }));
        if (list) setTokenList(list);
        setCliffCheck(false);
        //Clear state
        const currentTime = new Date();
        const hours = currentTime.getHours().toString().padStart(2, "0");
        const minutes = currentTime.getMinutes().toString().padStart(2, "0");
        const nativeToken = list?.find((item) => item.value === ZERO_ADDRESS);
        setVestingInfo({
          walletAddress: "",
          vestingAmount: "",
          startDate: moment(currentTime).format("MM/DD/YYYY"),
          startTime: `${hours}:${minutes}`,
          endDate: moment(currentTime).format("MM/DD/YYYY"),
          endTime: `${hours}:${minutes}`,
          token: {
            address: String(nativeToken?.value),
            symbol: nativeToken?.label,
          },
        });
      });
    },
    // eslint-disable-next-line
    [getTokenList, context.chainId]
  );

  useEffect(
    () => {
      (async () => {
        if (!context.account) return;

        const balance = await context.library.getBalance(context.account);
        setNativeBalance(formatIntBalance(formatBalance(balance)));
      })();
    },
    // eslint-disable-next-line
    [context.account, latestBlock]
  );

  useEffect(
    () => {
      (async () => {
        if (!context.account) return;
        if (!context.isSupportedNetwork) return;
        if (!tokenContract) {
          if (balanceOfERC20 !== "-") setBalanceOfERC20("-");
          return;
        }
        if (vestingInfo.token.address === ZERO_ADDRESS) return;

        const decimals = await tokenContract.decimals();

        const balance = await tokenContract.balanceOf(context.account);
        setBalanceOfERC20(formatIntBalance(formatBalance(balance, decimals)));
      })();
    },
    // eslint-disable-next-line
    [context.account, latestBlock, tokenContract, vestingInfo.token]
  );

  const handleAddVesting = useCallback(
    async (startDateTimeInTimestamp: number, endDateTimeInTimestamp: number) => {
      try {
        if (!tokenTransferContract) return;
        const { walletAddress, vestingAmount, token } = vestingInfo;
        if (!token.address) return;

        if (token.address !== ZERO_ADDRESS && !tokenContract) return;
        setIsTransctionPending(true);
        const decimals = token.address === ZERO_ADDRESS ? 18 : await tokenContract?.decimals();
        const amountConverted = parseBalance(vestingAmount, decimals);
        const args = [
          walletAddress,
          amountConverted,
          startDateTimeInTimestamp,
          endDateTimeInTimestamp,
          token.address,
        ];
        const gasLimit =
          token.address === ZERO_ADDRESS
            ? await tokenTransferContract.estimateGas.addVestingWallet(...args, {
                value: amountConverted,
              })
            : await tokenTransferContract.estimateGas.addVestingWallet(...args);

        const gasPrice = await context.library.getGasPrice();
        if (BigNumber.from(gasLimit).gt(BLOCK_GAS_LIMIT[context.chainId])) {
          dispatch(
            toastMessageActions.addToastMessage({
              title: "Exceeds block gas limit",
              type: "danger",
            })
          );
          return;
        }
        const tx =
          token.address === ZERO_ADDRESS
            ? await tokenTransferContract.addVestingWallet(...args, {
                gasLimit: gasLimit,
                gasPrice: gasPrice,
                value: amountConverted,
              })
            : await tokenTransferContract.addVestingWallet(...args, {
                gasLimit: gasLimit,
                gasPrice: gasPrice,
              });

        dispatch(
          addTransaction({
            action: "add-vesting-wallet",
            chainId: context.chainId,
            hash: tx.hash,
          })
        );
        const receipt = await tx.wait();
        if (receipt?.status === 1) {
          // Clear state
          const currentTime = new Date();
          const hours = currentTime.getHours().toString().padStart(2, "0");
          const minutes = currentTime.getMinutes().toString().padStart(2, "0");
          if (cliffCheck) {
            setVestingInfo((prev) => ({
              ...prev,
              walletAddress: "",
              vestingAmount: "",
              startDate: "0",
              startTime: "0",
              endDate: moment(currentTime).format("MM/DD/YYYY"),
              endTime: `${hours}:${minutes}`,
            }));
          } else {
            setVestingInfo((prev) => ({
              ...prev,
              walletAddress: "",
              vestingAmount: "",
              startDate: moment(currentTime).format("MM/DD/YYYY"),
              startTime: `${hours}:${minutes}`,
              endDate: moment(currentTime).format("MM/DD/YYYY"),
              endTime: `${hours}:${minutes}`,
            }));
          }
          setIsTransctionPending(false);
        } else {
          setIsTransctionPending(false);
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Something went wrong!",
            })
          );
        }
      } catch ({ code, reason, data }) {
        const errMsg = Object(data).message;
        setIsTransctionPending(false);
        if (
          code === ERROR_TRANSACTION.reject.codeNumber ||
          code === ERROR_TRANSACTION.reject.codeString
        ) {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Signature rejected",
            })
          );
          return;
        }

        if (reason === ERROR_TRANSACTION.balanceERC20.codeString) {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Insufficient ERC20 tokens",
            })
          );
        } else if (errMsg && errMsg.includes(ERROR_TRANSACTION.balanceNative.codeString1)) {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Insufficient native tokens",
            })
          );
        } else if (
          reason &&
          (String(reason).includes(ERROR_TRANSACTION.balanceNative.codeString2) ||
            String(reason).includes(ERROR_TRANSACTION.balanceNative.codeString3))
        ) {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Insufficient native tokens",
            })
          );
        } else {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Something went wrong!",
            })
          );
        }
      }
    },
    [
      context.chainId,
      dispatch,
      tokenTransferContract,
      vestingInfo,
      tokenContract,
      context.library,
      cliffCheck,
    ]
  );

  const handleFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      let valid = true;
      // Checking vesting wallet address
      if (!vestingInfo.walletAddress) {
        setFormErrors((prev) => ({ ...prev, addressError: ERROR_FIELD.requiredAddress }));
        valid = false;
      } else if (vestingInfo.walletAddress.toLowerCase() === ZERO_ADDRESS) {
        setFormErrors((prev) => ({ ...prev, addressError: ERROR_FIELD.zeroAddress }));
        valid = false;
      } else if (!isAddress(vestingInfo.walletAddress)) {
        setFormErrors((prev) => ({ ...prev, addressError: ERROR_FIELD.validAddress }));
        valid = false;
      } else setFormErrors((prev) => ({ ...prev, addressError: "" }));

      // Checking vesting amount
      if (!vestingInfo.vestingAmount) {
        setFormErrors((prev) => ({ ...prev, amountError: ERROR_FIELD.requiredAmount }));
        valid = false;
      } else if (!Number(vestingInfo.vestingAmount)) {
        setFormErrors((prev) => ({ ...prev, amountError: ERROR_FIELD.greater }));
        valid = false;
      } else {
        if (vestingInfo?.token.address !== ZERO_ADDRESS) {
          const convertedBalance = parseFloat(balanceOfERC20.replace(/,/g, ""));
          if (parseFloat(vestingInfo.vestingAmount) > convertedBalance) {
            setFormErrors((prev) => ({ ...prev, amountError: ERROR_FIELD.exceedBalance }));
            valid = false;
          } else setFormErrors((prev) => ({ ...prev, amountError: "" }));
        } else {
          const convertedBalance = parseFloat(nativeBalance.replace(/,/g, ""));
          if (parseFloat(vestingInfo.vestingAmount) > convertedBalance) {
            setFormErrors((prev) => ({ ...prev, amountError: ERROR_FIELD.exceedBalance }));
            valid = false;
          } else setFormErrors((prev) => ({ ...prev, amountError: "" }));
        }
      }
      let startDateTimeInTimestamp;
      let endDateTimeInTimestamp;
      //Checking timestamp
      if (vestingInfo.startDate !== "0" && vestingInfo.startTime !== "0") {
        const startDateTime = new Date(`${vestingInfo.startDate} ${vestingInfo.startTime}`);

        const endDateTime = new Date(`${vestingInfo.endDate} ${vestingInfo.endTime}`);
        if (startDateTime >= endDateTime) {
          setFormErrors((prev) => ({ ...prev, timeError: ERROR_FIELD.validTime }));
          valid = false;
        } else setFormErrors((prev) => ({ ...prev, timeError: "" }));

        startDateTimeInTimestamp = Math.floor(startDateTime.getTime() / 1000);

        endDateTimeInTimestamp = Math.floor(endDateTime.getTime() / 1000);
      } else {
        const endDateTime = new Date(`${vestingInfo.endDate} ${vestingInfo.endTime}`);
        startDateTimeInTimestamp = 0;
        endDateTimeInTimestamp = Math.floor(endDateTime.getTime() / 1000);
      }
      if (!valid) return;

      // Calling approve if not approve, else calling to contract
      approvalState === ApprovalState.NOT_APPROVED
        ? handleApprove()
        : await handleAddVesting(startDateTimeInTimestamp, endDateTimeInTimestamp);
    },
    [
      vestingInfo.startDate,
      vestingInfo.startTime,
      vestingInfo.endDate,
      vestingInfo.endTime,
      vestingInfo.vestingAmount,
      vestingInfo.walletAddress,
      vestingInfo.token.address,
      handleAddVesting,
      balanceOfERC20,
      nativeBalance,
      handleApprove,
      approvalState,
    ]
  );

  const handleSelectRadioButton = useCallback(() => {
    setFormErrors({
      addressError: "",
      amountError: "",
      timeError: "",
    });
    //Clear state
    const currentTime = new Date();
    const hours = currentTime.getHours().toString().padStart(2, "0");
    const minutes = currentTime.getMinutes().toString().padStart(2, "0");
    const nativeToken = tokenList?.find((item) => item.value === ZERO_ADDRESS);
    if (!cliffCheck) {
      setVestingInfo({
        walletAddress: "",
        vestingAmount: "",
        startDate: "0",
        startTime: "0",
        endDate: moment(currentTime).format("MM/DD/YYYY"),
        endTime: `${hours}:${minutes}`,
        token: {
          address: String(nativeToken?.value),
          symbol: nativeToken?.label,
        },
      });
    } else {
      setVestingInfo({
        walletAddress: "",
        vestingAmount: "",
        startDate: moment(currentTime).format("MM/DD/YYYY"),
        startTime: `${hours}:${minutes}`,
        endDate: moment(currentTime).format("MM/DD/YYYY"),
        endTime: `${hours}:${minutes}`,
        token: {
          address: String(nativeToken?.value),
          symbol: nativeToken?.label,
        },
      });
    }
    setResetSelectBox(!resetSelectBox);
    setCliffCheck((prev) => !prev);
  }, [tokenList, cliffCheck, resetSelectBox]);

  const renderButton = useCallback(() => {
    if (
      approvalState === ApprovalState.NOT_APPROVED &&
      parseFloat(vestingInfo.vestingAmount) > 0 &&
      !isApproving
    ) {
      return (
        <Button className="btn-primary mt-5" size="sm" type="submit">
          Approve
        </Button>
      );
    }

    if (isApproving) {
      return (
        <Button className="btn-primary mt-5 gap-2" size="sm" disabled={true}>
          <Spinner size="medium" /> Approving
        </Button>
      );
    }

    if (isTransactionPending) {
      return (
        <Button className="btn-primary mt-5 gap-2" size="sm" disabled={true}>
          <Spinner size="medium" /> Adding
        </Button>
      );
    }

    return (
      <Button className="btn-primary mt-5" size="sm" type="submit">
        Add vesting
      </Button>
    );
  }, [isApproving, approvalState, isTransactionPending, vestingInfo.vestingAmount]);

  return (
    <DefaultLayout title="Add Vesting Wallet">
      <div className={cn("bg-white border border-grey-20 rounded-lg shadow-panel py-6 px-8 mt-6")}>
        <div className="grid grid-cols-2 gap-8  mb-5">
          {!vestingInfo.token.address || vestingInfo.token.address === ZERO_ADDRESS ? (
            <div className="flex flex-row gap-3 items-center">
              <div className="text-sm text-grey-80">Available native</div>
              <div className="text-base font-extrabold">
                {!NATIVE_TOKEN_SYMBOL[context.chainId]
                  ? "-"
                  : `${nativeBalance} ${NATIVE_TOKEN_SYMBOL[context.chainId]}`}
              </div>
            </div>
          ) : (
            <div className="flex flex-row gap-3 items-center">
              <div className="text-sm text-grey-80">Available ERC20</div>
              <div className="text-base font-extrabold">{`${balanceOfERC20} ${
                vestingInfo.token.symbol ? vestingInfo.token.symbol : ""
              }`}</div>
            </div>
          )}
          <div className="flex flex-row gap-36">
            <RadioButton
              checked={!cliffCheck}
              onClick={() => {
                if (cliffCheck) {
                  handleSelectRadioButton();
                }
              }}
            >
              Linear unlock
            </RadioButton>
            <RadioButton
              checked={cliffCheck}
              onClick={() => {
                if (!cliffCheck) {
                  handleSelectRadioButton();
                }
              }}
            >
              Cliff unlock
            </RadioButton>
          </div>
        </div>

        <form className="mb-5" onSubmit={handleFormSubmit}>
          <div className="grid grid-cols-2 gap-8">
            <div className="flex flex-col">
              <div className="text-sm font-semibold text-grey-80 mb-1">Address</div>
              <Input
                className={cn({ "!border-danger": formErrors.addressError })}
                placeholder="Wallet address"
                value={vestingInfo.walletAddress}
                onChange={handleOnAddressChange}
              />
              <div className="text-xs text-danger mt-1 h-4">
                {formErrors.addressError && formErrors.addressError}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex flex-col">
                <div className="text-sm font-semibold text-grey-80 mb-1">Amount</div>
                <Input
                  className={cn({ "!border-danger": formErrors.amountError })}
                  placeholder="Vesting amount"
                  value={vestingInfo.vestingAmount}
                  onChange={handleOnAmountChange}
                />
                <div className="text-xs text-danger mt-1 h-4">
                  {formErrors.amountError && formErrors.amountError}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-sm font-semibold text-grey-80 mb-1">Token</div>
                <SelectBox
                  customClassName="w-full"
                  defaultValue={tokenList.find((item) => item.value === ZERO_ADDRESS)?.value}
                  options={tokenList}
                  onChange={handleTokenSelect}
                  key={resetSelectBox.toString()}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8">
            {!cliffCheck && (
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <div className="text-sm font-semibold text-grey-80 mb-1">Select start date</div>
                  <DatePicker
                    date={new Date(vestingInfo.startDate as string)}
                    onChange={handleSelectStartDate}
                    className="w-full"
                    size="md"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-sm font-semibold text-grey-80 mb-1">Select start time</div>
                  <TimePicker
                    value={vestingInfo.startTime}
                    onChange={handleSelectStartTime}
                    className="w-full"
                    size="md"
                  />
                </div>
              </div>
            )}

            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <div className="text-sm font-semibold text-grey-80 mb-1">Select end date</div>
                <DatePicker
                  date={new Date(vestingInfo.endDate as string)}
                  onChange={handleSelectEndDate}
                  className="w-full"
                  size="md"
                />
              </div>
              <div className="flex flex-col">
                <div className="text-sm font-semibold text-grey-80 mb-1">Select end time</div>
                <TimePicker
                  value={vestingInfo.endTime}
                  onChange={handleSelectEndTime}
                  className="w-full"
                  size="md"
                />
              </div>
            </div>
          </div>
          <div className="text-xs text-danger mt-1 h-4">
            {formErrors.timeError && formErrors.timeError}
          </div>
          {renderButton()}
        </form>
      </div>
    </DefaultLayout>
  );
};

export default AddVestingWallet;
