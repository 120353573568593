import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";

const ROUTES_TYPE = [
  "/",
  "/login",
  "/transfer-token",
  "/import-csv",
  "/transfer-token/transfer",
  "/transfer-token/history",
  "/grant-role",
  "/vesting",
  "/vesting/add-vesting-wallet",
  "/vesting/remove-vesting",
  "/vesting/vesting-list",
] as const;

export const ROUTES = {
  GENERATE_WALLET: "/",
  LOGIN: "/login",
  TRANSFER_TOKEN: "/transfer-token",
  IMPORT_CSV: "/import-csv",
  SUB_TRANSFER: "/transfer-token/transfer",
  SUB_HISTORY: "/transfer-token/history",
  GRANT_ROLE: "/grant-role",
  VESTING: "/vesting",
  ADD_VESTING_WALLET: "/vesting/add-vesting-wallet",
  REMOVE_VESTING: "/vesting/remove-vesting",
  VESTING_LIST: "/vesting/vesting-list",
};

export type TRoute = (typeof ROUTES_TYPE)[number];

export type TRouteSideBar = {
  title: string;
  route: TRoute;
  icon: ReactNode;
  child?: TRouteSideBar[];
};

export type TExpandRoute = {
  route: string;
  isExpand: boolean;
};

export function isRoute(type: string): type is TRoute {
  return ROUTES_TYPE.includes(type as TRoute);
}

export type TRouteState = {
  here: TRoute;

  expands?: TExpandRoute;
};

const initialState: TRouteState = {
  here: "/",
};

const _setRoute: CaseReducer<TRouteState, PayloadAction<TRoute>> = (state, action) => {
  state.here = action.payload;
};

const _handleSetExpand: CaseReducer<TRouteState, PayloadAction<TExpandRoute>> = (state, action) => {
  state.expands = {
    ...state.expands,
    ...action.payload,
  };
};

const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setRoute: _setRoute,
    handleSetExpand: _handleSetExpand,
  },
});

export const routeActions = routeSlice.actions;

export default routeSlice.reducer;
