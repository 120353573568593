import React from "react";
import { IconProps } from "../../classes/Icon";

const KeyIcon: React.FC<IconProps> = ({ size = "24", color = "#707070", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M19.948 4.243a6 6 0 00-9.55 7.046l-6.652 6.653a.523.523 0 00-.147.453l.148.917c.045.28.307.474.589.435l3.017-.42a.523.523 0 00.446-.446l.161-1.17h.001l1.17-.162a.523.523 0 00.446-.447l.106-.771a.524.524 0 01.453-.448l1.965-.25a.52.52 0 00.304-.149l1.33-1.33a6 6 0 006.213-9.911zm-14.36 14.36l-.446-.447 5.897-5.897c.132.162.273.318.423.469l-5.874 5.874zM16.98 7.264a1.255 1.255 0 111.775-1.775 1.255 1.255 0 01-1.774 1.775z"
        fill={color}
      />
    </svg>
  );
};

export default KeyIcon;
