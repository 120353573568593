import { createBrowserHistory } from "history";
import ReactDOM from "react-dom/client";
import { Router } from "react-router-dom";
import Root from "./App";
import reportWebVitals from "./reportWebVitals";

const browserHistory = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Router history={browserHistory}>
    <Root />
  </Router>
);

// If you want to start measuring performance in your Root, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
