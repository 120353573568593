import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dataTransferApi from "../../apis/endpoints/dataTransfer";
import { TListQuery, TMetaApiResponse, TWalletImported } from "../../classes/Api";
import { TOption } from "../../components/bases/SelectBox";
import { DEFAULT_BATCH_OPTION, DEFAULT_PAGE_LIMIT } from "../../constants/constant";

type TTransferTokenState = {
  transferFilters: Partial<TListQuery>;
  transferType: string | number;
  walletAddressList: TWalletImported[];
  updater: number;
  batchList: TOption[];
  isFetching: boolean;

  metaRes: Partial<TMetaApiResponse>;

  selectedItems: TWalletImported[];

  saveList: {
    [batch: number | string]: TWalletImported[];
  };
};

const initialState: TTransferTokenState = {
  transferFilters: {
    take: DEFAULT_PAGE_LIMIT,
    page: 1,
    orderBy: "batch",
    orderDir: "DESC",
    batch: "all",
    address: "",
    symbol: "",
  },
  transferType: "multi",
  isFetching: false,

  metaRes: {
    itemCount: 0,
    take: DEFAULT_PAGE_LIMIT,
  },

  walletAddressList: [],
  updater: Date.now(),
  batchList: DEFAULT_BATCH_OPTION,

  selectedItems: [],
  saveList: {},
};

const _handleChangeFilters: CaseReducer<TTransferTokenState, PayloadAction<Partial<TListQuery>>> = (
  state,
  action
) => {
  state.transferFilters = {
    ...state.transferFilters,
    ...action.payload,
  };
};

const _handleChangeTransferType: CaseReducer<
  TTransferTokenState,
  PayloadAction<string | number>
> = (state, action) => {
  state.transferType = action.payload;
};

const _clearState: CaseReducer<TTransferTokenState> = (state) => {
  state.walletAddressList = [];
  state.selectedItems = [];
  state.saveList = {};
  state.transferFilters = { ...initialState.transferFilters };
  state.transferType = "multi";
};

const _handleSetSelectedItems: CaseReducer<
  TTransferTokenState,
  PayloadAction<TWalletImported[]>
> = (state, action) => {
  state.selectedItems = action.payload;
};

const _handleSelectItem: CaseReducer<TTransferTokenState, PayloadAction<TWalletImported>> = (
  state,
  action
) => {
  state.selectedItems.push(action.payload);
};

const _handleUnselectItem: CaseReducer<TTransferTokenState, PayloadAction<number>> = (
  state,
  action
) => {
  const i = state.selectedItems.findIndex((item) => item.id === action.payload);
  state.selectedItems.splice(i, 1);
};

const _handleSetFetchingData: CaseReducer<TTransferTokenState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.isFetching = action.payload;
};

const transferTokenSlice = createSlice({
  name: "transferTokenSlice",
  initialState,
  reducers: {
    handleChangeFilters: _handleChangeFilters,
    handleChangeTransferType: _handleChangeTransferType,
    clearState: _clearState,
    handleSetSelectedItems: _handleSetSelectedItems,
    handleSelectItem: _handleSelectItem,
    handleUnselectItem: _handleUnselectItem,
    handleSetFetchingData: _handleSetFetchingData,
  },
  extraReducers: (builder) => {
    builder.addMatcher(dataTransferApi.endpoints.getList.matchFulfilled, (state, action) => {
      state.walletAddressList = action.payload.result.data.map((obj) => ({
        ...obj,
        selected: false,
      }));
      state.metaRes = action.payload.result.meta;
      state.isFetching = false;
    });

    builder.addMatcher(dataTransferApi.endpoints.getList.matchPending, (state) => {
      state.isFetching = true;
    });
    builder.addMatcher(dataTransferApi.endpoints.getList.matchRejected, (state) => {
      state.isFetching = false;
    });

    builder.addMatcher(dataTransferApi.endpoints.getListBatch.matchFulfilled, (state, action) => {
      const batchList = [...action.payload.result].map((batch) => ({
        label: String(batch),
        value: batch,
      }));

      state.batchList = DEFAULT_BATCH_OPTION.concat(batchList);
    });

    builder.addMatcher(dataTransferApi.endpoints.getFullList.matchFulfilled, (state, action) => {
      if (!state.transferFilters.batch) return;
      if (state.saveList[state.transferFilters.batch]) return;
      state.saveList[state.transferFilters.batch] = action.payload.result.data;
    });
  },
});

export const transferTokenActions = transferTokenSlice.actions;
export default transferTokenSlice.reducer;
