import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import ERC20_ABI from "../constants/abis/erc20";
import { ZERO_ADDRESS } from "../constants/constant";
import { getContract } from "../functions/contract";
import { IWeb3ReactContext } from "./useWeb3Activity";

export function useTokenAllowance(
  context: IWeb3ReactContext,
  tokenAddress?: string,
  owner?: string,
  spender?: string,
  dependency?: unknown
): BigNumber | undefined {
  const [allowance, setAllowance] = useState<BigNumber | undefined>(undefined);
  useMemo(() => {
    if (!tokenAddress || tokenAddress === String(undefined)) return setAllowance(undefined);
    if (tokenAddress === ZERO_ADDRESS) return setAllowance(undefined);
    if (!owner) return setAllowance(undefined);

    const contract = getContract(
      tokenAddress,
      ERC20_ABI,
      context.library,
      context.account || undefined
    );

    if (tokenAddress) {
      contract
        .allowance(owner, spender)
        .then((allowance: BigNumber) => {
          setAllowance(allowance);
        })
        .catch((err: unknown) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    } else {
      setAllowance(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress, context.library, context.account, owner, spender, dependency]);

  return allowance;
}
