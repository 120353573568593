import { BigNumberish } from "ethers";
import { useEffect } from "react";
import { walletActions } from "../stores/walletSlice";
import { formatBalance, formatIntBalance } from "../utils/format";
import { useAppDispatch, useAppSelector } from "./common";
import { useBlockNumber } from "./useBlockNumber";
import { useWeb3Activity } from "./useWeb3Activity";

const useNativeTokenBalance = () => {
  const walletState = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();
  const { chainId, library, account } = useWeb3Activity();

  const latestBlock = useBlockNumber(chainId);

  useEffect(() => {
    if (chainId === walletState.chainId) {
      library?.getBalance(account).then((balance: BigNumberish) => {
        const balanceFormatted = formatIntBalance(formatBalance(balance));
        dispatch(walletActions.handleSetBalance(balanceFormatted));
      });
    }
  }, [account, dispatch, library, latestBlock, chainId, walletState.chainId]);
};

export default useNativeTokenBalance;
