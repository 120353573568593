import { TOption } from "../components/bases/SelectBox";
import { CHAINS_ID } from "./chains";

export const LIMIT_PAGE = 20;
export const BUTTON_CONST = 3;
export const SIBLING_COUNT = 1;
export const MAX_LIMIT = 800;
export const SYSTEM_TYPE = "1TN";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const WALLET = {
  METAMASK: "MetaMask",
};

export const ERROR_TRANSACTION = {
  reject: {
    codeNumber: 4001,
    codeString: "ACTION_REJECTED",
  },
  balanceERC20: {
    codeNumber: -32603,
    codeString: "execution reverted: Sender balance is not enough!",
  },
  balanceNative: {
    codeNumber: -32603,
    codeString1: "insufficient funds for gas * price + value",
    codeString2: "Native token value is not enough!",
    codeString3: "insufficient funds for intrinsic transaction cost",
  },
};

export const DEFAULT_PAGE_LIMIT = 10;

export const METAMASK_SIGN_MESSAGE = process.env.REACT_APP_METAMASK_SIGN_MESSAGE || "";

export const NETWORK_BOX_TYPE = {
  NETWORK: "network",
  WALLET: "wallet",
};

export enum METAMASK_ERROR_CODE {
  // RPC
  INVALID_INPUT = -32000,
  RESOURCE_NOT_FOUND = -32001,
  RESOURCE_UNAVAILABLE = -32002,
  TRANSACTION_REJECTED = -32003,
  METHOD_NOT_SUPPORTED = -32004,
  LIMIT_EXCEEDED = -32005,
  PARSE = -32700,
  INVALID_REQUEST = -32600,
  METHOD_NOT_FOUND = -32601,
  INVALID_PARAMS = -32602,
  INTERNAL = -32603,

  // PROVIDER
  USER_REJECTED_REQUEST = 4001,
  UNAUTHORIZED = 4100,
  UNSUPPORTED_METHOD = 4200,
  DISCONNECTED = 4900,
  CHAIN_DISCONNECTED = 4901,
}

export const DEFAULT_BATCH_OPTION: TOption[] = [
  {
    label: "All",
    value: "all",
  },
];

export const BLOCK_GAS_LIMIT = {
  [CHAINS_ID.MAINNET]: 30000000,
  [CHAINS_ID.GÖRLI]: 30000000,
  [CHAINS_ID.MATIC]: 30000000,
  [CHAINS_ID.MATIC_TESTNET]: 20000000,
};

export const NATIVE_TOKEN_SYMBOL = {
  [CHAINS_ID.MAINNET]: "ETH",
  [CHAINS_ID.GÖRLI]: "ETH",
  [CHAINS_ID.MATIC]: "MATIC",
  [CHAINS_ID.MATIC_TESTNET]: "MATIC",
};
