import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import { SUPPORTED_CHAIN } from "../config/network";
import { useAppSelector } from "./common";
import { useChainId } from "./useContract";

export interface IWeb3ReactContext extends Web3ReactContextInterface {
  chainId: number;
  isConnected: boolean;
  isSupportedNetwork: boolean;
}

export const useWeb3Activity = (): IWeb3ReactContext => {
  const context = useWeb3React<Web3Provider>();
  const chainId = useChainId();
  const { isConnected } = useAppSelector((state) => state.wallet);

  return {
    ...context,
    chainId,
    isConnected,
    isSupportedNetwork: SUPPORTED_CHAIN.includes(chainId),
  };
};
