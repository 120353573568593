import { TVestingListReturn, TVestingListQuery } from "../../classes/Api";
import cosplayApi from "../config";

const vestingApi = cosplayApi.injectEndpoints({
  endpoints: (builder) => ({
    getVestingList: builder.query<TVestingListReturn, TVestingListQuery>({
      query: (params) => ({
        url: "/v1/api/vesting-transaction",
        params,
      }),
    }),
  }),
});

export default vestingApi;
