import React, { BaseHTMLAttributes, useCallback, useState } from "react";
import Modal from "../bases/Modal";
import { Button } from "../bases/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import { transferTokenActions } from "../../stores/transferTokenSlice";
import Spinner from "../commons/Spinner";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { modalSliceActions } from "../../stores/modalSlice";
import { ERROR_TRANSACTION, SYSTEM_TYPE, ZERO_ADDRESS } from "../../constants/constant";
import { TransactionReceipt, TransactionResponse } from "@ethersproject/providers";
import { ethers } from "ethers";
import { decryptedPrivateKey } from "../../utils/decryptKey";
import { AES_SECRET_KEY } from "../../constants/contractAddress";
import { useWeb3Activity } from "../../hooks/useWeb3Activity";
import { TWalletImported } from "../../classes/Api";
import { parseBalance } from "../../utils/format";
import { BigNumber } from "ethers";
import { useTokenTransferContract } from "../../hooks/useContract";

export interface IConfirmTransferProps extends BaseHTMLAttributes<HTMLDivElement> {
  container: HTMLElement;
  onCloseHandler?: () => void;
  propsState: {
    listAddressTransfer: TWalletImported[];
    addressOfToken: string;
    decimals: number;
  };
}

export default function ConfirmTransfer({
  container,
  onCloseHandler,
  propsState: { listAddressTransfer, addressOfToken, decimals },
}: IConfirmTransferProps): React.ReactElement {
  const { decryptPrivateKey } = useAppSelector((state) => state.auth);
  const { library } = useWeb3Activity();
  const [isTransactionsPending, setIsTransactionsPending] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const context = useWeb3Activity();

  const tokenTransferContract = useTokenTransferContract(context);

  const handleRejectTransfer = useCallback(() => {
    dispatch(
      toastMessageActions.addToastMessage({
        type: "danger",
        title: "Signature rejected",
      })
    );
    dispatch(modalSliceActions.shiftFromQueue());
  }, [dispatch]);

  const handleConfirmTransfer = useCallback(
    async (listAddressTransfer: TWalletImported[]) => {
      try {
        if (!tokenTransferContract) return;
        if (!addressOfToken) return;
        const transactionFailedList: string[] = [];
        const getAddressList = listAddressTransfer.map((element) => element.address.trim());
        const getAmountList = listAddressTransfer.map((element) =>
          parseBalance(element.amount, decimals)
        );
        let totalAmount = BigNumber.from(0);

        if (addressOfToken === ZERO_ADDRESS) {
          for (const amount of getAmountList) {
            totalAmount = BigNumber.from(totalAmount).add(BigNumber.from(amount));
          }
        }

        const gasLimit =
          addressOfToken === ZERO_ADDRESS
            ? await tokenTransferContract.estimateGas.transferToken(
                addressOfToken,
                getAddressList[0],
                getAmountList[0],
                SYSTEM_TYPE,
                {
                  value: totalAmount,
                }
              )
            : await tokenTransferContract.estimateGas.transferToken(
                addressOfToken,
                getAddressList[0],
                getAmountList[0],
                SYSTEM_TYPE
              );
        if (!decryptPrivateKey) {
          const transactionResponseList: TransactionResponse[] = [];
          let toastSuccessMessageFlag = true;
          for (let i = 0; i < listAddressTransfer.length; i++) {
            try {
              const transactionResponse: TransactionResponse =
                addressOfToken === ZERO_ADDRESS
                  ? await tokenTransferContract.transferToken(
                      addressOfToken,
                      getAddressList[i],
                      getAmountList[i],
                      SYSTEM_TYPE,
                      {
                        gasLimit: BigNumber.from(gasLimit)
                          .mul(BigNumber.from(3))
                          .div(BigNumber.from(2)),
                        value: totalAmount,
                      }
                    )
                  : await tokenTransferContract.transferToken(
                      addressOfToken,
                      getAddressList[i],
                      getAmountList[i],
                      SYSTEM_TYPE,
                      {
                        gasLimit: BigNumber.from(gasLimit)
                          .mul(BigNumber.from(3))
                          .div(BigNumber.from(2)),
                      }
                    );
              if (!isTransactionsPending) setIsTransactionsPending(true);
              transactionResponseList.push(transactionResponse);
            } catch ({ code, reason }) {
              if (
                code === ERROR_TRANSACTION.reject.codeNumber ||
                code === ERROR_TRANSACTION.reject.codeString
              ) {
                dispatch(
                  toastMessageActions.addToastMessage({
                    type: "danger",
                    title: "Signature rejected",
                  })
                );
                if (i === getAddressList.length - 1 && transactionResponseList.length === 0) {
                  toastSuccessMessageFlag = false;
                }
              } else {
                dispatch(
                  toastMessageActions.addToastMessage({
                    type: "danger",
                    title: "Something went wrong!",
                  })
                );
                dispatch(modalSliceActions.shiftFromQueue());
                return;
              }
            }
          }

          for (const response of transactionResponseList) {
            const receipt: TransactionReceipt = await response.wait();

            if (receipt?.status !== 1) {
              transactionFailedList.push(receipt.transactionHash);
            }
          }
          if (transactionFailedList.length > 0) {
            setIsTransactionsPending(false);
            dispatch(modalSliceActions.shiftFromQueue());
            dispatch(
              modalSliceActions.addToQueue({
                type: "popup/data-transfer",
                propsState: {
                  message: transactionFailedList,
                },
              })
            );
          } else {
            if (toastSuccessMessageFlag) {
              dispatch(
                toastMessageActions.addToastMessage({
                  type: "success",
                  title: "Successfully",
                  description: "Transfer token successfully",
                })
              );
            }
            setIsTransactionsPending(false);
            dispatch(modalSliceActions.shiftFromQueue());
            dispatch(transferTokenActions.clearState());
          }
        } else {
          const transactionPendingList: TransactionResponse[] = [];
          try {
            const privateKey = decryptedPrivateKey(String(AES_SECRET_KEY), decryptPrivateKey);
            const wallet = new ethers.Wallet(privateKey, library);
            const nonce = await wallet.getTransactionCount();
            setIsTransactionsPending(true);
            for (let i = 0; i < listAddressTransfer.length; i++) {
              const transactionResponse: TransactionResponse =
                addressOfToken === ZERO_ADDRESS
                  ? tokenTransferContract
                      .connect(wallet)
                      .transferToken(
                        addressOfToken,
                        getAddressList[i],
                        getAmountList[i],
                        SYSTEM_TYPE,
                        {
                          gasLimit: BigNumber.from(gasLimit)
                            .mul(BigNumber.from(3))
                            .div(BigNumber.from(2)),
                          value: totalAmount,
                          nonce: nonce + i,
                        }
                      )
                  : tokenTransferContract
                      .connect(wallet)
                      .transferToken(
                        addressOfToken,
                        getAddressList[i],
                        getAmountList[i],
                        SYSTEM_TYPE,
                        {
                          gasLimit: BigNumber.from(gasLimit)
                            .mul(BigNumber.from(3))
                            .div(BigNumber.from(2)),
                          nonce: nonce + i,
                        }
                      );
              transactionPendingList.push(transactionResponse);
            }
          } catch {
            dispatch(
              toastMessageActions.addToastMessage({
                type: "danger",
                title: "Something went wrong!",
              })
            );
            dispatch(modalSliceActions.shiftFromQueue());
            return;
          }

          const transactionResponseList = await Promise.all(transactionPendingList);

          for (const response of transactionResponseList) {
            const receipt: TransactionReceipt = await response.wait();

            if (receipt?.status !== 1) {
              transactionFailedList.push(receipt.transactionHash);
            }
          }
          if (transactionFailedList.length > 0) {
            setIsTransactionsPending(false);
            dispatch(modalSliceActions.shiftFromQueue());
            dispatch(
              modalSliceActions.addToQueue({
                type: "popup/data-transfer",
                propsState: {
                  message: transactionFailedList,
                },
              })
            );
          } else {
            dispatch(
              toastMessageActions.addToastMessage({
                type: "success",
                title: "Successfully",
                description: "Transfer token successfully",
              })
            );

            setIsTransactionsPending(false);
            dispatch(modalSliceActions.shiftFromQueue());
            dispatch(transferTokenActions.clearState());
          }
        }
      } catch ({ code, reason, data }) {
        const errMsg = Object(data).message;

        if (reason === ERROR_TRANSACTION.balanceERC20.codeString) {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Insufficient ERC20 tokens",
            })
          );
        } else if (errMsg && errMsg.includes(ERROR_TRANSACTION.balanceNative.codeString1)) {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Insufficient native tokens",
            })
          );
        } else if (
          reason &&
          (String(reason).includes(ERROR_TRANSACTION.balanceNative.codeString2) ||
            String(reason).includes(ERROR_TRANSACTION.balanceNative.codeString3))
        ) {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Insufficient native tokens",
            })
          );
        } else {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Something went wrong!",
            })
          );
        }
        dispatch(modalSliceActions.shiftFromQueue());
      }
    },
    [
      dispatch,
      library,
      tokenTransferContract,
      decryptPrivateKey,
      isTransactionsPending,
      addressOfToken,
      decimals,
    ]
  );

  return (
    <Modal
      onCloseHandler={onCloseHandler}
      container={container}
      bCloseBtn={isTransactionsPending ? false : true}
      className="w-[420px]"
    >
      <div className="text-xl font-bold">
        {decryptPrivateKey
          ? "The confirmation will be done automatically, you cannot cancel the transaction. Do you want to continue?"
          : "*Warning: Connected account hasn't provided private key, confirmation process will need to be done manually! Do you want to continue?"}
      </div>

      {isTransactionsPending ? (
        <Button className="btn-primary w-full mt-4 gap-2">
          <Spinner size="large" />
          Sending
        </Button>
      ) : (
        <div className="flex justify-between items-center gap-3 w-full">
          <Button
            className="btn-primary w-full mt-6"
            onClick={() => handleConfirmTransfer(listAddressTransfer)}
          >
            Confirm
          </Button>
          <Button className="btn-primary w-full mt-6" onClick={handleRejectTransfer}>
            Reject
          </Button>
        </div>
      )}
    </Modal>
  );
}
