import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Column, usePagination, useTable } from "react-table";
import addressApi from "../../apis/endpoints/address";
import { TListQuery, TWalletCreated } from "../../classes/Api";
import { Button } from "../../components/bases/Button";
import Table, { TablePagination } from "../../components/bases/Table";
import DefaultLayout from "../../components/layout/DefaultLayout";
import WalletFiltersComponent from "../../components/filters/WalletFiltersComponent";
import { DEFAULT_BATCH_OPTION } from "../../constants/constant";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import useDebounce from "../../hooks/useDebounce";
import { generateWalletActions } from "../../stores/generateWalletSlice";
import { modalSliceActions } from "../../stores/modalSlice";
import { removeEmptyProperties } from "../../utils/utils";

const headers = [
  { label: "No.", key: "id" },
  { label: "Time", key: "createdAt" },
  { label: "Address", key: "walletAddress" },
  // { label: "Amount", key: "" },
  // { label: "Symbol", key: "" },
  { label: "Batch", key: "batch" },
];

type TRef = CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement };

export const GenerateWallet = () => {
  const { queryFilters, totalItems, walletAddressList, updater, batchList } = useAppSelector(
    (state) => state.generateWallet
  );
  const dispatch = useAppDispatch();

  const csvLink = useRef<TRef>(null);
  const [exportList, setExportList] = useState<TWalletCreated[]>([]);

  const [getAccountList, accountResponseState] = addressApi.useLazyListQuery();
  const [getListBatch] = addressApi.useLazyGetBatchQuery();
  const [getDataExport] = addressApi.useLazyListExportQuery();

  const numPages = useMemo(() => {
    const controlledPageCount = Math.ceil(totalItems / queryFilters.take);
    return controlledPageCount;
  }, [totalItems, queryFilters.take]);

  const filtersDebounce = useDebounce(queryFilters, 400);

  const columns: Column<TWalletCreated>[] = useMemo((): Column<TWalletCreated>[] => {
    return [
      {
        Header: "No.",
        accessor: "id",
        width: "10%",
        Cell: ({ index, row }) => {
          return (
            <Table.Cell className="pl-14" key={index}>
              {row.index + (queryFilters.page - 1) * queryFilters.take + 1}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Address",
        accessor: "walletAddress",
        width: "40%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8" key={index}>
              {value}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Batch",
        accessor: "batch",
        width: "20%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              {value}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Created Date",
        accessor: "createdAt",
        width: "20%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              {moment(value).format("MM/DD/YYYY")}
            </Table.Cell>
          );
        },
      },
    ];
  }, [queryFilters.page, queryFilters.take]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: walletAddressList,
      manualPagination: true,
      initialState: {
        pageSize: queryFilters.take,
        pageIndex: queryFilters.page - 1,
      },
      pageCount: numPages,
      autoResetPage: false,
    },
    usePagination
  );

  const handleNext = useCallback(() => {
    if (canNextPage) {
      dispatch(generateWalletActions.handleChangeFilters({ page: queryFilters.page + 1 }));
      nextPage();
    }
  }, [canNextPage, dispatch, nextPage, queryFilters.page]);

  const handlePrev = useCallback(() => {
    if (canPreviousPage) {
      dispatch(generateWalletActions.handleChangeFilters({ page: queryFilters.page - 1 }));
      previousPage();
    }
  }, [canPreviousPage, dispatch, previousPage, queryFilters.page]);

  const handleGotoPage = useCallback(
    (updater: number) => {
      dispatch(generateWalletActions.handleChangeFilters({ page: updater + 1 }));
      gotoPage(updater);
    },
    [dispatch, gotoPage]
  );

  const handleGenerateButtonClicked = useCallback(() => {
    dispatch(
      modalSliceActions.addToQueue({
        type: "popup/generate-wallet",
      })
    );
  }, [dispatch]);

  const handleExportWalletAddressList = useCallback(async () => {
    setExportList([]);
    const params: Partial<TListQuery> = { ...queryFilters };
    delete params.take;
    delete params.page;
    const res = await getDataExport({ ...params });
    if (res.data?.statusCode === 200) {
      const result = res.data.result.map((item, index) => ({
        ...item,
        id: index + 1,
        createdAt: moment(item.createdAt).format("MM/DD/YYYY"),
      }));
      setExportList(result);
    }
  }, [queryFilters, getDataExport]);

  const handleSubmitSearch = useCallback(
    (params: Partial<TListQuery>) => {
      dispatch(
        generateWalletActions.handleChangeFilters({
          ...params,
          page: 1,
        })
      );
      gotoPage(0);
    },
    [dispatch, gotoPage]
  );

  useEffect(() => {
    getListBatch(undefined);
  }, [getListBatch, updater]);

  useEffect(() => {
    const params: Partial<TListQuery> = removeEmptyProperties({
      ...filtersDebounce,
      batch: filtersDebounce.batch === DEFAULT_BATCH_OPTION[0].value ? "" : filtersDebounce.batch,
    });
    getAccountList(params as TListQuery);
  }, [filtersDebounce, getAccountList, updater]);

  useEffect(() => {
    return () => {
      dispatch(generateWalletActions.clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!exportList.length) return;
    if (csvLink && csvLink.current && csvLink.current.link) {
      csvLink.current.link.click();
    }
  }, [exportList.length]);

  return (
    <DefaultLayout title="Generate Wallet">
      <Button className="btn-primary" onClick={handleGenerateButtonClicked}>
        Generate
      </Button>
      <WalletFiltersComponent
        className="mt-6"
        actionable={
          <Button
            className="mt-6 btn-secondary min-w-[133px] !px-0 ml-10"
            onClick={handleExportWalletAddressList}
            size="sm"
          >
            Export to CSV
          </Button>
        }
        queryFilters={queryFilters}
        onSubmitQuery={handleSubmitSearch}
        batchOptions={batchList}
        isGenerateWallet={true}
      />
      <div className="min-h-[300px] mt-4">
        <Table
          {...getTableProps()}
          isLoading={accountResponseState?.isLoading || accountResponseState?.isFetching}
          noRecord={!walletAddressList.length}
        >
          <Table.Head>
            {headerGroups?.map((headerGroup) => (
              <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((col) => {
                  return (
                    <Table.HeadCell
                      {...col.getHeaderProps()}
                      style={{ width: col.width }}
                      className={cn("pl-8", {
                        "text-center": col.id === "batch" || col.id === "createdAt",
                        "!pl-14": col.id === "id",
                      })}
                    >
                      {col.render("Header")}
                    </Table.HeadCell>
                  );
                })}
              </Table.HeadRow>
            ))}
          </Table.Head>
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <Table.Row
                  color={"inherit"}
                  {...row.getRowProps()}
                  key={index}
                  className={cn(index % 2 === 0 ? "bg-white" : "bg-[#FDFDFD]")}
                >
                  {row.cells.map((cell, index) => (
                    <React.Fragment key={index}>{cell.render("Cell", { index })}</React.Fragment>
                  ))}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <TablePagination
          currentPage={queryFilters.page}
          pageCount={pageCount}
          nextPage={handleNext}
          prevPage={handlePrev}
          hasNext={canNextPage}
          hasPrev={canPreviousPage}
          className="mt-5"
          gotoPage={handleGotoPage}
        />
      </div>
      <CSVLink
        headers={headers}
        data={exportList}
        filename={`account-list_${moment().format("MM-DD-YYYY")}`}
        ref={csvLink}
      />
    </DefaultLayout>
  );
};
