import { AddressZero } from "@ethersproject/constants";
import { Contract } from "@ethersproject/contracts";
import { JsonRpcSigner, Provider, Web3Provider } from "@ethersproject/providers";
import { ContractInterface } from "ethers";
import { isAddress } from "./validate";

// account is not optional
function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(
    library: Web3Provider,
    account?: string
): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
    address: string,
    ABI: ContractInterface,
    library: Web3Provider,
    account?: string
): Contract {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid "address" parameter "${address}".`);
    }

    return new Contract(address, ABI, getProviderOrSigner(library, account) as Provider);
}
