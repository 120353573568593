import React from "react";
import { IconProps } from "../../classes/Icon";

const LogoutIcon: React.FC<IconProps> = ({
  size = "20",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12547 12.7006C9.62219 12.6819 10.04 13.0693 10.0588 13.566C10.1011 14.6858 10.1605 15.5031 10.2188 16.0891C10.2763 16.6661 10.6246 17.0133 11.1103 17.0727C11.6829 17.1427 12.4907 17.2 13.5992 17.2C14.7077 17.2 15.5155 17.1427 16.0881 17.0727C16.5735 17.0133 16.922 16.666 16.9796 16.0888C17.0879 15.0011 17.1991 13.1219 17.1991 10C17.1991 6.87806 17.0879 4.99889 16.9796 3.91121C16.922 3.334 16.5735 2.98663 16.0881 2.92729C15.5155 2.8573 14.7077 2.8 13.5992 2.8C12.4907 2.8 11.6829 2.85729 11.1103 2.92729C10.6246 2.98665 10.2763 3.33386 10.2188 3.91093C10.1605 4.49691 10.1011 5.31419 10.0588 6.43395C10.04 6.93065 9.62219 7.3181 9.12547 7.29935C8.62877 7.28061 8.24131 6.86275 8.26007 6.36605C8.30345 5.21672 8.36497 4.36189 8.42767 3.73251C8.55948 2.40936 9.482 1.31293 10.8918 1.14058C11.5481 1.06036 12.43 1 13.5992 1C14.7685 1 15.6502 1.06036 16.3065 1.14059C17.7166 1.31296 18.6389 2.4099 18.7706 3.73279C18.8867 4.89761 18.9992 6.83962 18.9992 10C18.9992 13.1603 18.8867 15.1024 18.7706 16.2672C18.6389 17.5901 17.7166 18.687 16.3065 18.8594C15.6502 18.9396 14.7685 19 13.5992 19C12.43 19 11.5481 18.9396 10.8918 18.8594C9.482 18.687 8.55948 17.5906 8.42767 16.2675C8.36497 15.6381 8.30345 14.7833 8.26007 13.6339C8.24131 13.1372 8.62877 12.7194 9.12547 12.7006Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6864 12.5136C6.03787 12.8651 6.03787 13.435 5.6864 13.7864C5.33492 14.1378 4.76507 14.1378 4.41361 13.7864L1.26361 10.6364C0.912131 10.2849 0.912131 9.7151 1.26361 9.36362L4.41361 6.21362C4.76507 5.86216 5.33492 5.86216 5.6864 6.21362C6.03787 6.5651 6.03787 7.13495 5.6864 7.48642L4.07279 9.10002H12.7C13.1971 9.10002 13.6 9.50296 13.6 10C13.6 10.4971 13.1971 10.9 12.7 10.9H4.07279L5.6864 12.5136Z"
        fill={color}
      />
    </svg>
  );
};

export default LogoutIcon;
