import { CHAINS_ID } from "./chains";

export const TOKEN_TRANSFER_ADDRESS = {
  [CHAINS_ID.MAINNET]: process.env.REACT_APP_TOKEN_TRANSFER_ETHEREUM,
  [CHAINS_ID.GÖRLI]: process.env.REACT_APP_TOKEN_TRANSFER_GOERLI,
  [CHAINS_ID.MATIC]: process.env.REACT_APP_TOKEN_TRANSFER_POLYGON,
  [CHAINS_ID.MATIC_TESTNET]: process.env.REACT_APP_TOKEN_TRANSFER_MUMBAI,
};

export const ADMIN_ROLE = process.env.REACT_APP_ADMIN_ROLE || "";

export const AES_SECRET_KEY = process.env.REACT_APP_AES_SECRET_KEY;
