import React from "react";
import { IconProps } from "../../classes/Icon";

const SearchIcon: React.FC<IconProps> = ({ size = "24", color = "#C92B5B", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2491 10.4991C17.2491 11.3855 17.0745 12.2632 16.7352 13.0822C16.3961 13.9011 15.8988 14.6452 15.2721 15.2721C14.6453 15.8988 13.9012 16.396 13.0822 16.7353C12.2633 17.0745 11.3855 17.2491 10.4991 17.2491C9.6127 17.2491 8.73496 17.0745 7.91601 16.7353C7.09707 16.396 6.35295 15.8988 5.72616 15.2721C5.09935 14.6452 4.60215 13.9011 4.26293 13.0822C3.9237 12.2632 3.74913 11.3855 3.74913 10.4991C3.74913 8.7089 4.46028 6.99202 5.72616 5.72615C6.99202 4.46027 8.70891 3.74912 10.4991 3.74912C12.2893 3.74912 14.0062 4.46027 15.2721 5.72615C16.538 6.99202 17.2491 8.7089 17.2491 10.4991ZM16.0191 17.6091C14.2107 19.0131 11.9352 19.675 9.65583 19.4602C7.37647 19.2456 5.26464 18.1702 3.75025 16.4532C2.23585 14.7361 1.43275 12.5065 1.50442 10.2182C1.57611 7.92992 2.51718 5.75492 4.13605 4.13605C5.75494 2.51717 7.92993 1.5761 10.2182 1.50442C12.5065 1.43274 14.7362 2.23585 16.4532 3.75025C18.1703 5.26463 19.2456 7.37647 19.4603 9.65582C19.6751 11.9351 19.0131 14.2106 17.6091 16.0191L22.1691 20.5791C22.2797 20.6821 22.3683 20.8063 22.4298 20.9443C22.4913 21.0823 22.5243 21.2313 22.527 21.3823C22.5297 21.5334 22.5018 21.6834 22.4453 21.8235C22.3887 21.9636 22.3044 22.0908 22.1976 22.1976C22.0908 22.3045 21.9636 22.3887 21.8235 22.4452C21.6834 22.5018 21.5334 22.5297 21.3824 22.527C21.2313 22.5243 21.0822 22.4913 20.9444 22.4298C20.8064 22.3683 20.6822 22.2796 20.5791 22.1691L16.0191 17.6091Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
