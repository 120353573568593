import cn from "classnames";
import React from "react";

interface Props extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "size" | "fullWidth"> {
  size?: "sm" | "md";
}

export const Button = React.forwardRef(function Button(
  { children, className, size = "md", ...props }: Props,
  ref: React.Ref<HTMLButtonElement>
) {
  const getSize = {
    sm: "text-sm px-7 py-2 rounded-[100px]",
    md: "text-base py-3 px-10 rounded-[100px]",
  };

  return (
    <button
      ref={ref}
      className={cn(
        "font-input font-extrabold whitespace-nowrap select-none btn",
        !className?.includes("btn-text") ? getSize[size] : "",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
});

Button.displayName = "Button";
