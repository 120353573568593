import { ReactElement } from "react";
import EthereumIcon from "../components/icons/Network/EthereumIcon";
import EthereumRoundedIcon from "../components/icons/Network/EthereumRoundedIcon";
import PolygonIcon from "../components/icons/Network/PolygonIcon";
import PolygonRoundedIcon from "../components/icons/Network/PolygonRoundedIcon";
import { ChainId } from "../constants/chains";

export const SUPPORTED_NETWORKS: Record<
  number,
  {
    chainId: string;
    chainName: string;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls: string[];
    scanName?: string;
  }
> = {
  [ChainId.MAINNET]: {
    chainId: "0x1",
    chainName: "Ethereum",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3"],
    blockExplorerUrls: ["https://etherscan.com"],
  },
  [ChainId.GÖRLI]: {
    chainId: "0x5",
    chainName: "Goerli",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://goerli.infura.io/v3"],
    blockExplorerUrls: ["https://goerli.etherscan.io"],
  },
  [ChainId.MATIC]: {
    chainId: "0x89",
    chainName: "Matic",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  [ChainId.MATIC_TESTNET]: {
    chainId: "0x13881",
    chainName: "Mumbai",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
};

export const SUPPORTED_CHAIN =
  process.env.REACT_APP_NETWORK === "TESTNET"
    ? [ChainId.GÖRLI, ChainId.MATIC_TESTNET]
    : [ChainId.MAINNET, ChainId.MATIC];

export const DEFAULT_CHAIN_ID = Number(process.env.REACT_APP_DEFAULT_CHAIN_ID);

export const NETWORK_DETAILS: Record<
  number,
  {
    name: string;
    icon: ReactElement;
    roundedIcon: ReactElement;
    chainId: number;
    label: string;
    value: number;
  }
> = {
  [ChainId.MAINNET]: {
    name: "Ethereum",
    label: "Ethereum",
    icon: <EthereumIcon className="w-28 h-28"/>,
    roundedIcon: <EthereumRoundedIcon />,
    chainId: ChainId.MAINNET,
    value: ChainId.MAINNET,
  },

  [ChainId.MATIC]: {
    name: "Polygon",
    label: "Polygon",
    icon: <PolygonIcon className="w-28 h-28"/>,
    roundedIcon: <PolygonRoundedIcon />,
    chainId: ChainId.MATIC,
    value: ChainId.MATIC,
  },
  [ChainId.GÖRLI]: {
    name: "Goerli",
    label: "Goerli",
    icon: <EthereumIcon className="w-28 h-28"/>,
    roundedIcon: <EthereumRoundedIcon />,
    chainId: ChainId.GÖRLI,
    value: ChainId.GÖRLI,
  },

  [ChainId.MATIC_TESTNET]: {
    name: "Mumbai",
    label: "Mumbai",
    icon: <PolygonIcon className="w-28 h-28"/>,
    chainId: ChainId.MATIC_TESTNET,
    roundedIcon: <PolygonRoundedIcon />,
    value: ChainId.MATIC_TESTNET,
  },
};

export const networkOptions =
  process.env.REACT_APP_NETWORK === "TESTNET"
    ? [NETWORK_DETAILS[ChainId.GÖRLI], NETWORK_DETAILS[ChainId.MATIC_TESTNET]]
    : [NETWORK_DETAILS[ChainId.MAINNET], NETWORK_DETAILS[ChainId.MATIC]];

export const NETWORK_SCAN: Record<number, string> = {
  [ChainId.MAINNET]: "Etherscan",
  [ChainId.GÖRLI]: "Etherscan",
  [ChainId.MATIC]: "Polygonscan",
  [ChainId.MATIC_TESTNET]: "Polygonscan",
};
