import { ContractReceipt } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { removeTransaction, updateBlockNumber } from ".";
import { NETWORK_SCAN, SUPPORTED_NETWORKS } from "../../config/network";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import { useBlockNumber } from "../../hooks/useBlockNumber";
import useDebounce from "../../hooks/useDebounce";
import useIsWindowVisible from "../../hooks/useIsWindowVisible";
import { useWeb3Activity } from "../../hooks/useWeb3Activity";
import { toastMessageActions } from "../toastMessageSlice";
import { transferTokenActions } from "../transferTokenSlice";

export function BlockNumberUpdater(): null {
  const { library, chainId } = useWeb3Activity();

  const dispatch = useAppDispatch();
  const windowVisible = useIsWindowVisible();

  const [state, setState] = useState<{
    chainId: number | undefined;
    blockNumber: number | null;
  }>({
    chainId,
    blockNumber: null,
  });

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState((state) => {
        if (chainId === state.chainId) {
          if (typeof state.blockNumber !== "number") return { chainId, blockNumber };
          return {
            chainId,
            blockNumber: Math.max(blockNumber, state.blockNumber),
          };
        }
        return state;
      });
    },
    [chainId, setState]
  );

  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || !windowVisible) return undefined;

    setState({ chainId, blockNumber: null });

    library
      .getBlockNumber()
      .then(blockNumberCallback)
      .catch((error: Error) =>
        // eslint-disable-next-line no-console
        console.error(`Failed to get block number for chainId: ${chainId}`, error)
      );

    library.on("block", blockNumberCallback);
    return () => {
      library.removeListener("block", blockNumberCallback);
    };
  }, [dispatch, chainId, library, blockNumberCallback, windowVisible]);

  const debouncedState = useDebounce(state, 100);

  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return;
    dispatch(
      updateBlockNumber({
        chainId: debouncedState.chainId,
        blockNumber: debouncedState.blockNumber,
      })
    );
  }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId]);

  return null;
}

export function shouldCheck(
  lastBlockNumber: number,
  tx: { addedTime: number; receipt?: unknown }
): boolean {
  const blocksSinceCheck = lastBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  } else {
    // otherwise every block
    return true;
  }
}

export default function TransactionUpdater(): null {
  const { chainId, library } = useWeb3Activity();

  const lastBlockNumber = useBlockNumber(chainId);
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.wallet);

  const transactions = useMemo(() => {
    return chainId ? state.transactions[chainId] ?? {} : {};
  }, [chainId, state]);

  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return;

    Object.keys(transactions)
      .filter((hash) => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach((hash) => {
        library
          .getTransactionReceipt(hash)
          .then((receipt: ContractReceipt) => {
            if (receipt) {
              if (receipt.status === 1) {
                dispatch(
                  toastMessageActions.addToastMessage({
                    type: "success",
                    title: "Transaction successfully",
                    description: `View on ${NETWORK_SCAN[chainId]}: `,
                    transaction: {
                      hash: receipt.transactionHash as string,
                      href: `${SUPPORTED_NETWORKS[chainId].blockExplorerUrls[0]}/tx/${receipt?.transactionHash}`,
                    },
                  })
                );
                dispatch(transferTokenActions.clearState());
              }
              if (!transactions[hash]) return;
              dispatch(
                removeTransaction({
                  chainId,
                  hash: receipt.transactionHash,
                })
              );
            }
          })
          .catch((error: Error) => {
            // eslint-disable-next-line no-console
            console.error(`failed to check transaction hash: ${hash}`, error);
          });
      });
  }, [chainId, library, transactions, lastBlockNumber, dispatch]);

  return null;
}
