import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect } from "react";
import authApi from "../../apis/endpoints/auth";
import { getNetworkConnector } from "../../config/wallet";
import TOKEN_TRANSFER_ABI from "../../constants/abis/token-transfer";
import {
  ERROR_TRANSACTION,
  METAMASK_ERROR_CODE,
  METAMASK_SIGN_MESSAGE,
} from "../../constants/constant";
import { ADMIN_ROLE, TOKEN_TRANSFER_ADDRESS } from "../../constants/contractAddress";
import { getContract } from "../../functions/contract";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import { useEagerConnect } from "../../hooks/useEagerConnect";
import { useInactiveListener } from "../../hooks/useInactiveListener";
import { useWeb3Activity } from "../../hooks/useWeb3Activity";
import { authActions } from "../../stores/authSlice";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { transferTokenActions } from "../../stores/transferTokenSlice";
import { walletActions } from "../../stores/walletSlice";
import StorageUtils from "../../utils/storage";

const NetworkContextName = "NETWORK";
const network = getNetworkConnector();

export default function Web3ReactManager({ children }: { children: JSX.Element }) {
  const {
    active,
    account: connectedAccount,
    library,
    chainId,
    isSupportedNetwork,
  } = useWeb3Activity();
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React(NetworkContextName);

  const triedEager = useEagerConnect();

  useEffect(() => {
    const activate = async () => {
      if (triedEager && !networkActive && !networkError && !active) {
        activateNetwork(network);
      }
    };
    activate();
  }, [triedEager, networkActive, networkError, activateNetwork, active]);

  useInactiveListener(!triedEager);

  const [loginApi, loginResponse] = authApi.useLoginMutation();
  const walletState = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getSignature = useCallback(async (library: any, address: string) => {
    const signer = library.getSigner();

    const oldSignature = StorageUtils.getSignature();
    const oldAddress = StorageUtils.getAccount();

    if (oldAddress === address.toLowerCase() && oldSignature) {
      return oldSignature;
    } else {
      const signature = await signer.signMessage(METAMASK_SIGN_MESSAGE, address);
      StorageUtils.setSignature(signature);
      StorageUtils.setAccount(address.toLowerCase());
      return signature;
    }
  }, []);

  const handleCheckAdminRole = useCallback(async () => {
    try {
      if (!walletState.account || !connectedAccount || walletState.chainId !== chainId) return;

      if (!TOKEN_TRANSFER_ADDRESS[chainId]) return;

      const tokenTransferContract = getContract(
        TOKEN_TRANSFER_ADDRESS[chainId] || "",
        TOKEN_TRANSFER_ABI,
        library,
        connectedAccount
      );
      if (!tokenTransferContract) return;

      const check = await tokenTransferContract.hasRole(ADMIN_ROLE, connectedAccount);

      dispatch(walletActions.handleSetHasRole(check));
      if (check) {
        const signature = await getSignature(library, connectedAccount);
        const params = {
          address: connectedAccount,
          signature,
          chainId: chainId,
        };
        await loginApi(params);
        dispatch(walletActions.handleSetConnected(true));
        dispatch(transferTokenActions.clearState());
      }
    } catch ({ code }) {
      if (
        code === METAMASK_ERROR_CODE.USER_REJECTED_REQUEST ||
        code === ERROR_TRANSACTION.reject.codeString
      ) {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Error",
            description: "Signature Rejected",
          })
        );
      }
      dispatch(authActions.logout());
      return;
    }
  }, [
    chainId,
    connectedAccount,
    dispatch,
    getSignature,
    library,
    loginApi,
    walletState.account,
    walletState.chainId,
  ]);

  useEffect(() => {
    handleCheckAdminRole();
  }, [handleCheckAdminRole]);

  useEffect(() => {
    if (loginResponse.error) {
      if ((loginResponse.error as { status?: number })?.status === 401) {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Access Denied!",
          })
        );
      }
      dispatch(authActions.logout());
    }
  }, [dispatch, loginResponse.error]);

  useEffect(() => {
    const ethereum = window?.ethereum;
    const handleAccountsChanged = (accounts: string[]) => {
      if (!accounts.length) {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Access Denied!",
          })
        );
        dispatch(authActions.logout());
      }
      StorageUtils.removeSignature();
      StorageUtils.removeToken();
    };

    if (ethereum?.on) {
      ethereum.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (ethereum?.removeListener) {
        ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, [dispatch]);

  useEffect(
    () => {
      if (!isSupportedNetwork || !walletState.isConnected) return;
      dispatch(walletActions.setChainId(chainId));
    },
    // eslint-disable-next-line
    [dispatch, isSupportedNetwork, walletState.isConnected]
  );

  useEffect(
    () => {
      if (!isSupportedNetwork) return;
      dispatch(walletActions.setChainId(chainId));
    },
    // eslint-disable-next-line
    [chainId]
  );

  if (!triedEager) {
    return null;
  }
  return children;
}
