import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import userApi from "../../apis/endpoints/user";
import { useAppDispatch } from "../../hooks/common";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { isAddress } from "@ethersproject/address";
import { ZERO_ADDRESS } from "../../constants/constant";
import Spinner from "../../components/commons/Spinner";
import DefaultLayout from "../../components/layout/DefaultLayout";
import { Input } from "../../components/bases/Input";
import { Button } from "../../components/bases/Button";
import { TErrorReturn } from "../../classes/Api";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

const ERROR_FIELD = {
  required: "Please enter wallet address",
  greater: "Zero address is not allowed",
  valid: "Wallet address must match ethereum address format",
};

const GrantRole = () => {
  {
    const [walletAddress, setWalletAddress] = useState<string>("");
    const [isAddingAdmin, setisAddingAdmin] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [handleAddAdmin, addAdminResponse] = userApi.useAddAdminMutation();
    const [error, setError] = useState<string>("");

    const handleClickAddAdminBtn = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!walletAddress) return setError(ERROR_FIELD.required);
        if (error) return;
        handleAddAdmin({ walletAddress: walletAddress });
        setisAddingAdmin(true);
      },
      [error, handleAddAdmin, walletAddress]
    );

    const handleOnValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setWalletAddress(value);
      if (!value) return setError(ERROR_FIELD.required);
      if (value.toLowerCase() === ZERO_ADDRESS) return setError(ERROR_FIELD.greater);
      if (!isAddress(value)) return setError(ERROR_FIELD.valid);
      return setError("");
    }, []);

    useEffect(
      () => {
        if (addAdminResponse.isSuccess) {
          setWalletAddress("");
          setisAddingAdmin(false);
          dispatch(
            toastMessageActions.addToastMessage({
              type: "success",
              title: "Successfully",
              description: `Successfully set address ${walletAddress} to admin role`,
            })
          );
        }
      },
      // eslint-disable-next-line
      [dispatch, addAdminResponse.isSuccess]
    );

    useEffect(() => {
      if (addAdminResponse.error) {
        setWalletAddress("");
        setisAddingAdmin(false);
        if ((addAdminResponse.error as FetchBaseQueryError).status === 400) {
          dispatch(
            toastMessageActions.addToastMessage({
              title: "Error",
              description: (addAdminResponse.error as TErrorReturn).data.message || "",
              type: "danger",
            })
          );
        } else {
          dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Something went wrong!",
            })
          );
        }
      }
    }, [dispatch, addAdminResponse.error]);

    return (
      <DefaultLayout title="Wallet Address">
        <form className="mt-8 w-full" onSubmit={handleClickAddAdminBtn}>
          <Input
            className={cn("w-[560px]", { "!border-danger": error })}
            placeholder="Address of wallet"
            value={walletAddress}
            onChange={handleOnValueChange}
          />
          <div className="text-xs text-danger mt-1 h-4">{error && error}</div>
          {isAddingAdmin ? (
            <Button className="btn-primary mt-2 gap-1" disabled={true}>
              <Spinner size="medium" /> Adding admin
            </Button>
          ) : (
            <Button className="btn-primary mt-2" type="submit">
              Grant role
            </Button>
          )}
        </form>
      </DefaultLayout>
    );
  }
};

export default GrantRole;
