import cn from "classnames";
import moment from "moment";
import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import { transactionHistoryActions } from "../../stores/transactionHistorySlice";
import DatePicker from "../bases/DatePicker";
import { Input } from "../bases/Input";
import SelectBox, { TOption } from "../bases/SelectBox";
import SearchIcon from "../icons/SearchIcon";

type Props = {
  className?: string;
};

const tranferTypeOptions: TOption[] = [
  {
    label: "All",
    value: "DEFAULT",
  },
  {
    label: "Multi Send",
    value: "MULTI",
  },
  {
    label: "Separate Send",
    value: "SEPARATE",
  },
];

const TransferHistoryFilters = ({ className }: Props) => {
  const { queryFilters } = useAppSelector((state) => state.transactionHistory);
  const dispatch = useAppDispatch();

  const handleSetFromDate = useCallback(
    (date: Date) => {
      dispatch(
        transactionHistoryActions.handleChangeFilters({
          fromDate: moment(date).format("MM/DD/YYYY"),
          page: 1,
        })
      );
    },
    [dispatch]
  );

  const handleSetToDate = useCallback(
    (date: Date) => {
      dispatch(
        transactionHistoryActions.handleChangeFilters({
          toDate: moment(date).format("MM/DD/YYYY"),
          page: 1,
        })
      );
    },
    [dispatch]
  );

  const handleOnAddressChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      dispatch(transactionHistoryActions.handleChangeFilters({ searchEq: value, page: 1 }));
    },
    [dispatch]
  );

  const handleTransferTypeSelected = useCallback(
    (value: string | number) => {
      dispatch(
        transactionHistoryActions.handleChangeFilters({ transferType: value as string, page: 1 })
      );
    },
    [dispatch]
  );

  return (
    <div
      className={cn(
        "flex gap-4 justify-between items-center py-6 px-7",
        "bg-white border border-grey-20 rounded-lg shadow-panel",
        className
      )}
    >
      <div className="grid grid-cols-4 gap-4 items-center w-full">
        <div className="flex flex-col w-full">
          <Input
            classNameContainer="mt-6"
            placeholder="Search by Address/TxnHash"
            inputSize="sm"
            value={queryFilters.searchEq}
            onChange={handleOnAddressChanged}
            startAdornment={<SearchIcon size={16} color="#A7A8A7" />}
          />
        </div>
        <div>
          <div className="text-sm font-semibold text-grey-80 mb-1">From</div>
          <DatePicker
            date={new Date(queryFilters.fromDate as string)}
            onChange={handleSetFromDate}
            className="w-full"
            size="sm"
            maxDate={new Date(queryFilters.toDate as string)}
          />
        </div>
        <div>
          <div className="text-sm font-semibold text-grey-80 mb-1">To</div>
          <DatePicker
            date={new Date(queryFilters.toDate as string)}
            onChange={handleSetToDate}
            className="w-full"
            size="sm"
            minDate={new Date(queryFilters.fromDate as string)}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-sm font-semibold text-grey-80 mb-1">Transfer Type</div>
          <SelectBox
            customClassName="w-full"
            options={tranferTypeOptions}
            defaultValue={queryFilters.transferType}
            onChange={handleTransferTypeSelected}
            size="sm"
          />
        </div>
      </div>
    </div>
  );
};

export default TransferHistoryFilters;
