import cn from "classnames";
import React, { BaseHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { Column, Row, usePagination, useTable } from "react-table";
import transactionApi from "../../apis/endpoints/transaction";
import { TWalletReceived } from "../../classes/Api";
import { DEFAULT_PAGE_LIMIT } from "../../constants/constant";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import useDebounce from "../../hooks/useDebounce";
import { transactionHistoryActions } from "../../stores/transactionHistorySlice";
import { formatIntBalance } from "../../utils/format";
import { Input } from "../bases/Input";
import Modal from "../bases/Modal";
import Table, { TablePagination } from "../bases/Table";
import Tooltip from "../bases/Tooltip";
import SearchIcon from "../icons/SearchIcon";

export interface IAddressListModalProps extends BaseHTMLAttributes<HTMLDivElement> {
  container: HTMLElement;
  onCloseHandler?: () => void;
  propsState: { id: string };
}

export default function AddressListModal({
  container,
  onCloseHandler,
  propsState: { id },
}: IAddressListModalProps): React.ReactElement {
  const { oneTransactionData } = useAppSelector((state) => state.transactionHistory);
  const dispatch = useAppDispatch();

  const [getOneTransaction, { isFetching, isLoading }] = transactionApi.useLazyGetOneQuery();
  const [search, setSearch] = useState<string>("");
  const searchDebounce = useDebounce(search, 400);

  const dataTable = useMemo(() => {
    if (!oneTransactionData?.extraData) return [];
    const filtered = oneTransactionData.extraData.filter((item) => {
      return item.receiverAddress.toLowerCase().includes(searchDebounce.toLowerCase());
    });

    return filtered;
  }, [oneTransactionData?.extraData, searchDebounce]);

  const numPages = useMemo(() => {
    const controlledPageCount = Math.ceil(dataTable.length / DEFAULT_PAGE_LIMIT);
    return controlledPageCount;
  }, [dataTable]);

  const columns: Column<TWalletReceived>[] = useMemo((): Column<TWalletReceived>[] => {
    return [
      {
        Header: "No.",
        width: "10%",
        Cell: ({ row: { index } }: { row: Row<TWalletReceived> }) => {
          return (
            <Table.Cell className="pl-10" key={index}>
              {index + 1}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Received Address",
        accessor: "receiverAddress",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8" key={index}>
              {value}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell key={index}>
              <Tooltip message={formatIntBalance(value, 18, 2)}>
                {formatIntBalance(value, 18, 2).split(".")[1].length > 2
                  ? `${formatIntBalance(value, 2, 2)}...`
                  : formatIntBalance(value, 2, 2)}
              </Tooltip>
            </Table.Cell>
          );
        },
      },
    ];
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: dataTable || [],
      manualPagination: true,
      initialState: {
        pageSize: DEFAULT_PAGE_LIMIT,
      },
      pageCount: numPages,
      autoResetPage: false,
    },
    usePagination
  );

  const handleNext = useCallback(() => {
    if (canNextPage) {
      nextPage();
    }
  }, [canNextPage, nextPage]);

  const handlePrev = useCallback(() => {
    if (canPreviousPage) {
      previousPage();
    }
  }, [canPreviousPage, previousPage]);

  const handleGotoPage = useCallback(
    (updater: number) => {
      gotoPage(updater);
    },
    [gotoPage]
  );

  const handleOnSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
  }, []);

  useEffect(() => {
    gotoPage(0);
  }, [gotoPage, searchDebounce]);

  useEffect(() => {
    getOneTransaction(id);
  }, [getOneTransaction, id]);

  useEffect(() => {
    return () => {
      dispatch(transactionHistoryActions.clearOneTransactionData());
    };
  }, [dispatch]);

  return (
    <Modal onCloseHandler={onCloseHandler} container={container} className="w-[805px]">
      <div className="flex justify-between items-center w-full">
        <div className="text-grey-80 text-2xl font-extrabold">Address list</div>
        <Input
          inputSize="sm"
          placeholder="Search"
          startAdornment={
            <SearchIcon className="min-w-[16px] min-h-[16px]" size={16} color="#A7A8A7" />
          }
          onChange={handleOnSearchChange}
        />
      </div>
      <div className="mt-8 min-h-[300px] w-full">
        <Table
          {...getTableProps()}
          isLoading={isFetching || isLoading}
          noRecord={!dataTable.length}
        >
          <Table.Head>
            {headerGroups?.map((headerGroup) => (
              <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((col) => {
                  return (
                    <Table.HeadCell
                      {...col.getHeaderProps()}
                      style={{ width: col.width }}
                      className={cn("pl-8", {
                        "!pl-0": col.id === "amount",
                        "!pl-10": col.id === "No.",
                      })}
                    >
                      {col.render("Header")}
                    </Table.HeadCell>
                  );
                })}
              </Table.HeadRow>
            ))}
          </Table.Head>
          <Table.Body {...getTableBodyProps()}>
            {[...rows]
              .slice(
                pageIndex * DEFAULT_PAGE_LIMIT,
                pageIndex * DEFAULT_PAGE_LIMIT + DEFAULT_PAGE_LIMIT
              )
              .map((row, index) => {
                prepareRow(row);
                return (
                  <Table.Row
                    color={"inherit"}
                    {...row.getRowProps()}
                    key={index}
                    className={cn(index % 2 === 0 ? "bg-white" : "bg-[#FDFDFD]")}
                  >
                    {row.cells.map((cell, index) => (
                      <React.Fragment key={index}>{cell.render("Cell", { index })}</React.Fragment>
                    ))}
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <TablePagination
          currentPage={pageIndex + 1}
          pageCount={pageCount}
          nextPage={handleNext}
          prevPage={handlePrev}
          hasNext={canNextPage}
          hasPrev={canPreviousPage}
          className="mt-5"
          gotoPage={handleGotoPage}
        />
      </div>
    </Modal>
  );
}
