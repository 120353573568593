import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import cosplayApi from "../apis/config";
import authReducer from "./authSlice";
import generateWalletReducer from "./generateWalletSlice";
import importCsvReducer from "./importCsvSlice";
import { netMiddleware } from "./middlewares/net";
import modalReducer from "./modalSlice";
import routeReducer from "./routeSlice";
import toastMessageReducer from "./toastMessageSlice";
import transferTokenReducer from "./transferTokenSlice";
import transactionHistoryReducer from "./transactionHistorySlice";
import vestingListReducer from "./vestingListSlice";
import walletReducer from "./walletSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  route: routeReducer,
  toastMessage: toastMessageReducer,
  wallet: walletReducer,

  generateWallet: generateWalletReducer,
  importCsv: importCsvReducer,
  transferToken: transferTokenReducer,
  transactionHistory: transactionHistoryReducer,
  vestingList: vestingListReducer,

  [cosplayApi.reducerPath]: cosplayApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //     ignoredActions: [walletAsyncActions.connect.fulfilled.type],
      // },
    })
      .concat(cosplayApi.middleware)
      .concat(netMiddleware),
});

export type TRootState = ReturnType<typeof rootReducer>;
export type TAppDispatch = typeof store.dispatch;
export default store;
