import classNames from "classnames";
import React, { BaseHTMLAttributes, useCallback } from "react";

interface Props extends BaseHTMLAttributes<HTMLElement> {
  checked?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const RadioButton = ({ checked, disabled, children, onClick, ...props }: Props) => {
  const handleOnClicked = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (disabled) return;
      onClick && onClick(e);
    },
    [disabled, onClick]
  );

  return (
    <div
      className="cursor-pointer select-none flex flex-row gap-2 justify-start items-center"
      onClick={handleOnClicked}
      {...props}
    >
      <div
        className={classNames(
          "w-5 h-5 border border-grey-40 rounded-full flex justify-center items-center",
          { "!border-slate-500": disabled }
        )}
      >
        {checked && (
          <div
            className={classNames("bg-success w-3 h-3 rounded-full", {
              "!bg-slate-500": disabled,
            })}
          ></div>
        )}
      </div>
      {children && (
        <div
          className={classNames("first:ml-0 text-sm text-grey-100", {
            "!text-slate-500": disabled,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default RadioButton;
