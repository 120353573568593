import cn from "classnames";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { TListQuery } from "../../classes/Api";
import { Button } from "../bases/Button";
import DatePicker from "../bases/DatePicker";
import { Input } from "../bases/Input";
import SelectBox, { TOption } from "../bases/SelectBox";
import SearchIcon from "../icons/SearchIcon";

type Props = {
  className?: string;
  queryFilters: TListQuery;
  onSubmitQuery: (params: Partial<TListQuery>) => void;
  actionable?: React.ReactNode;
  batchOptions: TOption[];
  isGenerateWallet?: boolean;
};

const WalletFiltersComponent = ({
  className,
  queryFilters,
  onSubmitQuery,
  actionable,
  batchOptions,
  isGenerateWallet,
}: Props) => {
  const [draftFilter, setDraftFilter] = useState<Partial<TListQuery>>({
    address: queryFilters.address || "",
    batch: queryFilters.batch,
    fromDate: moment(queryFilters.fromDate).format("MM/DD/YYYY"),
    toDate: moment(queryFilters.toDate).format("MM/DD/YYYY"),
  });

  const handleSetFromDate = useCallback((date: Date) => {
    setDraftFilter((prev) => ({
      ...prev,
      fromDate: moment(date).format("MM/DD/YYYY"),
    }));
  }, []);

  const handleSetToDate = useCallback((date: Date) => {
    setDraftFilter((prev) => ({
      ...prev,
      toDate: moment(date).format("MM/DD/YYYY"),
    }));
  }, []);

  const handleOnAddressChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDraftFilter((prev) => ({
      ...prev,
      address: value,
    }));
  }, []);

  const handleOnTokenChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDraftFilter((prev) => ({
      ...prev,
      symbol: value,
    }));
  }, []);

  const handleBatchSelected = useCallback((value: string | number) => {
    setDraftFilter((prev) => ({
      ...prev,
      batch: value,
    }));
  }, []);

  const handleSubmitSearch = useCallback(() => {
    onSubmitQuery(draftFilter);
  }, [draftFilter, onSubmitQuery]);

  return (
    <div
      className={cn(
        "flex gap-4 justify-between items-center py-6 px-7",
        "bg-white border border-grey-20 rounded-lg shadow-panel",
        className
      )}
    >
      <div
        className={`grid ${
          isGenerateWallet ? "grid-cols-4" : "grid-cols-5"
        } gap-4 items-center w-full`}
      >
        <div>
          <div className="text-sm font-semibold text-grey-80 mb-1">From</div>
          <DatePicker
            date={new Date(draftFilter.fromDate as string)}
            onChange={handleSetFromDate}
            className="w-full"
            size="sm"
            maxDate={new Date(draftFilter.toDate as string)}
          />
        </div>
        <div>
          <div className="text-sm font-semibold text-grey-80 mb-1">To</div>
          <DatePicker
            date={new Date(draftFilter.toDate as string)}
            onChange={handleSetToDate}
            className="w-full"
            size="sm"
            minDate={new Date(draftFilter.fromDate as string)}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="text-sm font-semibold text-grey-80 mb-1">Address</div>
          <Input
            placeholder="0x0000"
            inputSize="sm"
            value={draftFilter.address}
            onChange={handleOnAddressChanged}
          />
        </div>
        {isGenerateWallet ? (
          ""
        ) : (
          <div className="flex flex-col w-full">
            <div className="text-sm font-semibold text-grey-80 mb-1">Token</div>
            <Input
              placeholder="COT"
              inputSize="sm"
              value={draftFilter.symbol}
              onChange={handleOnTokenChanged}
            />
          </div>
        )}
        <div className="flex flex-col">
          <div className="text-sm font-semibold text-grey-80 mb-1">Batch</div>
          <SelectBox
            customClassName="w-full"
            options={batchOptions}
            defaultValue={draftFilter.batch}
            onChange={handleBatchSelected}
            size="sm"
          />
        </div>
      </div>
      <Button
        className="mt-6 btn-primary gap-2 min-w-[100px] !px-0"
        size="sm"
        onClick={handleSubmitSearch}
      >
        <SearchIcon size={18} color="currentColor" />
        Search
      </Button>
      {actionable ?? actionable}
    </div>
  );
};

export default WalletFiltersComponent;
