import {
  TTransactionDetailReturn,
  TTransactionHistoryReturn,
  TTransactionListQuery,
} from "../../classes/Api";
import cosplayApi from "../config";

const transactionApi = cosplayApi.injectEndpoints({
  endpoints: (builder) => ({
    transactionList: builder.query<TTransactionHistoryReturn, TTransactionListQuery>({
      query: (params) => ({
        url: "/v1/api/transaction",
        params,
      }),
    }),
    getOne: builder.query<TTransactionDetailReturn, string>({
      query: (id) => ({
        url: `/v1/api/transaction/${id}`,
      }),
    }),
  }),
});

export default transactionApi;
