import * as RadixPopover from "@radix-ui/react-popover";
import cn from "classnames";
import moment from "moment";
import React, { useCallback, useState } from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from "react-datepicker";
import { useMouseClick } from "../../hooks/common";
import { getDateClassName, getYearRange, monthNames } from "../../utils/calendar";
import CalendarIcon from "../icons/CalendarIcon";
import ChevronDownIcon from "../icons/ChevronDownIcon";

export interface IReactDatePicker extends Omit<ReactDatePickerProps, "onChange"> {
  date?: Date | null | undefined;
  onChange: (date: Date) => void;
  children?: React.ReactNode;
  size?: "sm" | "md";
}

export const DatePicker = ({
  date,
  onChange,
  minDate,
  children,
  className,
  size = "md",
  ...props
}: IReactDatePicker) => {
  const [toggleCalendar, setToggleCalendar] = useState<boolean>(false);

  const sizeClassName = cn({
    "text-sm px-4 py-1.5": size === "sm",
    "text-base p-[11px]": size === "md",
  });

  const handleOnChange = useCallback(
    (date: Date | null, event: React.SyntheticEvent<Event> | undefined) => {
      event?.stopPropagation();
      if (!date) return;
      onChange(date);
      setToggleCalendar(false);
    },
    [onChange]
  );

  return (
    <div className={className}>
      <RadixPopover.Root open={toggleCalendar} onOpenChange={setToggleCalendar}>
        <RadixPopover.Trigger className="w-full my-1">
          <div
            className={cn(
              "flex gap-3 justify-between box-border items-center",
              "border rounded-lg cursor-pointer select-none",
              sizeClassName,
              {
                "border-primary-80": toggleCalendar,
              }
            )}
          >
            {children || moment(date).format("MM/DD/YYYY")}
            <CalendarIcon />
          </div>
        </RadixPopover.Trigger>
        <RadixPopover.Content
          side="bottom"
          align="start"
          sideOffset={10}
          className="transition-all duration-250 ease-in z-10"
        >
          <ReactDatePicker
            selected={date}
            onChange={handleOnChange}
            inline
            calendarClassName={cn("date-picker select-none z-[1]")}
            minDate={minDate}
            dayClassName={(d) => getDateClassName(d, date, minDate)}
            renderCustomHeader={({ ...props }) => <CustomHeader {...props} />}
            {...props}
          />
        </RadixPopover.Content>
      </RadixPopover.Root>
    </div>
  );
};

export default DatePicker;

const CustomHeader = ({ date, changeYear, changeMonth }: ReactDatePickerCustomHeaderProps) => {
  const month = date.getMonth();
  const monthName = monthNames[month];
  const year = date.getFullYear();

  const [toggleMonth, setToggleMonth] = useState<boolean>(false);
  const [toggleYear, setToggleYear] = useState<boolean>(false);

  const handleToggleMonth = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setToggleYear(false);
    setToggleMonth((prev) => !prev);
  }, []);

  const handleToggleYear = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setToggleMonth(false);
    setToggleYear((prev) => !prev);
  }, []);

  const handleClickDropdown = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  const handleSelectedMonth = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, month: string) => {
      changeMonth(monthNames.indexOf(month));
      setToggleMonth(false);
    },
    [changeMonth]
  );

  const handleSelectedYear = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, year: number) => {
      changeYear(year);
      setToggleYear(false);
    },
    [changeYear]
  );

  useMouseClick(() => {
    setToggleMonth(false);
    setToggleYear(false);
  });

  return (
    <div className="grid grid-cols-2 w-full gap-4 items-center z-10">
      <div
        className={cn(
          "flex items-center font-semibold gap-3 px-2 cursor-pointer relative select-none",
          "w-full justify-end"
        )}
        onClick={handleToggleMonth}
      >
        {monthName}{" "}
        <ChevronDownIcon
          size={14}
          className={cn("transition-transform duration-250 ease-in-out", {
            "rotate-180": toggleMonth,
          })}
        />
        <div
          className={cn(
            "absolute h-52 max-h-[200px] w-28 scrollbar-none overflow-y-auto",
            "bg-white rounded shadow-character-slot py-3 top-5 z-10",
            "transition-all duration-250 ease-in-out",
            { hidden: !toggleMonth }
          )}
          onClick={handleClickDropdown}
        >
          {monthNames.map((m, i) => {
            return (
              <div
                key={i}
                className={cn("text-left px-5 py-3 hover:bg-slate-100")}
                onClick={(e) => handleSelectedMonth(e, m)}
              >
                {m}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={cn(
          "flex items-center font-semibold gap-3 px-2 cursor-pointer relative select-none",
          "w-full justify-start"
        )}
        onClick={handleToggleYear}
      >
        {year}{" "}
        <ChevronDownIcon
          size={14}
          className={cn("transition-transform duration-250 ease-in-out", {
            "rotate-180": toggleYear,
          })}
        />
        <div
          className={cn(
            "absolute h-52 max-h-[200px] w-28 scrollbar-none overflow-y-auto",
            "bg-white rounded shadow-character-slot py-3 top-5 z-10",
            "transition-all duration-250 ease-in-out",
            { hidden: !toggleYear }
          )}
          onClick={handleClickDropdown}
        >
          {getYearRange().map((y, i) => {
            return (
              <div
                key={i}
                className={cn("text-left px-5 py-3 hover:bg-slate-100")}
                onClick={(e) => handleSelectedYear(e, y)}
              >
                {y}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
