import cn from "classnames";
import { useCallback } from "react";
import { networkOptions } from "../../config/network";
import { SUPPORTED_WALLETS } from "../../config/wallet";
import { switchToNetwork } from "../../functions/network";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import { useConnectWallet } from "../../hooks/useConnectWallet";
import { modalSliceActions } from "../../stores/modalSlice";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { walletActions } from "../../stores/walletSlice";
import StorageUtils, { STORAGE_KEYS } from "../../utils/storage";
import Modal from "../bases/Modal";

export interface ISwithNetworkModalProps {
  container: HTMLElement;
  onCloseHandler?: () => void;
  propsState?: {
    connectNow: boolean;
  };
}

const SwitchNetworkModal: React.FC<ISwithNetworkModalProps> = ({
  container,
  propsState,
  onCloseHandler,
}) => {
  const walletState = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();
  const { tryActivation } = useConnectWallet();

  const handleSwitchNetwork = useCallback(
    async (chainId: number) => {
      if (!walletState.isConnected) {
        dispatch(walletActions.setChainId(chainId));
        return dispatch(modalSliceActions.shiftFromQueue());
      }

      if (!window.ethereum) {
        return dispatch(
          toastMessageActions.addToastMessage({
            title: "No wallet detected",
            type: "danger",
          })
        );
      }

      const connector = await SUPPORTED_WALLETS.METAMASK.connector;
      const conn = typeof connector === "function" ? await connector() : connector;
      const provider = await conn?.getProvider();
      if (provider && chainId) {
        return switchToNetwork({
          provider,
          chainId,
        })
          .then(() => {
            const selectedAddress = window.ethereum?.selectedAddress;
            dispatch(walletActions.setChainId(chainId));
            dispatch(walletActions.setAccount(selectedAddress || ""));
            StorageUtils.setAccount(selectedAddress || "");
            return dispatch(modalSliceActions.shiftFromQueue());
          })
          .catch(({ code, name }) => {
            if (code === 4001 || name === "UserRejectedRequestError") {
              dispatch(
                toastMessageActions.addToastMessage({
                  title: "The connection to MetaMask has been canceled by the user",
                  type: "danger",
                })
              );
            }
            localStorage.removeItem(STORAGE_KEYS.IS_CONNECTED);
          });
      }
    },
    [dispatch, walletState.isConnected]
  );

  const handleSelectNetworkButtonClicked = useCallback(
    async (chainId: number) => {
      if (propsState?.connectNow) {
        await tryActivation(chainId);
      } else {
        await handleSwitchNetwork(chainId);
      }
    },
    [handleSwitchNetwork, propsState?.connectNow, tryActivation]
  );

  return (
    <Modal onCloseHandler={onCloseHandler} container={container} className="w-[549px]">
      <div className="text-2xl font-extrabold">Select network</div>
      <div className="mt-6 w-full">
        <div className="w-full flex flex-col gap-5">
          {networkOptions.map(({ roundedIcon, name, chainId }) => {
            return (
              <div
                key={chainId}
                className={cn(
                  "w-full flex items-center justify-between bg-[#FDFDFD] cursor-pointer",
                  "border border-grey-20 rounded-xl px-5 py-3 text-grey-100",
                  "hover:bg-grey-20",
                  {
                    "text-primary-80 !border-primary-80": chainId === walletState.chainId,
                  }
                )}
                onClick={() => {
                  handleSelectNetworkButtonClicked(chainId);
                }}
              >
                {name}
                <div className="w-10 h-10">{roundedIcon}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default SwitchNetworkModal;
