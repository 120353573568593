import React from "react";
import { IconProps } from "../../classes/Icon";

const VestingIcon: React.FC<IconProps> = ({ size = "24", color = "#707070", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <g fill={color} clipPath="url(#clip0_302_14)">
        <path d="M15.777 10.554a5.77 5.77 0 015.762 5.763 5.77 5.77 0 01-5.762 5.763 5.77 5.77 0 01-5.763-5.763 5.77 5.77 0 015.763-5.763zm0-1.92a7.683 7.683 0 00-7.683 7.683 7.683 7.683 0 107.683-7.683zM6.768 0C3.328 0 .54 1.358.54 3.034v1.62c0 1.676 2.788 3.034 6.228 3.034 3.439 0 6.228-1.358 6.228-3.034v-1.62C12.996 1.358 10.207 0 6.768 0zm0 1.26c3.033 0 4.968 1.164 4.968 1.774 0 .61-1.935 1.774-4.968 1.774S1.8 3.643 1.8 3.034c0-.61 1.935-1.774 4.968-1.774zM6.768 10.125c1.152 0 2.23-.154 3.156-.419a8.785 8.785 0 00-2.135 2.839c-.33.026-.672.042-1.021.042-3.44 0-6.228-1.36-6.228-3.035V7.648c0-.03.023-.059.054-.062.015-.004.03.003.042.02-.008 0 0 .021.042.125.603 1.369 3.1 2.394 6.09 2.394zM6.94 16.317c0 .373.023.738.072 1.099-.08.004-.161.004-.244.004-3.44 0-6.228-1.357-6.228-3.03v-1.91c0-.027.023-.054.054-.058.025-.003.041.012.053.03-.015-.02-.03-.03.031.116.603 1.367 3.1 2.394 6.09 2.394.092 0 .188 0 .28-.009-.074.446-.108.9-.108 1.364zM9.056 22.044c-.706.14-1.478.212-2.288.212-3.44 0-6.228-1.356-6.228-3.034v-1.906c0-.03.023-.054.054-.057a.045.045 0 01.053.03c-.015-.019-.034-.038.031.112.603 1.371 3.1 2.396 6.09 2.396.295 0 .59-.011.875-.03a8.72 8.72 0 001.413 2.277z"></path>
        <path d="M17.228 16.459c.19-.065.178-.144.315-.237.137-.094.25-.198.34-.314.088-.114.154-.237.197-.369.042-.132.064-.27.064-.413 0-.252-.043-.48-.128-.68a1.27 1.27 0 00-.401-.514 1.948 1.948 0 00-.7-.327 3.62 3.62 0 00-.78-.109v-.668h-.97v.662H13.54v.5c0 .146-.01.235.132.266.012.001.03.004.053.009l.126.023c.062.01-.017.025.09.045v4.457c-.107.018-.028.033-.09.043-.061.011-.103.02-.127.023l-.052.01c-.142.03-.132.118-.132.265v.5h1.624v.62h.97v-.62h.125c.357 0 .673-.046.951-.137.278-.09.51-.215.7-.375.19-.16.334-.35.435-.571.099-.221.148-.462.148-.722 0-.723-.422-1.177-1.266-1.367zm-2.144-2.085h.8c.386 0 .669.068.85.204.181.136.272.363.272.683 0 .309-.101.534-.302.675-.201.142-.495.212-.883.212h-.737v-1.774zm2.08 3.787a.755.755 0 01-.186.297.925.925 0 01-.345.204c-.143.051-.32.077-.533.077h-1.016v-1.8h1.012c.201 0 .373.02.514.06.141.039.258.096.348.171.09.076.157.166.199.272a.942.942 0 01.064.359c0 .127-.02.247-.057.36z"></path>
      </g>
      <defs>
        <clipPath id="clip0_302_14">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default VestingIcon;
