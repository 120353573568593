import { BigNumber as BN } from "bignumber.js";
import { BigNumber, ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";

export const shortenAddress = (string: string, start?: number, end?: number): string => {
  if (typeof string !== "string") return "";
  return string.slice(0, start || 6) + "..." + string.slice(-(end || 4));
};

export const formatIntBalance = (num?: string | number, decimal = 4, fillZero = 0) => {
  if (!num) return "0";
  let balanceSplit = String(num).split(".");

  balanceSplit[1] = balanceSplit.length > 1 ? balanceSplit[1].slice(0, decimal) : "0";
  num = new BN(`${balanceSplit[0]}.${balanceSplit[1]}`).toFixed();
  balanceSplit = String(num).split(".");

  if (balanceSplit.length === 1 || balanceSplit[1] === "0") {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: fillZero,
    });

    return formatter.format(Number(balanceSplit[0]) || 0);
  } else {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
    });
    return `${formatter.format(Number(balanceSplit[0]))}.${balanceSplit[1]}`;
  }
};

export const shortenString = (str?: string, maxLength = 30, isNumber?: boolean) => {
  if (!str) return str;
  if (str.length > maxLength) {
    if (isNumber) {
      const replaceAll = str.replaceAll(",", "");
      let num = replaceAll.slice(0, maxLength);
      if (num.includes(".")) {
        num = replaceAll.slice(0, maxLength + 1);
      }
      return `${formatIntBalance(num)}...`;
    }
    return `${str.slice(0, maxLength)}...`;
  }
  return str;
};

export function toFixedDecimal(val: string | number, decimals = 18): string {
  val = String(val);
  const split = val.split(".");
  if (split.length > 1) {
    if (decimals) {
      return split[0] + "." + split[1].substr(0, decimals);
    } else {
      return split[0];
    }
  } else {
    return val;
  }
}

export const formatBalance = (value: ethers.BigNumberish, decimals = 18, maxFraction = 0) => {
  let formatted = ethers.utils.formatUnits(
    Number(value) ? toFixedDecimal(new BN(value.toString()).toFixed(), 0) : BigNumber.from("0"),
    decimals
  );
  formatted = new BN(formatted).toFixed();

  if (maxFraction > 0) {
    const split = formatted.split(".");
    if (split.length > 1) {
      return split[0] + "." + split[1].substr(0, maxFraction);
    }
  }
  return formatted;
};

export const parseBalance = (value: string, decimals = 18): BigNumber => {
  value = new BN(value || "0").isNaN() ? "0" : new BN(value || "0").toFixed();
  return parseUnits(value || "0", decimals);
};

export const parseToBigNumber = (value: string | number, decimals = 18) => {
  const isNaN = new BN(value).isNaN();
  return new BN(isNaN ? 0 : value).times(new BN(10).pow(decimals));
};

export const formatTimeCount = (sc: number) => {
  const time = Math.floor(sc);
  const hours = Math.floor(time / 3600);
  const minute = Math.floor((time - hours * 3600) / 60);
  const second = time - hours * 3600 - minute * 60;
  return `${hours < 10 ? `0${hours}` : hours}:${minute < 10 ? `0${minute}` : minute}:${
    second < 10 ? `0${second}` : second
  }`;
};

export const formatReadingTimeRequired = (sc: number) => {
  const time = Math.floor(sc);
  const hours = Math.floor(time / 3600);
  const minute = Math.floor((time - hours * 3600) / 60);
  const second = time - hours * 3600 - minute * 60;
  return `${minute < 10 ? `${minute} mins` : minute} ${`${second} secs`}`;
};

export const formatWidth = (cur: number, total: number) => {
  if (!cur && !total) return 0;
  if (cur && !total) return 100;
  return (cur / total) * 100;
};

export const formatTimestampToDateTime = (sc: string | undefined) => {
  const date = new Date(Number(sc) * 1000);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};

export const formatTimestampToDate = (sc: string | undefined) => {
  const date = new Date(Number(sc) * 1000);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${month}/${day}/${year}`;
};
