import React, { BaseHTMLAttributes } from "react";
import { SUPPORTED_NETWORKS } from "../../config/network";
import { useWeb3Activity } from "../../hooks/useWeb3Activity";
import { shortenString } from "../../utils/format";
import Modal from "../bases/Modal";

export interface IDataTransferErrorModalProps extends BaseHTMLAttributes<HTMLDivElement> {
  container: HTMLElement;
  onCloseHandler?: () => void;
  propsState: {
    message: string[];
  };
}

export default function DataTransferErrorModal({
  container,
  onCloseHandler,
  propsState: { message },
}: IDataTransferErrorModalProps): React.ReactElement {
  const { chainId } = useWeb3Activity();

  return (
    <Modal onCloseHandler={onCloseHandler} container={container} className="w-[500px]">
      <div className="text-2xl font-extrabold">Error Message</div>
      <div className="mt-6 w-full overflow-auto text-danger">
        The following transaction failed to execute:
      </div>
      <div className="mt-1 w-full max-h-[50vh] scrollbar-thin overflow-auto">
        {message.map((msg, index) => (
          <div className="text-danger" key={index}>
            <a
              href={`${SUPPORTED_NETWORKS[chainId].blockExplorerUrls[0]}/tx/${msg}`}
              target="_blank"
              rel="noreferrer"
            >
              - <span className="underline font-medium">{shortenString(msg, 40, false)}</span>
            </a>
          </div>
        ))}
      </div>
    </Modal>
  );
}
