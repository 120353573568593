import React from "react";
import { IconProps } from "../../classes/Icon";

const ArrowLeftIcon: React.FC<IconProps> = ({ size = "20", color = "#E3E3E3", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M14.7329 3.44344V1.71799C14.7329 1.56844 14.5611 1.48585 14.445 1.57737L4.38249 9.43674C4.29699 9.50324 4.22783 9.58837 4.18023 9.68566C4.13264 9.78296 4.10791 9.88982 4.10791 9.99812C4.10791 10.1064 4.13264 10.2133 4.18023 10.3106C4.22783 10.4079 4.29699 10.493 4.38249 10.5595L14.445 18.4189C14.5633 18.5104 14.7329 18.4278 14.7329 18.2783V16.5528C14.7329 16.4434 14.6816 16.3385 14.5968 16.2716L6.56106 9.99924L14.5968 3.72469C14.6816 3.65772 14.7329 3.55282 14.7329 3.44344Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowLeftIcon;
