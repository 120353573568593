import React from "react";
import { IconProps } from "../../classes/Icon";

const ArrowRightIcon: React.FC<IconProps> = ({ size = "20", color = "#828382", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M15.663 9.43802L5.60045 1.57865C5.57415 1.55795 5.54255 1.54508 5.50928 1.54153C5.476 1.53798 5.4424 1.54389 5.41234 1.55857C5.38227 1.57326 5.35695 1.59613 5.33928 1.62455C5.32163 1.65298 5.31235 1.68582 5.3125 1.71927V3.44472C5.3125 3.5541 5.36384 3.659 5.44867 3.72597L13.4844 10.0005L5.44867 16.2751C5.36162 16.342 5.3125 16.447 5.3125 16.5563V18.2818C5.3125 18.4313 5.48439 18.5138 5.60045 18.4223L15.663 10.563C15.7485 10.4963 15.8177 10.411 15.8653 10.3135C15.9128 10.216 15.9376 10.109 15.9376 10.0005C15.9376 9.89205 15.9128 9.78502 15.8653 9.68755C15.8177 9.59007 15.7485 9.50473 15.663 9.43802Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowRightIcon;
