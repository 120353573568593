import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TModalData } from "../../classes/Modal";

export type TModalState = {
  queue: Array<TModalData>;
};

const initialState: TModalState = {
  queue: [],
};

const _addToQueue: CaseReducer<TModalState, PayloadAction<TModalData>> = (state, action) => {
  if (state.queue.indexOf(action.payload) !== -1) return;
  state.queue.push(action.payload);
};

const _switchTo: CaseReducer<TModalState, PayloadAction<TModalData>> = (state, action) => {
  state.queue.shift();
  if (state.queue.indexOf(action.payload) !== -1) return;
  state.queue.push(action.payload);
};

const _shiftFromQueue: CaseReducer<TModalState> = (state) => {
  state.queue.shift();
};

const _clearAll: CaseReducer<TModalState> = (state) => {
  state.queue = [];
};

const modalSlice = createSlice({
  name: "modalSlice",
  initialState,
  reducers: {
    addToQueue: _addToQueue,
    switchTo: _switchTo,
    shiftFromQueue: _shiftFromQueue,
    clearAll: _clearAll,
  },
});

export default modalSlice.reducer;
export const modalSliceActions = modalSlice.actions;
