import * as RadixPopover from "@radix-ui/react-popover";
import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

type Props = {
  message: string | React.ReactNode;
  children: React.ReactNode;
  side?: "left" | "right" | "top" | "bottom";
  align?: "center" | "start" | "end";
  positionX?: "top" | "bottom";
  className?: string;
  hidden?: boolean;
};

const Tooltip: React.FC<Props> = ({
  message,
  children,
  side = "top",
  align = "center",
  className,
  hidden,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [dataSet, setDataSet] = useState<DOMStringMap>({
    ...ref.current?.dataset,
  });
  const [toggle, setToggle] = useState<boolean>(false);

  const handleTitleMouseEnter = useCallback(() => {
    setToggle(true);
    if (!ref.current?.dataset) return;
    setDataSet({
      side: ref.current.dataset.side,
      align: ref.current.dataset.align,
    });
  }, []);

  const handleTitleMouseLeave = useCallback(() => {
    setToggle(false);
  }, []);

  const contentClassName = cn({
    "before:left-1/2":
      dataSet?.align === "center" && (dataSet.side === "top" || dataSet.side === "bottom"),
    "before:left-3":
      dataSet.align === "start" && (dataSet.side === "top" || dataSet.side === "bottom"),
    "before:right-3":
      dataSet.align === "end" && (dataSet.side === "top" || dataSet.side === "bottom"),
    "before:-bottom-1": dataSet.side === "top",
    "before:-top-1": dataSet.side === "bottom",
    "before:-right-1": dataSet.side === "left",
    "before:-left-1": dataSet.side === "right",
  });

  useEffect(() => {
    if (!ref.current?.dataset) return;
    setDataSet({
      side: ref.current.dataset.side,
      align: ref.current.dataset.align,
    });
  }, []);

  return (
    <div className={cn("w-fit group", className)}>
      <RadixPopover.Root open={toggle}>
        <RadixPopover.Trigger
          className="w-fit outline-none"
          onMouseMove={handleTitleMouseEnter}
          onMouseLeave={handleTitleMouseLeave}
        >
          {children}
        </RadixPopover.Trigger>
        <RadixPopover.Content
          ref={ref}
          side={side}
          align={align}
          sideOffset={10}
          className={cn(
            "transition-transform duration-150 ease-in z-10 text relative invisible group-hover:visible",
            "rounded-sm bg-grey-100 px-2 py-2 text-white text-sm outline-none",
            "before:w-2.5 before:h-2.5 before:bg-grey-100 before:rotate-45 before:absolute before:z-0",
            contentClassName,
            {
              "opacity-0": hidden,
            }
          )}
        >
          {message}
        </RadixPopover.Content>
      </RadixPopover.Root>
    </div>
  );
};

export default Tooltip;
