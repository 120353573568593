import cn from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import { Column, usePagination, useTable } from "react-table";
import dataTransferApi from "../../apis/endpoints/dataTransfer";
import { TListQuery, TWalletImported } from "../../classes/Api";
import { Button } from "../../components/bases/Button";
import Table, { TablePagination } from "../../components/bases/Table";
import Tooltip from "../../components/bases/Tooltip";
import DefaultLayout from "../../components/layout/DefaultLayout";
import WalletFiltersComponent from "../../components/filters/WalletFiltersComponent";
import { DEFAULT_BATCH_OPTION } from "../../constants/constant";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import useDebounce from "../../hooks/useDebounce";
import { importCsvActions } from "../../stores/importCsvSlice";
import { modalSliceActions } from "../../stores/modalSlice";
import { formatIntBalance } from "../../utils/format";
import { removeEmptyProperties } from "../../utils/utils";

const ImportCSV = () => {
  const { queryFilters, totalItems, walletAddressList, updater, batchList } = useAppSelector(
    (state) => state.importCsv
  );
  const dispatch = useAppDispatch();

  const [getWalletImportedList, importWalletResponse] = dataTransferApi.useLazyGetListQuery();
  const [getListBatch] = dataTransferApi.useLazyGetListBatchQuery();

  const numPages = useMemo(() => {
    const controlledPageCount = Math.ceil(totalItems / queryFilters.take);
    return controlledPageCount;
  }, [totalItems, queryFilters.take]);

  const filtersDebounce = useDebounce(queryFilters, 400);

  const columns: Column<TWalletImported>[] = useMemo((): Column<TWalletImported>[] => {
    return [
      {
        Header: "No.",
        accessor: "id",
        width: "10%",
        Cell: ({ index, row }) => {
          return (
            <Table.Cell className="pl-14" key={index}>
              {row.index + (queryFilters.page - 1) * queryFilters.take + 1}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Address",
        accessor: "address",
        width: "30%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8" key={index}>
              {value}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        width: "15%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell
              className="pl-8 text-ellipsis overflow-hidden whitespace-nowrap"
              key={index}
            >
              <Tooltip message={formatIntBalance(value, 18, 2)}>
                {formatIntBalance(value, 18, 2).split(".")[1].length > 2
                  ? `${formatIntBalance(value, 2, 2)}...`
                  : formatIntBalance(value, 2, 2)}
              </Tooltip>
            </Table.Cell>
          );
        },
      },
      {
        Header: "Token",
        accessor: "symbol",
        width: "10%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              {value.toUpperCase()}
            </Table.Cell>
          );
        },
      },
      {
        Header: "Batch",
        accessor: "batch",
        width: "15%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              {value}
            </Table.Cell>
          );
        },
      },
    ];
  }, [queryFilters.page, queryFilters.take]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: walletAddressList,
      manualPagination: true,
      initialState: {
        pageSize: queryFilters.take,
        pageIndex: queryFilters.page - 1,
      },
      pageCount: numPages,
      autoResetPage: false,
    },
    usePagination
  );

  const handleNext = useCallback(() => {
    if (canNextPage) {
      dispatch(importCsvActions.handleChangeFilters({ page: queryFilters.page + 1 }));
      nextPage();
    }
  }, [canNextPage, dispatch, nextPage, queryFilters.page]);

  const handlePrev = useCallback(() => {
    if (canPreviousPage) {
      dispatch(importCsvActions.handleChangeFilters({ page: Number(queryFilters.page) - 1 }));
      previousPage();
    }
  }, [canPreviousPage, dispatch, previousPage, queryFilters.page]);

  const handleGotoPage = useCallback(
    (updater: number) => {
      dispatch(importCsvActions.handleChangeFilters({ page: updater + 1 }));
      gotoPage(updater);
    },
    [dispatch, gotoPage]
  );

  const handleSubmitSearch = useCallback(
    (params: Partial<TListQuery>) => {
      dispatch(
        importCsvActions.handleChangeFilters({
          ...params,
          page: 1,
        })
      );
      gotoPage(0);
    },
    [dispatch, gotoPage]
  );

  const handleImportButtonClicked = useCallback(() => {
    dispatch(modalSliceActions.addToQueue({ type: "popup/upload-file" }));
  }, [dispatch]);

  useEffect(() => {
    getListBatch(undefined);
  }, [getListBatch, updater]);

  useEffect(() => {
    const params: Partial<TListQuery> = removeEmptyProperties({
      ...filtersDebounce,
      batch: filtersDebounce.batch === DEFAULT_BATCH_OPTION[0].value ? "" : filtersDebounce.batch,
    });
    getWalletImportedList(params as TListQuery);
  }, [filtersDebounce, getWalletImportedList, updater]);

  useEffect(() => {
    if (importWalletResponse.error) {
      dispatch(importCsvActions.clearAddressList());
    }
  }, [dispatch, importWalletResponse.error]);

  useEffect(() => {
    return () => {
      dispatch(importCsvActions.clearState());
    };
  }, [dispatch]);

  const renderTableBody = useCallback(() => {
    return (
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <Table.Row
              color={"inherit"}
              {...row.getRowProps()}
              key={index}
              className={cn(index % 2 === 0 ? "bg-white" : "bg-[#FDFDFD]")}
            >
              {row.cells.map((cell, index) => (
                <React.Fragment key={index}>{cell.render("Cell", { index })}</React.Fragment>
              ))}
            </Table.Row>
          );
        })}
      </Table.Body>
    );
  }, [getTableBodyProps, rows, prepareRow]);

  return (
    <DefaultLayout title="Import CSV">
      <WalletFiltersComponent
        queryFilters={queryFilters}
        onSubmitQuery={handleSubmitSearch}
        batchOptions={batchList}
        actionable={
          <Button
            size="sm"
            className="mt-6 btn-secondary min-w-[133px] !px-0 ml-10"
            onClick={handleImportButtonClicked}
          >
            Import CSV
          </Button>
        }
      />
      <div className="min-h-[300px] mt-4">
        <Table
          {...getTableProps()}
          isLoading={importWalletResponse?.isLoading || importWalletResponse?.isFetching}
          noRecord={!walletAddressList.length}
        >
          <Table.Head>
            {headerGroups?.map((headerGroup) => (
              <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((col) => {
                  return (
                    <Table.HeadCell
                      {...col.getHeaderProps()}
                      style={{ width: col.width }}
                      className={cn("pl-8", {
                        "text-center": col.id === "symbol" || col.id === "batch",
                        "!pl-14": col.id === "id",
                      })}
                    >
                      {col.render("Header")}
                    </Table.HeadCell>
                  );
                })}
              </Table.HeadRow>
            ))}
          </Table.Head>
          {renderTableBody()}
        </Table>
        <TablePagination
          currentPage={pageIndex + 1}
          pageCount={pageCount}
          nextPage={handleNext}
          prevPage={handlePrev}
          hasNext={canNextPage}
          hasPrev={canPreviousPage}
          className="mt-5"
          gotoPage={handleGotoPage}
        />
      </div>
    </DefaultLayout>
  );
};

export default ImportCSV;
