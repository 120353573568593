import { TTokenListQuery, TTokenListReturn } from "../../classes/Api";
import cosplayApi from "../config";

const tokenApi = cosplayApi.injectEndpoints({
  endpoints: (builder) => ({
    getListToken: builder.query<TTokenListReturn, TTokenListQuery>({
      query: (params) => ({
        url: "/v1/api/token",
        params,
      }),
    }),
  }),
});

export default tokenApi;
