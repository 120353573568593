import {
  TBatchListReturn,
  TListQuery,
  TWalletImportedReturn,
  TWalletRandomReturn,
} from "../../classes/Api";
import cosplayApi from "../config";

const dataTransferApi = cosplayApi.injectEndpoints({
  endpoints: (builder) => ({
    upload: builder.mutation<unknown, FormData>({
      query: (args) => ({
        url: "/v1/api/data-transfer/import_csv",
        method: "POST",
        body: args,
      }),
    }),

    getList: builder.query<TWalletImportedReturn, TListQuery>({
      query: (params) => ({
        url: "/v1/api/data-transfer",
        params,
      }),
    }),

    getFullList: builder.query<TWalletImportedReturn, Partial<TListQuery>>({
      query: (params) => ({
        url: "/v1/api/data-transfer",
        params,
        cache: "no-cache",
      }),
    }),

    getListBatch: builder.query<TBatchListReturn, undefined>({
      query: () => ({
        url: "/v1/api/data-transfer/batches",
      }),
    }),

    getRandomList: builder.query<
      TWalletRandomReturn,
      Partial<TListQuery> & { randomNumber: number | string }
    >({
      query: (params) => ({
        url: "/v1/api/data-transfer/random",
        params,
      }),
    }),
  }),
});

export default dataTransferApi;
