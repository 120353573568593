import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import ToastMessage from "./components/commons/ToastMessage";
import Web3ProviderNetwork from "./components/commons/Web3ProviderNetwork";
import Web3ReactManager from "./components/commons/Web3ReactManager";
import PrivateRoute from "./components/layout/PrivateRoute";
import { useAppSelector, useModalQueue } from "./hooks/common";
import { GenerateWallet } from "./pages/GenerateWallet";
import ImportCSV from "./pages/ImportCSV";
import Login from "./pages/Login";
import TransferHistory from "./pages/TransferHistory";
import TransferToken from "./pages/TransferToken";
import GrantRole from "./pages/GrantRole";
import AddVestingWallet from "./pages/AddVestingWallet";
// import VestingList from "./pages/Vesting List";
import store from "./stores";
import { ROUTES } from "./stores/routeSlice";
import TransactionUpdater, { BlockNumberUpdater } from "./stores/walletSlice/updater";
import "./styles/globals.css";
import RemoveVesting from "./pages/RemoveVesting";

export const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc): Web3Provider => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
};

function App(): JSX.Element {
  const modalState = useAppSelector((state) => state.modal);
  const modalQueue = useModalQueue(modalState.queue);

  return (
    <React.Fragment>
      <ToastMessage container={document.body} />
      {modalQueue}
      <BlockNumberUpdater />
      <TransactionUpdater />
      <Switch>
        <PrivateRoute path={ROUTES.GENERATE_WALLET} exact component={GenerateWallet} />
        <PrivateRoute path={ROUTES.IMPORT_CSV} exact component={ImportCSV} />
        <PrivateRoute path={ROUTES.SUB_TRANSFER} exact component={TransferToken} />
        <PrivateRoute path={ROUTES.SUB_HISTORY} exact component={TransferHistory} />
        <PrivateRoute path={ROUTES.GRANT_ROLE} exact component={GrantRole} />
        <PrivateRoute path={ROUTES.ADD_VESTING_WALLET} exact component={AddVestingWallet} />
        <PrivateRoute path={ROUTES.REMOVE_VESTING} exact component={RemoveVesting} />
        {/*<PrivateRoute path={ROUTES.VESTING_LIST} exact component={VestingList} />*/}
        <Route path={ROUTES.LOGIN} exact component={Login} />
        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  );
}

function Root() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <Web3ReactManager>
            <Router>
              <App />
            </Router>
          </Web3ReactManager>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
}

export default Root;
