import cn from "classnames";
import React from "react";

type SpinnerProps = {
  size?: "large" | "medium" | "small";
  variant?: "primary" | "secondary";
};

const Spinner: React.FC<SpinnerProps> = ({ size = "large", variant = "primary" }) => {
  return (
    <span
      className={cn(
        "flex items-center justify-center",
        { "h-[24px] w-[24px]": size === "large" },
        { "h-[20px] w-[20px]": size === "medium" },
        { "h-[16px] w-[16px]": size === "small" }
      )}
    >
      <span className="flex items-center justify-center relative w-full h-full">
        <span
          className={cn(
            "animate-ring border-2 h-4/5 w-4/5 rounded-full border-transparent",
            { "border-t-grey-0": variant === "primary" },
            { "border-t-violet-60": variant === "secondary" }
          )}
        />
      </span>
    </span>
  );
};

export default Spinner;
