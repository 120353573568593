import cn from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import { Column, usePagination, useTable } from "react-table";
import transactionApi from "../../apis/endpoints/transaction";
import { TTransactionListQuery, TTxnData } from "../../classes/Api";
import Table, { TablePagination } from "../../components/bases/Table";
import Tooltip from "../../components/bases/Tooltip";
import EyeIcon from "../../components/icons/EyeIcon";
import DefaultLayout from "../../components/layout/DefaultLayout";
import TransferHistoryFilters from "../../components/filters/TransferHistoryFilters";
import { SUPPORTED_NETWORKS } from "../../config/network";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import useDebounce from "../../hooks/useDebounce";
import { modalSliceActions } from "../../stores/modalSlice";
import { transactionHistoryActions } from "../../stores/transactionHistorySlice";
import { formatIntBalance, shortenAddress } from "../../utils/format";
import { removeEmptyProperties } from "../../utils/utils";

const TransferHistory = () => {
  const { queryFilters, totalItems, transactionList, updater } = useAppSelector(
    (state) => state.transactionHistory
  );
  const { chainId } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();

  const [getListTransactionHistory, { isFetching, isLoading }] =
    transactionApi.useLazyTransactionListQuery();

  const filtersDebounce = useDebounce(queryFilters, 400);

  const handleEyeIconClicked = useCallback(
    (id: string | number) => {
      dispatch(modalSliceActions.addToQueue({ type: "popup/address-list", propsState: { id } }));
    },
    [dispatch]
  );

  const numPages = useMemo(() => {
    const controlledPageCount = Math.ceil(totalItems / queryFilters.take);
    return controlledPageCount;
  }, [totalItems, queryFilters.take]);

  const columns: Column<TTxnData>[] = useMemo((): Column<TTxnData>[] => {
    return [
      {
        Header: "",
        accessor: "id",
        width: "5%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8" key={index}>
              <span onClick={() => handleEyeIconClicked(value)} className="cursor-pointer">
                <EyeIcon />
              </span>
            </Table.Cell>
          );
        },
      },
      {
        Header: "txnHash",
        accessor: "txHash",
        width: "20%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8" key={index}>
              <Tooltip message={value}>
                <a
                  href={`${SUPPORTED_NETWORKS[chainId].blockExplorerUrls[0]}/tx/${value}`}
                  target="_blank"
                  className="text-secondary underline"
                >
                  {shortenAddress(value, 10, 4)}
                </a>
              </Tooltip>
            </Table.Cell>
          );
        },
      },
      {
        Header: "From",
        accessor: "fromAddressWallet",
        width: "20%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              <Tooltip message={value.walletAddress}>
                {shortenAddress(value.walletAddress, 10, 4)}
              </Tooltip>
            </Table.Cell>
          );
        },
      },
      {
        Header: "To",
        accessor: "tokenAddress",
        width: "20%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              <Tooltip message={value}>{shortenAddress(value, 10, 4)}</Tooltip>
            </Table.Cell>
          );
        },
      },
      {
        Header: "Total Amount",
        accessor: "amount",
        width: "15%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell
              className="pl-8 text-ellipsis overflow-hidden whitespace-nowrap"
              key={index}
            >
              <Tooltip message={formatIntBalance(value, 18, 2)}>
                {formatIntBalance(value, 18, 2).split(".")[1].length > 2
                  ? `${formatIntBalance(value, 2, 2)}...`
                  : formatIntBalance(value, 2, 2)}
              </Tooltip>
            </Table.Cell>
          );
        },
      },
      {
        Header: "Transfer Type",
        accessor: "transferType",
        width: "20%",
        Cell: ({ cell: { value }, index }) => {
          return (
            <Table.Cell className="pl-8 text-center" key={index}>
              {value === "MULTI" ? "Multi Send" : "Separate Send"}
            </Table.Cell>
          );
        },
      },
    ];
  }, [chainId, handleEyeIconClicked]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: transactionList,
      manualPagination: true,
      initialState: {
        pageSize: queryFilters.take,
        pageIndex: queryFilters.page - 1,
      },
      pageCount: numPages,
      autoResetPage: false,
    },
    usePagination
  );

  const handleNext = useCallback(() => {
    if (canNextPage) {
      dispatch(transactionHistoryActions.handleChangeFilters({ page: queryFilters.page + 1 }));
      nextPage();
    }
  }, [canNextPage, dispatch, nextPage, queryFilters]);

  const handlePrev = useCallback(() => {
    if (canPreviousPage) {
      dispatch(transactionHistoryActions.handleChangeFilters({ page: queryFilters.page - 1 }));
      previousPage();
    }
  }, [canPreviousPage, dispatch, previousPage, queryFilters]);

  const handleGotoPage = useCallback(
    (updater: number) => {
      dispatch(transactionHistoryActions.handleChangeFilters({ page: updater + 1 }));
      gotoPage(updater);
    },
    [dispatch, gotoPage]
  );

  useEffect(() => {
    if (queryFilters.page === 1) {
      gotoPage(0);
    }
  }, [gotoPage, queryFilters]);

  useEffect(() => {
    const params: Partial<TTransactionListQuery> = removeEmptyProperties({
      ...filtersDebounce,
      transferType: filtersDebounce.transferType === "DEFAULT" ? "" : filtersDebounce.transferType,
      chainId,
    });

    getListTransactionHistory(params as TTransactionListQuery);
  }, [chainId, filtersDebounce, getListTransactionHistory, updater]);

  useEffect(() => {
    return () => {
      dispatch(transactionHistoryActions.clearState());
    };
  }, [dispatch]);

  return (
    <DefaultLayout title="Transfer History">
      <TransferHistoryFilters className="mb-5" />
      <Table
        {...getTableProps()}
        isLoading={isFetching || isLoading}
        noRecord={!transactionList.length}
      >
        <Table.Head>
          {headerGroups?.map((headerGroup) => (
            <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((col) => {
                return (
                  <Table.HeadCell
                    {...col.getHeaderProps()}
                    style={{ width: col.width }}
                    className={cn("pl-8", {
                      "text-center": col.id === "transferType",
                    })}
                  >
                    {col.render("Header")}
                  </Table.HeadCell>
                );
              })}
            </Table.HeadRow>
          ))}
        </Table.Head>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <Table.Row
                color={"inherit"}
                {...row.getRowProps()}
                key={index}
                className={cn(index % 2 === 0 ? "bg-white" : "bg-[#FDFDFD]")}
              >
                {row.cells.map((cell, index) => (
                  <React.Fragment key={index}>{cell.render("Cell", { index })}</React.Fragment>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <TablePagination
        currentPage={pageIndex + 1}
        pageCount={pageCount}
        nextPage={handleNext}
        prevPage={handlePrev}
        hasNext={canNextPage}
        hasPrev={canPreviousPage}
        className="mt-5"
        gotoPage={handleGotoPage}
      />
    </DefaultLayout>
  );
};

export default TransferHistory;
