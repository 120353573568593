import React from "react";
import { IconProps } from "../../classes/Icon";

const CalendarIcon: React.FC<IconProps> = ({ size = "24", color = "#A7A8A7", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M2 9.00003C1.44772 9.00003 1 9.44774 1 10C1 10.5523 1.44772 11 2 11V9.00003ZM22 11C22.5523 11 23 10.5523 23 10C23 9.44774 22.5523 9.00003 22 9.00003V11ZM2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17V15ZM22 17C22.5523 17 23 16.5523 23 16C23 15.4477 22.5523 15 22 15V17ZM9.5 2C9.5 1.44772 9.05228 1 8.5 1C7.94772 1 7.5 1.44772 7.5 2H9.5ZM7.5 6C7.5 6.55228 7.94772 7 8.5 7C9.05228 7 9.5 6.55228 9.5 6H7.5ZM16.5 2C16.5 1.44772 16.0523 1 15.5 1C14.9477 1 14.5 1.44772 14.5 2H16.5ZM14.5 6C14.5 6.55228 14.9477 7 15.5 7C16.0523 7 16.5 6.55228 16.5 6H14.5ZM9.5 10C9.5 9.44774 9.05228 9.00003 8.5 9.00003C7.94772 9.00003 7.5 9.44774 7.5 10H9.5ZM7.5 22C7.5 22.5523 7.94772 23 8.5 23C9.05228 23 9.5 22.5523 9.5 22H7.5ZM16.5 10C16.5 9.44774 16.0523 9.00003 15.5 9.00003C14.9477 9.00003 14.5 9.44774 14.5 10H16.5ZM14.5 22C14.5 22.5523 14.9477 23 15.5 23C16.0523 23 16.5 22.5523 16.5 22H14.5ZM23 6.5C23 5.94772 22.5523 5.5 22 5.5C21.4477 5.5 21 5.94772 21 6.5H23ZM21 19.5C21 20.0523 21.4477 20.5 22 20.5C22.5523 20.5 23 20.0523 23 19.5H21ZM3 6.5C3 5.94772 2.55228 5.5 2 5.5C1.44772 5.5 1 5.94772 1 6.5H3ZM1 19.5C1 20.0523 1.44772 20.5 2 20.5C2.55228 20.5 3 20.0523 3 19.5H1ZM7 21C6.44772 21 6 21.4477 6 22C6 22.5523 6.44772 23 7 23V21ZM17 23C17.5523 23 18 22.5523 18 22C18 21.4477 17.5523 21 17 21V23ZM21 3H3V5H21V3ZM3 3C1.89544 3 1 3.89542 1 5H3V3ZM1 5V21H3V5H1ZM1 21C1 22.1045 1.89538 23 3 23V21H1ZM3 23H21V21H3V23ZM21 23C22.1046 23 23 22.1045 23 21H21V23ZM23 21V5H21V21H23ZM23 5C23 3.89543 22.1046 3 21 3V5H23ZM2 11H22V9.00003H2V11ZM2 17H22V15H2V17ZM7.5 2V6H9.5V2H7.5ZM14.5 2V6H16.5V2H14.5ZM7.5 10V22H9.5V10H7.5ZM14.5 10V22H16.5V10H14.5ZM21 6.5V19.5H23V6.5H21ZM1 6.5V19.5H3V6.5H1ZM7 23H17V21H7V23Z"
        fill={color}
      />
    </svg>
  );
};

export default CalendarIcon;
