import React, { BaseHTMLAttributes, useMemo } from "react";
import Modal from "../bases/Modal";

export interface IImportCSVErrorModalProps extends BaseHTMLAttributes<HTMLDivElement> {
  container: HTMLElement;
  onCloseHandler?: () => void;
  propsState: {
    message: string;
  };
}

export default function ImportCSVErrorModal({
  container,
  onCloseHandler,
  propsState: { message },
}: IImportCSVErrorModalProps): React.ReactElement {
  const messages = useMemo(() => message.split(","), [message]);

  return (
    <Modal onCloseHandler={onCloseHandler} container={container} className="w-[460px]">
      <div className="text-2xl font-extrabold">Error Message</div>
      <div className="mt-6 w-full max-h-[50vh] scrollbar-thin overflow-auto">
        {messages.map((msg) => (
          <div className="text-danger">- {msg}</div>
        ))}
      </div>
    </Modal>
  );
}
