import classNames from "classnames";
import React, { useCallback } from "react";
import CheckedIcon from "../icons/CheckedIcon";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  groupClassName?: {
    wrapClassName?: string;
    boxClassName?: string;
    checkClassName?: string;
  };
}

function CheckBox({ checked, disabled, children, onClick, groupClassName }: Props) {
  const handleOnClicked = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (disabled) return;
      onClick && onClick();
    },
    [disabled, onClick]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== " " && e.key !== "Enter") return;
      onClick && onClick();
    },
    [onClick]
  );

  return (
    <div
      className={classNames(
        "cursor-pointer select-none flex flex-row gap-2 justify-start items-center relative",
        groupClassName?.wrapClassName
      )}
      onClick={handleOnClicked}
    >
      <div
        className={classNames(
          "w-5 h-5 border rounded-[3px] flex justify-center items-center",
          "border-grey-40",
          groupClassName?.boxClassName,
          {
            "!bg-slate-500": disabled,
          }
        )}
        onKeyDown={handleOnKeyDown}
        tabIndex={0}
      >
        {checked && (
          <div
            className={classNames(
              "bg-success rounded-[3px]",
              "absolute flex items-center justify-center transition-colors duration-250 ease-in-out",
              groupClassName?.checkClassName,
              {
                "!bg-slate-500": disabled,
              }
            )}
          >
            <CheckedIcon />
          </div>
        )}
      </div>
      {children && (
        <div
          className={classNames("first:ml-0 text-grey-100 text-sm", {
            "!text-slate-500": disabled,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default CheckBox;
