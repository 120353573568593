import { Middleware, MiddlewareAPI } from "redux";
import { TAppDispatch, TRootState } from "..";
import StorageUtils, { STORAGE_KEYS } from "../../utils/storage";
import { authActions } from "../authSlice";
import { generateWalletActions } from "../generateWalletSlice";
import { toastMessageActions } from "../toastMessageSlice";
import { walletActions } from "../walletSlice";

export const netMiddleware: Middleware =
  (store: MiddlewareAPI<TAppDispatch, TRootState>) => (next) => async (action) => {
    switch (action.type) {
      case walletActions.handleSetHasRole.type: {
        if (!action.payload) {
          store.dispatch(
            toastMessageActions.addToastMessage({
              type: "danger",
              title: "Access Denied!",
            })
          );
          store.dispatch(authActions.logout());
        }
        return next(action);
      }
      case authActions.logout.type: {
        store.dispatch(walletActions.clearState());
        store.dispatch(generateWalletActions.clearState());

        localStorage.removeItem(STORAGE_KEYS.IS_CONNECTED);
        StorageUtils.removeAccount();
        StorageUtils.removeSignature();
        StorageUtils.removeToken();
        return next(action);
      }
      default:
        if (
          (action.payload?.error && action.payload.error.status === 401) ||
          (action.payload && action.payload.status === 401)
        ) {
          store.dispatch(authActions.logout());
        }
        return next(action);
    }
  };
