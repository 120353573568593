import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import cn from "classnames";
import React, { BaseHTMLAttributes, useCallback, useEffect, useState } from "react";
import dataTransferApi from "../../apis/endpoints/dataTransfer";
import { TErrorReturn } from "../../classes/Api";
import { useAppDispatch } from "../../hooks/common";
import { modalSliceActions } from "../../stores/modalSlice";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { Button } from "../bases/Button";
import Modal from "../bases/Modal";

const FILE_TYPE = ["application/vnd.ms-excel", "text/csv"];
export interface IUploadFileModalProps extends BaseHTMLAttributes<HTMLDivElement> {
  container: HTMLElement;
  onCloseHandler?: () => void;
}

export default function UploadFileModal({
  container,
  onCloseHandler,
}: IUploadFileModalProps): React.ReactElement {
  const [file, setFile] = useState<File | undefined>();
  const [error, setError] = useState<string>("");

  const [uploadFile, uploadResponse] = dataTransferApi.useUploadMutation();
  const dispatch = useAppDispatch();

  const handleImportFileButtonClicked = useCallback(async () => {
    if (!file) {
      return setError("Please choose file");
    }
    if (error) return;
    const formData = new FormData();
    formData.append("file_csv", file);
    await uploadFile(formData);
  }, [error, file, uploadFile]);

  const handleFileOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFile(file);
    if (!file) {
      return setError("Please choose file");
    }
    if (!FILE_TYPE.includes(file.type)) {
      return setError("Wrong format");
    }
    return setError("");
  }, []);

  useEffect(() => {
    if (uploadResponse.isSuccess) {
      dispatch(
        toastMessageActions.addToastMessage({
          type: "success",
          title: "Successfully",
          description: "Successfully imported CSV",
        })
      );
      dispatch(modalSliceActions.shiftFromQueue());
    }
  }, [dispatch, uploadResponse.isSuccess]);

  useEffect(() => {
    if (uploadResponse.error) {
      if ((uploadResponse.error as FetchBaseQueryError).status === 400) {
        dispatch(
          modalSliceActions.addToQueue({
            type: "popup/import-csv-error",
            propsState: { message: (uploadResponse.error as TErrorReturn)?.data?.message || "" },
          })
        );
      } else {
        dispatch(
          toastMessageActions.addToastMessage({
            type: "danger",
            title: "Something went wrong!",
          })
        );
      }

      dispatch(modalSliceActions.shiftFromQueue());
    }
  }, [dispatch, uploadResponse.error]);

  return (
    <Modal onCloseHandler={onCloseHandler} container={container} className="w-[361px]">
      <div className="flex justify-between items-center gap-3 w-full">
        <Button
          className={cn("btn-third !w-[163px] !h-12 relative shrink-0", { "border-danger": error })}
        >
          Choose file
          <input
            className="w-full h-full rounded-[100px] cursor-pointer absolute opacity-0"
            type="file"
            accept=".csv"
            onChange={handleFileOnChange}
          />
        </Button>
        <div
          className={cn(
            "text-grey-80 text-sm tracking-tight max-w-full",
            "whitespace-nowrap overflow-hidden text-ellipsis"
          )}
        >
          {file?.name || "No file chosen"}
        </div>
      </div>
      <div className="text-xs text-danger mt-1 h-4">{error && error}</div>
      <Button className="btn-primary w-full mt-4" onClick={handleImportFileButtonClicked}>
        Import now
      </Button>
    </Modal>
  );
}
