import classNames from "classnames";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../hooks/common";
import { IToast, toastMessageActions } from "../../stores/toastMessageSlice";
import { shortenAddress } from "../../utils/format";
import CloseToastIcon from "../icons/CloseToastIcon";
import { DangerToastIcon } from "../icons/DangerToastIcon";
import { InfoIcon } from "../icons/InfoIcon";
import { SuccessToastIcon } from "../icons/SuccessToastIcon";
import { WarningIcon } from "../icons/WarningIcon";

const TOAST_TIME = 5000;

function ToastMessageItem({ id, title, description, type, transaction }: IToast) {
  const dispatch = useAppDispatch();
  const colorList = {
    success: "bg-emerald-0 text-grey-100 border-success",
    danger: "bg-rose-0 text-grey-100 border-danger",
    info: "bg-blue-0 text-grey-100 border-info",
    warning: "bg-yellow-0 text-grey-100 border-warning",
  };

  const iconList = {
    success: <SuccessToastIcon className="w-7 h-7" />,
    danger: <DangerToastIcon className="w-7 h-7" />,
    info: <InfoIcon className="w-7 h-7" />,
    warning: <WarningIcon className="w-7 h-7" />,
  };

  const handleOnCloseBtnClicked = useCallback(
    (index: number) => {
      dispatch(toastMessageActions.removeToastMessage(index));
    },
    [dispatch]
  );

  const renderTitle = useCallback(() => {
    if (description) {
      return <p className="text-base font-semibold">{title}</p>;
    }
    return <p className="text-sm leading-6">{title}</p>;
  }, [description, title]);

  useEffect(() => {
    if (!id) return;
    const timeout = setTimeout(() => {
      dispatch(toastMessageActions.removeToastMessage(id));
    }, TOAST_TIME);
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, id]);

  return (
    <div
      className={classNames(
        "animate-[toastIn_5s_ease-in-out_forwards]",
        "relative transition-all overflow-hidden",
        "w-[370px] m-h-20 py-[14px] pr-[44px] pl-[50px] rounded-tl-lg rounded-bl-lg mt-2 origin-right border",
        colorList[type]
      )}
    >
      <div className={classNames("absolute top-[12.5px] left-[12.5px]", "w-7 h-7")}>
        {iconList[type]}
      </div>

      <div>
        {renderTitle()}
        {transaction ? (
          <p className="text-sm mt-1">
            {description}
            <a href={transaction?.href} target="blank">
              {shortenAddress(transaction.hash)}
            </a>
          </p>
        ) : (
          <p className="text-sm text-grey-100 mt-1">{description}</p>
        )}
      </div>

      <button
        onClick={() => {
          if (!id) return;
          handleOnCloseBtnClicked(id);
        }}
        className="absolute min-w-[12px] top-6 right-5"
      >
        <CloseToastIcon className="stroke-grey-100" />
      </button>
    </div>
  );
}

export default ToastMessageItem;
