import ReactDOM from "react-dom";
import cn from "classnames";
import CloseIcon from "../icons/CloseIcon";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../hooks/common";
import { modalSliceActions } from "../../stores/modalSlice";

type Props = {
  children: React.ReactNode;
  className?: string;
  container: HTMLElement;
  bCloseBtn?: boolean;
  onCloseHandler?: () => void;
};
export default function Modal({
  children,
  className,
  container,
  bCloseBtn = true,
  onCloseHandler,
}: Props): React.ReactElement {
  const dispatch = useAppDispatch();

  const handleOnCloseBtnClicked = useCallback(() => {
    onCloseHandler && onCloseHandler();
    dispatch(modalSliceActions.shiftFromQueue());
  }, [dispatch, onCloseHandler]);

  const renderCloseBtn = useCallback(() => {
    if (!bCloseBtn) return;

    return (
      <div
        onClick={handleOnCloseBtnClicked}
        className={cn("absolute top-5 right-5 z-10 cursor-pointer")}
      >
        <CloseIcon />
      </div>
    );
  }, [bCloseBtn, handleOnCloseBtnClicked]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return ReactDOM.createPortal(
    <div className="fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center">
      <div
        onClick={bCloseBtn ? handleOnCloseBtnClicked : undefined}
        className="absolute top-0 left-0 bottom-0 right-0 bg-modal-backdrop/50"
      ></div>
      <div
        className={cn(
          "flex flex-col items-start relative p-12 bg-modal-light shadow-modal rounded-2xl",
          className
        )}
      >
        {renderCloseBtn()}
        {children}
      </div>
    </div>,
    container
  );
}
