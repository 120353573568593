import cn from "classnames";
import React, { useCallback, useState } from "react";
import { DEFAULT_CHAIN_ID, SUPPORTED_NETWORKS } from "../../config/network";
import { useAppDispatch, useAppSelector, useMouseClick } from "../../hooks/common";
import useCopyClipboard from "../../hooks/useCopyToClipboard";
import { useWeb3Activity } from "../../hooks/useWeb3Activity";
import { authActions } from "../../stores/authSlice";
import { shortenAddress } from "../../utils/format";
import { Button } from "../bases/Button";
import Tooltip from "../bases/Tooltip";
import CloseIcon from "../icons/CloseIcon";
import CopyIcon from "../icons/CopyIcon";
import LinkIcon from "../icons/LinkIcon";
import LogoutIcon from "../icons/LogoutIcon";
import WalletIcon from "../icons/WalletIcon";

function UserMenu() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { deactivate, account: accountConnected, chainId } = useWeb3Activity();
  const dispatch = useAppDispatch();
  const walletState = useAppSelector((state) => state.wallet);

  const [isCopied, setIsCopied] = useCopyClipboard();

  const handleDisconnectWallet = useCallback(() => {
    deactivate();
    setDropdownVisible(false);
    dispatch(authActions.logout());
  }, [deactivate, dispatch]);

  useMouseClick(() => {
    setDropdownVisible(false);
  });

  const copyAddress = useCallback(() => {
    setIsCopied(accountConnected || "");
  }, [accountConnected, setIsCopied]);

  const handleOnAccountDetailClicked = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setDropdownVisible((prev) => !prev);
  }, []);

  const handleCloseBtnClicked = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setDropdownVisible(false);
  }, []);

  const handleOnExpandedPanelClicked = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      className={cn(
        "relative rounded-[100px]",
        "font-extrabold select-none text-base bg-primary-80/[.12]"
      )}
      onClick={handleOnAccountDetailClicked}
    >
      <div className="flex flex-row items-center justify-end gap-3 cursor-pointer p-1 pr-6">
        <div className={cn("bg-primary-80 rounded-[100px] text-white text-sm py-2 px-3")}>
          {walletState.nativeTokenBalance}{" "}
          {SUPPORTED_NETWORKS[chainId || DEFAULT_CHAIN_ID]?.nativeCurrency.symbol}
        </div>
        <div className="text-primary-80">{shortenAddress(accountConnected || "")}</div>
      </div>
      {isDropdownVisible && (
        <div
          className={cn(
            "w-[456px] bg-white p-12 shadow-modal rounded-2xl absolute top-14 right-0 !block",
            "transition-opacity duration-250 ease-linear z-10"
          )}
          onClick={handleOnExpandedPanelClicked}
        >
          <div className="absolute top-5 right-5 cursor-pointer" onClick={handleCloseBtnClicked}>
            <CloseIcon />
          </div>
          <div className="flex pb-5 border-b-grey-20 border-b gap-3 justify-center items-center">
            <WalletIcon size={24} />
            <div className="font-extrabold text-lg text-grey-100">My wallet</div>
          </div>
          <div className="flex justify-between mt-6 items-center">
            <div className="text-grey-70 text-sm">Wallet address</div>
            <div className="flex items-center gap-4">
              <div className="text-grey-100 relative">
                <Tooltip message={accountConnected}>
                  {shortenAddress(accountConnected || "")}
                </Tooltip>
              </div>
              <Tooltip message="Copied" hidden={!isCopied} className="flex">
                <div className="cursor-pointer" onClick={copyAddress}>
                  <CopyIcon />
                </div>
              </Tooltip>
              <div className="h-5 border-r border-grey-20"></div>
              <a
                href={`${
                  SUPPORTED_NETWORKS[chainId || DEFAULT_CHAIN_ID].blockExplorerUrls[0]
                }/address/${accountConnected}`}
                target="_blank"
                rel="noreferrer"
              >
                <LinkIcon className="min-w-[20px] min-h-[20px]" />
              </a>
            </div>
          </div>
          <Button className="btn-primary gap-3 w-full mt-6" onClick={handleDisconnectWallet}>
            <LogoutIcon />
            <span>Disconnect</span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default UserMenu;
