import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/common";
import Spinner from "../../components/commons/Spinner";
import DefaultLayout from "../../components/layout/DefaultLayout";
import { Input } from "../../components/bases/Input";
import { Button } from "../../components/bases/Button";
import { useWeb3Activity } from "../../hooks/useWeb3Activity";
import { useTokenTransferContract } from "../../hooks/useContract";
import { BigNumber } from "ethers";
import { toastMessageActions } from "../../stores/toastMessageSlice";
import { BLOCK_GAS_LIMIT, ERROR_TRANSACTION } from "../../constants/constant";
import { addTransaction } from "../../stores/walletSlice";

const ERROR_FIELD = {
  required: "Please enter vesting ID",
  greater: "Vesting ID must be greater than 0",
  valid: "Vesting has been unlocked",
  expired: "Only vesting id that is not due for claim can be removed",
  empty: "Vesting ID has been removed or not existed",
};

const RemoveVesting = () => {
  {
    const [vestingId, setVestingId] = useState<string>("");
    const [error, setError] = useState<string>("");

    const { transactions } = useAppSelector((state) => state.wallet);
    const dispatch = useAppDispatch();
    const context = useWeb3Activity();
    const tokenTransferContract = useTokenTransferContract(context);

    const isTransactionPending = useMemo(() => {
      if (!transactions[context.chainId]) {
        setVestingId("");
        return false;
      }
      const isPending = !!Object.values(transactions[context.chainId]).find(
        (tx) => tx.action === "remove-vesting"
      );
      if (isPending) return true;
      setVestingId("");
      return false;
    }, [context.chainId, transactions]);

    const handleClickRemoveVestingBtn = useCallback(
      async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!vestingId) return setError(ERROR_FIELD.required);
        if (!Number(vestingId)) return setError(ERROR_FIELD.greater);
        if (!tokenTransferContract) return;

        try {
          const vestingWallet = await tokenTransferContract.vestingWalletV2(vestingId);
          const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
          if (!vestingWallet.isVestingValid)
            return dispatch(
              toastMessageActions.addToastMessage({
                type: "danger",
                title: ERROR_FIELD.empty,
              })
            );
          else if (Number(vestingWallet.startDate) === 0) {
            if (currentTimestampInSeconds > Number(vestingWallet.endDate))
              return dispatch(
                toastMessageActions.addToastMessage({
                  type: "danger",
                  title: ERROR_FIELD.expired,
                })
              );
          } else if (currentTimestampInSeconds >= Number(vestingWallet.startDate))
            return dispatch(
              toastMessageActions.addToastMessage({
                type: "danger",
                title: ERROR_FIELD.valid,
              })
            );
          const gasLimit = await tokenTransferContract.estimateGas.removeVesting(vestingId);
          if (BigNumber.from(gasLimit).gt(BLOCK_GAS_LIMIT[context.chainId])) {
            dispatch(
              toastMessageActions.addToastMessage({
                title: "Exceeds block gas limit",
                type: "danger",
              })
            );
            return;
          }
          const tx = await tokenTransferContract.removeVesting(vestingId, {
            gasLimit,
          });

          dispatch(
            addTransaction({
              action: "remove-vesting",
              chainId: context.chainId,
              hash: tx.hash,
            })
          );
        } catch ({ code }) {
          if (
            code === ERROR_TRANSACTION.reject.codeNumber ||
            code === ERROR_TRANSACTION.reject.codeString
          ) {
            dispatch(
              toastMessageActions.addToastMessage({
                type: "danger",
                title: "Signature rejected",
              })
            );
          } else {
            dispatch(
              toastMessageActions.addToastMessage({
                type: "danger",
                title: "Something went wrong!",
              })
            );
          }
        }
      },
      [vestingId, tokenTransferContract, dispatch, context.chainId]
    );

    const handleOnValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const regex = /^[0-9]+$/;
      if (value && !value.match(regex)) return;
      setVestingId(value);
      setError("");
    }, []);

    return (
      <DefaultLayout title="Remove Vesting">
        <form className="mt-8 w-full" onSubmit={handleClickRemoveVestingBtn}>
          <Input
            className={cn("w-[560px]", { "!border-danger": error })}
            placeholder="Vesting ID"
            value={vestingId}
            onChange={handleOnValueChange}
          />
          <div className="text-xs text-danger mt-1 h-4">{error && error}</div>
          {isTransactionPending ? (
            <Button className="btn-primary mt-2 gap-1" disabled={true}>
              <Spinner size="medium" /> Removing
            </Button>
          ) : (
            <Button className="btn-primary mt-2" type="submit">
              Remove vesting
            </Button>
          )}
        </form>
      </DefaultLayout>
    );
  }
};

export default RemoveVesting;
