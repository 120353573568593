/* eslint-disable no-console */
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import { SUPPORTED_WALLETS } from "../config/wallet";
import { switchToNetwork } from "../functions/network";
import { modalSliceActions } from "../stores/modalSlice";
import { toastMessageActions } from "../stores/toastMessageSlice";
import { walletActions } from "../stores/walletSlice";
import StorageUtils, { STORAGE_KEYS } from "../utils/storage";
import { useAppDispatch, useAppSelector } from "./common";

export const useConnectWallet = () => {
  const walletState = useAppSelector((state) => state.wallet);
  const { activate } = useWeb3React();
  const dispatch = useAppDispatch();

  const tryActivation = useCallback(
    async (chain?: number) => {
      const chainId = chain || walletState.chainId;
      if (!window.ethereum) {
        return dispatch(
          toastMessageActions.addToastMessage({
            title: "No wallet detected",
            type: "danger",
          })
        );
      }

      const connector = await SUPPORTED_WALLETS.METAMASK.connector;
      const conn = typeof connector === "function" ? await connector() : connector;

      if (conn) {
        activate(
          conn,
          (error) => {
            console.debug("Error activating connector ", error);
          },
          true
        )
          .then(async () => {
            const provider = await conn?.getProvider();
            if (provider && chainId) {
              return switchToNetwork({
                provider,
                chainId,
              })
                .then(() => {
                  const selectedAddress = window.ethereum?.selectedAddress;
                  dispatch(walletActions.setChainId(chainId));
                  dispatch(walletActions.setAccount(selectedAddress || ""));
                  StorageUtils.setAccount(selectedAddress || "");
                })
                .catch(({ code }) => {
                  if (code === 4001) {
                    dispatch(
                      toastMessageActions.addToastMessage({
                        title: "The connection to MetaMask has been canceled by the user",
                        type: "danger",
                      })
                    );
                  }
                });
            }
          })
          .catch((error) => {
            if (error?.name === "UserRejectedRequestError") {
              dispatch(
                toastMessageActions.addToastMessage({
                  title: "The connection to MetaMask has been canceled by the user",
                  type: "danger",
                })
              );
            }
            console.error("Error activating", error);
            localStorage.removeItem(STORAGE_KEYS.IS_CONNECTED);
            if (error instanceof UnsupportedChainIdError) {
              activate(conn); // a little janky...can't use setError because the connector isn't set
            }
          })
          .finally(() => {
            dispatch(modalSliceActions.shiftFromQueue());
          });
      }
    },
    [activate, dispatch, walletState.chainId]
  );

  return { tryActivation };
};
