import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
  prepareHeaders: (headers) => {
    // const state = getState() as TRootState;
    const token = sessionStorage.getItem("tokenAccess");
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const cosplayApi = createApi({
  baseQuery,
  endpoints: () => ({}),
});

export default cosplayApi;
