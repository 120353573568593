import { useCallback, useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import Modal, { TModalData } from "../classes/Modal";
import { TAppDispatch, TRootState } from "../stores";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<TAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;

export const useMouseClick = (callback: (event: MouseEvent) => void, root?: HTMLElement) => {
  const rfCallback = useRef(callback);
  const handleMouseClick = useCallback((event: MouseEvent) => {
    rfCallback.current(event);
  }, []);

  useEffect(() => {
    rfCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (root) {
      root.addEventListener("click", handleMouseClick);
    } else {
      document.addEventListener("click", handleMouseClick);
    }

    return () => {
      if (root) {
        root.removeEventListener("click", handleMouseClick);
      } else {
        document.removeEventListener("click", handleMouseClick);
      }
    };
  }, [handleMouseClick, root]);
};

export const useCtrlKeyPress = (keys: string[], callback: (event: KeyboardEvent) => void) => {
  // implement the callback ref pattern
  const rfCallBack = useRef(callback);

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      if (event.ctrlKey && keys.some((key) => event.key === key)) {
        event.preventDefault();
        rfCallBack.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    rfCallBack.current = callback;
  }, [callback]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
};

export const usePressKey = (keys: string[], callback: (event: KeyboardEvent) => void) => {
  // implement the callback ref pattern
  const rfCallBack = useRef(callback);

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      if (keys.some((key) => event.key === key)) {
        event.preventDefault();
        rfCallBack.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    rfCallBack.current = callback;
  }, [callback]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
};

export const useGlobalContainer = (): HTMLElement | undefined => {
  const [container, setContainer] = useState<HTMLElement>();

  const getContainer = useCallback(() => document.body, []);

  useEffect(() => {
    setContainer(getContainer());
  }, [getContainer]);

  return container;
};

export const useModalQueue = (queue: Array<TModalData>): React.ReactNode => {
  const [modal, setModal] = useState<JSX.Element>();
  const container = useGlobalContainer();
  useEffect(() => {
    if (!container) return;
    const modalGenerator = Modal.generateFromQueue(queue, container);
    setModal(modalGenerator.next().value);
  }, [container, queue]);

  return modal;
};

// export const useLoadTheme = () => {
//     const dispatch = useAppDispatch();
//     const accessibilityState = useAppSelector((state) => state.accessibility);
//     useEffect(() => {
//         if (accessibilityState.theme) return;
//         const localTheme = window.localStorage.getItem(LOCAL_STORAGE_KEYS.theme);
//         const theme: TTheme = localTheme && isTheme(localTheme) ? localTheme : "light";
//         dispatch(accessibilityActions.setTheme(theme));
//     }, [accessibilityState.theme, dispatch]);
// };

// export const useTheme = () => {
//     const accessibilityState = useAppSelector((state) => state.accessibility);

//     useEffect(() => {
//         const { theme } = accessibilityState;
//         if (theme === "dark") {
//             document.documentElement.classList.add("dark");
//         } else {
//             document.documentElement.classList.remove("dark");
//         }
//     }, [accessibilityState]);
// };
