/* eslint-disable no-console */
import { MaxUint256 } from "@ethersproject/constants";
import { TransactionReceipt, TransactionResponse } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import { useCallback, useMemo, useState } from "react";
import { IWeb3ReactContext } from "../classes/Wallet";
import { ERROR_TRANSACTION, ZERO_ADDRESS } from "../constants/constant";
import { toastMessageActions } from "../stores/toastMessageSlice";
import { useAppDispatch } from "./common";
import { useTokenContract, useTokenERC20PermitContract } from "./useContract";
import { useTokenAllowance } from "./useTokenAllowance";

export enum ApprovalState {
  UNKNOWN = "UNKNOWN",
  NOT_APPROVED = "NOT_APPROVED",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  context: IWeb3ReactContext,
  tokenAddress?: string,
  amountToApprove?: BigNumber,
  spender?: string,
  dependency?: unknown
): [Promise<number>, ApprovalState, () => Promise<void>, boolean] {
  const { account } = context;
  const dispatch = useAppDispatch();
  const [isApproving, setApproving] = useState<boolean>(false);

  const currentAllowance = useTokenAllowance(
    context,
    tokenAddress,
    account ?? undefined,
    spender,
    dependency || isApproving
  );

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (tokenAddress === ZERO_ADDRESS) return ApprovalState.APPROVED;
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    if (isApproving) return ApprovalState.PENDING;
    // amountToApprove will be defined if currentAllowance is
    return BigNumber.from(currentAllowance).lt(amountToApprove)
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [amountToApprove, currentAllowance, isApproving, spender, tokenAddress]);

  const tokenContract = useTokenContract(context, tokenAddress);
  const tokenERC20PermitContract = useTokenERC20PermitContract(context, tokenAddress);

  // check the current token decimals
  const tokenDecimals: Promise<number> = useMemo(async () => {
    if (!tokenAddress) return;

    if (tokenAddress === ZERO_ADDRESS) return 18;

    if (!tokenContract) {
      console.error("tokenContract is null");
      return;
    }

    // get current decimals
    return await tokenContract.decimals();
  }, [tokenAddress, tokenContract]);

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error("approve was called unnecessarily");
      return;
    }
    if (!tokenAddress) {
      console.error("no token");
      return;
    }

    if (!tokenContract) {
      console.error("tokenContract is null");
      return;
    }

    if (!amountToApprove) {
      console.error("missing amount to approve");
      return;
    }

    if (!spender) {
      console.error("no spender");
      return;
    }

    let useExact = false;

    if (BigNumber.from(currentAllowance).isZero()) {
      setApproving(true);
      const estimatedGas = await tokenContract.estimateGas
        .approve(spender, MaxUint256)
        .catch(async () => {
          // general fallback for tokens who restrict approval amounts
          useExact = true;
          return await tokenContract.estimateGas.approve(spender, amountToApprove).catch(() => {
            // general fallback for tokens which restrict re-approve (e.g: USDT)
            return tokenContract.estimateGas.approve(spender, 0);
          });
        });
      const gasPrice = await context.library.getGasPrice();
      return tokenContract
        .approve(spender, useExact ? amountToApprove : MaxUint256, {
          gasLimit: estimatedGas,
          gasPrice: gasPrice,
        })
        .then((response: TransactionResponse) => {
          return response.wait();
        })
        .then((tx: TransactionReceipt) => {
          if (tx?.status === 1) {
            dispatch(
              toastMessageActions.addToastMessage({
                type: "success",
                title: "Approved successfully",
              })
            );
          }
          setApproving(false);
        })
        .catch(({ code }: { code: number | string }) => {
          setApproving(false);
          if (
            code === ERROR_TRANSACTION.reject.codeNumber ||
            code === ERROR_TRANSACTION.reject.codeString
          ) {
            dispatch(
              toastMessageActions.addToastMessage({
                type: "danger",
                title: "Signature rejected",
              })
            );
          }
        });
    } else {
      setApproving(true);
      let isTokenReApprove;
      const gasFee = await tokenERC20PermitContract?.estimateGas
        .increaseAllowance(spender, BigNumber.from(MaxUint256).sub(currentAllowance || "0"))
        .catch(async (err) => {
          // general fallback for tokens who restrict approval amounts
          useExact = true;
          console.log("err", err);
          return tokenERC20PermitContract.estimateGas
            .increaseAllowance(spender, amountToApprove)
            .catch(() => {
              // general fallback for tokens which restrict re-approve (e.g: USDT)
              isTokenReApprove = true;
              return tokenContract.estimateGas.approve(spender, 0);
            });
        });
      const gasPrice = await context.library.getGasPrice();
      //For token that restrict re-approve
      if (isTokenReApprove) {
        return tokenContract
          ?.approve(spender, 0, {
            gasLimit: gasFee,
            gasPrice: gasPrice,
          })
          .then((response: TransactionResponse) => {
            return response.wait();
          })
          .then(async (tx: TransactionReceipt) => {
            if (tx?.status === 1) {
              const gasFee = await tokenContract.estimateGas.approve(
                spender,
                BigNumber.from(MaxUint256).sub(currentAllowance || "0")
              );
              return tokenContract
                ?.approve(
                  spender,
                  useExact
                    ? amountToApprove
                    : BigNumber.from(MaxUint256).sub(currentAllowance || "0"),
                  {
                    gasLimit: gasFee,
                    gasPrice: gasPrice,
                  }
                )
                .then((response: TransactionResponse) => {
                  return response.wait();
                })
                .then((tx: TransactionReceipt) => {
                  if (tx?.status === 1) {
                    dispatch(
                      toastMessageActions.addToastMessage({
                        type: "success",
                        title: "Approve Successfully",
                      })
                    );
                  }
                  setApproving(false);
                })
                .catch(({ code }: { code: number | string }) => {
                  setApproving(false);
                  if (
                    code === ERROR_TRANSACTION.reject.codeNumber ||
                    code === ERROR_TRANSACTION.reject.codeString
                  ) {
                    dispatch(
                      toastMessageActions.addToastMessage({
                        type: "danger",
                        title: "Signature rejected",
                      })
                    );
                  } else {
                    dispatch(
                      toastMessageActions.addToastMessage({
                        type: "danger",
                        title: "Something went wrong",
                      })
                    );
                  }
                });
            }
          })
          .catch(({ code }: { code: number | string }) => {
            setApproving(false);
            if (
              code === ERROR_TRANSACTION.reject.codeNumber ||
              code === ERROR_TRANSACTION.reject.codeString
            ) {
              dispatch(
                toastMessageActions.addToastMessage({
                  type: "danger",
                  title: "Signature rejected",
                })
              );
            }
          });
      } else {
        return tokenERC20PermitContract
          ?.increaseAllowance(
            spender,
            useExact ? amountToApprove : BigNumber.from(MaxUint256).sub(currentAllowance || "0"),
            {
              gasLimit: gasFee,
              gasPrice: gasPrice,
            }
          )
          .then((response: TransactionResponse) => {
            return response.wait();
          })
          .then((tx: TransactionReceipt) => {
            if (tx?.status === 1) {
              dispatch(
                toastMessageActions.addToastMessage({
                  type: "success",
                  title: "Approve Successfully",
                })
              );
            }
            setApproving(false);
          })
          .catch(({ code }: { code: number | string }) => {
            setApproving(false);
            if (
              code === ERROR_TRANSACTION.reject.codeNumber ||
              code === ERROR_TRANSACTION.reject.codeString
            ) {
              dispatch(
                toastMessageActions.addToastMessage({
                  type: "danger",
                  title: "Signature rejected",
                })
              );
            } else {
              dispatch(
                toastMessageActions.addToastMessage({
                  type: "danger",
                  title: "Something went wrong",
                })
              );
            }
          });
      }
    }
  }, [
    approvalState,
    tokenAddress,
    tokenContract,
    amountToApprove,
    spender,
    currentAllowance,
    dispatch,
    tokenERC20PermitContract,
    context.library
  ]);

  return [tokenDecimals, approvalState, approve, isApproving];
}
