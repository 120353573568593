import React from "react";

const PolygonRoundedIcon = () => {
  return (
    <svg
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full"
    >
      <path
        d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z"
        fill="#8247E5"
      />
      <path
        d="M168.774 96.3819C165.69 94.6195 161.725 94.6195 158.2 96.3819L133.526 110.922L116.783 120.174L92.5503 134.714C89.4661 136.477 85.5007 136.477 81.9759 134.714L63.03 123.259C59.9458 121.496 57.7428 117.971 57.7428 114.006V91.9759C57.7428 88.4511 59.5052 84.9262 63.03 82.7232L81.9759 71.7082C85.0601 69.9458 89.0255 69.9458 92.5503 71.7082L111.496 83.1638C114.58 84.9262 116.783 88.4511 116.783 92.4165V106.956L133.526 97.2631V82.2826C133.526 78.7578 131.764 75.233 128.239 73.03L92.9909 52.3218C89.9067 50.5594 85.9413 50.5594 82.4165 52.3218L46.2872 73.4706C42.7624 75.233 41 78.7578 41 82.2826V123.699C41 127.224 42.7624 130.749 46.2872 132.952L81.9759 153.66C85.0601 155.422 89.0255 155.422 92.5503 153.66L116.783 139.561L133.526 129.868L157.759 115.768C160.843 114.006 164.809 114.006 168.334 115.768L187.279 126.783C190.364 128.546 192.567 132.071 192.567 136.036V158.066C192.567 161.591 190.804 165.116 187.279 167.319L168.774 178.334C165.69 180.096 161.725 180.096 158.2 178.334L139.254 167.319C136.17 165.556 133.967 162.031 133.967 158.066V143.967L117.224 153.66V168.2C117.224 171.725 118.986 175.249 122.511 177.452L158.2 198.161C161.284 199.923 165.249 199.923 168.774 198.161L204.463 177.452C207.547 175.69 209.75 172.165 209.75 168.2V126.343C209.75 122.818 207.988 119.293 204.463 117.09L168.774 96.3819Z"
        fill="white"
      />
    </svg>
  );
};

export default PolygonRoundedIcon;
