import React from "react";

const EthereumRoundedIcon = () => {
  return (
    <svg
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full"
    >
      <path
        d="M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z"
        fill="#627EEA"
      />
      <path d="M124.578 31V100.297L183.148 126.469L124.578 31Z" fill="white" fillOpacity="0.602" />
      <path d="M124.578 31L66 126.469L124.578 100.297V31Z" fill="white" />
      <path
        d="M124.578 171.375V218.461L183.187 137.375L124.578 171.375Z"
        fill="white"
        fillOpacity="0.602"
      />
      <path d="M124.578 218.461L124.578 171.367L66 137.375L124.578 218.461Z" fill="white" />
      <path
        d="M124.578 160.477L183.148 126.469L124.578 100.312V160.477Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path d="M66 126.469L124.578 160.477V100.312L66 126.469Z" fill="white" fillOpacity="0.602" />
    </svg>
  );
};

export default EthereumRoundedIcon;
