import cn from "classnames";
import React, { useCallback } from "react";
import { BUTTON_CONST, SIBLING_COUNT } from "../../../constants/constant";
import { DOTS, usePagination } from "../../../hooks/usePagination";
import Spinner from "../../commons/Spinner";
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import ArrowRightIcon from "../../icons/ArrowRightIcon";
import SortingIcon from "../../icons/SortingIcon";
import { NoRecordTable } from "./TableNoRecord";

type TableRowProps = React.HTMLAttributes<HTMLTableRowElement> & {
  forceDropdown?: boolean;
  actions?: React.ReactNode;
  linkTo?: string;
};

type TablePaginationProps = React.HTMLAttributes<HTMLDivElement> & {
  currentPage: number;
  pageCount: number;
  nextPage: () => void;
  prevPage: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  gotoPage: (pageIndex: number) => void;
};

type TableCellProps = React.TdHTMLAttributes<HTMLTableCellElement> & {
  linkTo?: string;
  name?: string;
};

type SortingHeadCellProps = {
  onSortClicked: () => void;
  sortDirection?: "ASC" | "DESC";
  setSortDirection: (field?: string) => void;
} & React.HTMLAttributes<HTMLTableCellElement>;

export type TableProps = {
  tableActions?: React.ReactNode;
  enableSearch?: boolean;
  immediateSearchFocus?: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  containerClassName?: string;
  isLoading?: boolean;
  handleSearch?: (searchTerm: string) => void;
  noRecord?: boolean;
} & React.HTMLAttributes<HTMLTableElement>;

type TableElement<T> = React.ForwardRefExoticComponent<T> & React.RefAttributes<unknown>;

type TableType = {
  Head: TableElement<React.HTMLAttributes<HTMLTableSectionElement>>;
  HeadRow: TableElement<React.HTMLAttributes<HTMLTableRowElement>>;
  HeadCell: TableElement<React.ThHTMLAttributes<HTMLTableCellElement>>;
  SortingHeadCell: TableElement<SortingHeadCellProps>;
  Body: TableElement<React.HTMLAttributes<HTMLTableSectionElement>>;
  Row: TableElement<TableRowProps>;
  Cell: TableElement<TableCellProps>;
  Pagination: React.ForwardRefExoticComponent<TablePaginationProps> & React.RefAttributes<unknown>;
} & TableElement<TableProps>;

const Table = React.forwardRef<HTMLTableElement, TableProps>(
  (
    {
      className,
      children,
      tableActions,
      enableSearch,
      handleSearch,
      containerClassName,
      isLoading,
      noRecord,
      ...props
    },
    ref
  ) => {
    if (enableSearch && !handleSearch) {
      throw new Error("Table cannot enable search without a search handler");
    }

    const renderStatus = useCallback(() => {
      if (isLoading) {
        return (
          <div className="flex items-center justify-center absolute inset-0 py-32">
            <Spinner size={"large"} variant={"secondary"} />
          </div>
        );
      }
      if (noRecord) return <NoRecordTable />;
      return null;
    }, [isLoading, noRecord]);

    return (
      <div className={cn("flex flex-col", containerClassName)}>
        {tableActions && <div className="flex items-center"> {tableActions}</div>}
        <div
          className={cn("relative border border-grey-40 rounded-lg", {
            "min-h-[256px]": isLoading,
          })}
        >
          <table ref={ref} className={cn("w-full table-auto", className)} {...props}>
            {children}
          </table>
          {renderStatus()}
        </div>
      </div>
    );
  }
) as TableType;

Table.Head = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, children, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      "whitespace-nowrap text-grey-100 border-b border-grey-40/50 bg-[#FDFDFD]",
      "font-semibold select-none",
      className
    )}
    {...props}
  >
    {children}
  </thead>
));

Table.HeadRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, children, ...props }, ref) => (
    <tr ref={ref} className={cn(className)} {...props}>
      {children}
    </tr>
  )
);

Table.HeadCell = React.forwardRef<HTMLTableCellElement, React.HTMLAttributes<HTMLTableCellElement>>(
  ({ className, children, ...props }, ref) => (
    <th
      ref={ref}
      className={cn("text-left h-[40px] border-[none_solid_solid_none] rounded-lg", className)}
      {...props}
    >
      {children}
    </th>
  )
);

Table.SortingHeadCell = React.forwardRef<HTMLTableCellElement, SortingHeadCellProps>(
  (
    {
      onSortClicked,
      sortDirection,
      setSortDirection,
      className,
      children,
      ...props
    }: SortingHeadCellProps,
    ref
  ) => {
    return (
      <th ref={ref} className={cn("text-left py-2.5", className)} {...props}>
        <div
          className="flex items-center cursor-pointer select-none"
          onClick={(e) => {
            e.preventDefault();
            if (!sortDirection) {
              setSortDirection("ASC");
            } else {
              if (sortDirection === "ASC") {
                setSortDirection("DESC");
              } else {
                setSortDirection(undefined);
              }
            }
            onSortClicked();
          }}
        >
          {children}
          <SortingIcon
            size={16}
            ascendingColor={sortDirection === "ASC" ? "#111827" : undefined}
            descendingColor={sortDirection === "DESC" ? "#111827" : undefined}
          />
        </div>
      </th>
    );
  }
);

Table.Body = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, children, ...props }, ref) => (
  <tbody ref={ref} className={cn(className)} {...props}>
    {children}
  </tbody>
));

Table.Cell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ className, linkTo, children, ...props }, ref) => (
    <td
      ref={ref}
      className={cn("h-[40px]", "last:rounded-lg first:rounded-lg", className)}
      {...props}
      {...(linkTo && {
        onClick: (e) => {
          linkTo;
          e.stopPropagation();
        },
      })}
    >
      {children}
    </td>
  )
);

Table.Row = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, actions, children, linkTo, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn("border-t border-grey-40 text-grey-80", className, {
        "cursor-pointer hover:bg-grey-5": linkTo !== undefined,
      })}
      {...props}
      {...(linkTo && {
        onClick: () => {
          linkTo;
        },
      })}
    >
      {children}
      {actions && (
        <Table.Cell onClick={(e) => e.stopPropagation()} className="w-[32px]">
          {actions}
        </Table.Cell>
      )}
    </tr>
  )
);

export const TablePagination = ({
  className,
  currentPage,
  pageCount,
  nextPage,
  prevPage,
  hasNext,
  hasPrev,
  gotoPage,
}: TablePaginationProps) => {
  const paginationRange = usePagination({
    pageCount,
    buttonConst: BUTTON_CONST,
    siblingCount: SIBLING_COUNT,
    currentPage,
  });

  return (
    <ul
      className={cn(
        "sticky left-0 flex flex-row justify-center items-center gap-x-2 select-none",
        className
      )}
    >
      <li
        className={cn(
          "border rounded min-w-[32px] h-8 flex justify-center items-center cursor-pointer p-2",
          {
            "border-grey-40": !hasPrev,
          }
        )}
        onClick={prevPage}
      >
        <ArrowLeftIcon className="w-3 h-3" color={hasPrev ? "#828382" : "#E3E3E3"} />
      </li>
      {paginationRange?.map((item, index) => {
        if (item === DOTS) {
          return (
            <li
              key={index}
              className={cn(
                "border rounded min-w-[32px] h-8 justify-center items-center flex p-2",
                "border-grey-40 text-grey-70"
              )}
            >
              {DOTS}
            </li>
          );
        }

        return (
          <li
            className={cn(
              "border rounded min-w-[32px] h-8 flex justify-center items-center cursor-pointer font-semibold p-2",
              currentPage === item
                ? "border-primary-80 text-primary-80"
                : "border-grey-40 text-grey-70"
            )}
            key={index}
            onClick={() => gotoPage(Number(item) - 1)}
          >
            {item}
          </li>
        );
      })}
      {!paginationRange?.length && (
        <li
          className={cn(
            "border rounded min-w-[32px] h-8 flex justify-center items-center cursor-pointer font-semibold p-2",
            "border-grey-40 text-grey-40"
          )}
        >
          1
        </li>
      )}
      <li
        className={cn(
          "border rounded min-w-[32px] h-8 flex justify-center items-center cursor-pointer p-2",
          {
            "border-grey-40": !hasNext,
          }
        )}
        onClick={nextPage}
      >
        <ArrowRightIcon className="w-3 h-3" color={hasNext ? "#828382" : "#E3E3E3"} />
      </li>
    </ul>
  );
};

export default Table;
