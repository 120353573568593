import React from "react";
import { IconProps } from "../../classes/Icon";

const EyeIcon: React.FC<IconProps> = ({ size = "20", color = "#707070", ...attributes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <g clipPath="url(#clip0_467_90)">
        <path
          d="M1 9.6667C1 9.6667 4.33333 3 10.1667 3C16 3 19.3333 9.6667 19.3333 9.6667C19.3333 9.6667 16 16.3333 10.1667 16.3333C4.33333 16.3333 1 9.6667 1 9.6667Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1665 12.1667C11.5472 12.1667 12.6665 11.0474 12.6665 9.6667C12.6665 8.28595 11.5472 7.16667 10.1665 7.16667C8.78579 7.16667 7.6665 8.28595 7.6665 9.6667C7.6665 11.0474 8.78579 12.1667 10.1665 12.1667Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_467_90">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
